// THREE
import * as THREE from 'three';
import {TrackballControls} from "three/examples/jsm/controls/TrackballControls";

import OBJModelController from '../../../helper/OBJModelController';

import Hammer from 'hammerjs';

// Config
import raycastCylConf from '../config/RaycastCylinders';

class VisualizerController {
    constructor(threeMount, labelData, labelUpdateFlagHandle, labelScaleHandle, onWorldSelection) {
        this.threeMount = threeMount;
        this.labelUpdateFlagHandle = labelUpdateFlagHandle;
        this.labelScaleHandle = labelScaleHandle;
        this.onWorldSelection = onWorldSelection;

        this.lastLabelUpdate = 0;

        this.mouse = new THREE.Vector2();
        this.pos = {
            x: 0,
            y: 0
        };

        this.labelData = labelData;

        this.setupScene();
        this.setupObjects();
        this.setupControls();

        this.calcLabelScale();

        this.update();
    }

    setupScene = () => {
        // Prepare 3d scene
        this.scene = new THREE.Scene();

        this.resolution = {
            x: this.threeMount.clientWidth,
            y: this.threeMount.clientHeight
        }

        this.width = this.threeMount.clientWidth;
        this.height = this.threeMount.clientHeight;

        let n = 5.5;
        let sceneWidth = (this.width <= this.height) ? n : n / (this.height / this.width);
        let sceneHeight = (this.width >= this.height) ? n : n / (this.width / this.height);

        this.camera = new THREE.OrthographicCamera(
            sceneWidth / - 2,
            sceneWidth / 2,
            sceneHeight / 2,
            sceneHeight / - 2,
            0.1, 1000
        );

        this.camera.add(new THREE.DirectionalLight(0xffffff, 1.25, 0));
        this.scene.add(this.camera);

        // Prepare Camera Angle
        let storedCamPos = this.restoreVector3FromLocalStorage("cameraPos");
        let storedCamRot = this.restoreVector3FromLocalStorage("cameraRot");
        let storedCamUp = this.restoreVector3FromLocalStorage("cameraUp");

        if (!storedCamPos) {
            this.camera.position.set(-1, 0.75, -5);
            this.camera.lookAt(new THREE.Vector3(0, 0, 0));
        } else {
            if (storedCamRot) {
                this.camera.rotation.set(storedCamRot.x, storedCamRot.y, storedCamRot.z);
            }

            if (storedCamUp) {
                this.camera.up.set(storedCamUp.x, storedCamUp.y, storedCamUp.z);
            }

            this.camera.position.set(storedCamPos.x, storedCamPos.y, storedCamPos.z);

            this.camera.updateProjectionMatrix();
        }


        this.renderer = new THREE.WebGLRenderer({
            antialias: true,
            alpha: true
        });
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(this.width, this.height);
        this.threeMount.appendChild(this.renderer.domElement);
    }

    setupObjects = () => {
        this.objs = new THREE.Group();
        this.scene.add(this.objs);

        let storedObjRotation = this.restoreVector3FromLocalStorage("objWrapperRot");
        if (storedObjRotation) {
            this.objs.rotation.set(storedObjRotation.x, storedObjRotation.y, storedObjRotation.z);
        }

        // Add cube
        this.cube = new OBJModelController(
            "/meshes/cube_lndw_2020.obj",
            "/textures/cube_tex_new.png"
        );
        this.cube.loadObjPromise().then(() => {
            this.cube.setScale(1);
            this.cube.setRotation(-90, 0, 90);
            this.cube.setVisibility(true);
            this.objs.add(this.cube.obj);
        });

        // Add vertex spheres
        this.spheres = new THREE.Group();
        const geometry = new THREE.SphereGeometry(0.025, 32, 32);
        const material = new THREE.MeshBasicMaterial({ color: 0xffffff });
        for (let x = 0; x < 2; x++) {
            for (let y = 0; y < 2; y++) {
                for (let z = 0; z < 2; z++) {
                    let sphere = new THREE.Mesh(geometry, material);
                    sphere.position.set((x * 2) - 1, (y * 2) - 1, (z * 2) - 1);
                    this.spheres.add(sphere);
                }
            }
        }

        // Hide spheres
        this.spheres.visible = false;
        this.objs.add(this.spheres);

        // Generate cylinders to raycast against to change cursor on hover
        this.cylinders = new THREE.Group();
        const cylGeometry = new THREE.CylinderBufferGeometry(0.5, 0.5, 0.05, 12);

        for (let i = 0; i < 6; i++) {
            let cyl = new THREE.Mesh(cylGeometry, material);
            cyl.name = "cylinder";

            let pos = raycastCylConf.positions[i];
            cyl.position.set(pos.x, pos.y, pos.z);

            let rot = raycastCylConf.rotations[i];
            cyl.rotateX(rot.x);
            cyl.rotateY(rot.y);
            cyl.rotateZ(rot.z);

            this.cylinders.add(cyl);
        }

        this.cylinders.visible = false;
        this.objs.add(this.cylinders);

        this.raycaster = new THREE.Raycaster();
    }

    setupControls = () => {
        // Setup Controls
        this.controls = new TrackballControls(this.camera, this.threeMount);
        this.controls.rotateSpeed = 2.0;
        this.controls.noPan = true;
        this.controls.noZoom = true;
        this.controls.noRoll = true;

        this.hammerManager = new Hammer.Manager(this.threeMount);
        this.hammerManager.add(new Hammer.Rotate());
        this.hammerManager.on("rotate", this.onRotate);
    }

    onMouseDown = (ev) => {
        let mouse = new THREE.Vector2();
        let x = (ev.touches? ev.touches[0] : ev).clientX;
        let y = (ev.touches? ev.touches[0] : ev).clientY;
        mouse.x =  (x / window.innerWidth) * 2 - 1;
	    mouse.y = -(y / window.innerHeight) * 2 + 1;

        let raycaster = new THREE.Raycaster();
        raycaster.setFromCamera( mouse, this.camera );

        this.startPos = mouse;

        const intersects = raycaster.intersectObject( this.cube.obj );
        if (intersects.length > 0) {    
            this.clickStart = performance.now();
            this.clickedFace = intersects[0].faceIndex;
        } else {
            this.clickStart = null;
        }
    }

    onMouseMove = (ev) => {
        let x = (ev.touches? ev.touches[0] : ev).clientX;
        let y = (ev.touches? ev.touches[0] : ev).clientY;
        this.mouse.x =  (x / window.innerWidth) * 2 - 1;
	    this.mouse.y = -(y / window.innerHeight) * 2 + 1;
    }

    onMouseUp = (ev) => {
        let mouse = new THREE.Vector2();
        let click = ev.touches? ev.changedTouches[0] : ev;
        let x = click.clientX;
        let y = click.clientY;
        mouse.x =  (x / window.innerWidth) * 2 - 1;
	    mouse.y = -(y / window.innerHeight) * 2 + 1;
        
        if (this.clickStart && this.startPos.distanceTo(mouse) < 0.01) {
            this.saveCurrentSceneSetupToLocalStorage();
            this.onWorldSelection(this.clickedFace);
            
            ev.stopPropagation();
        }
    }

    onRotate = (ev) => {
        switch (ev.eventType) {
            case 1:
                this.startEvRotation = ev.rotation;
                this.currentRotation = 0;
                break;
            case 2:
                let newRotation = ev.rotation - this.startEvRotation;
                let change = (newRotation - this.currentRotation) * Math.PI / 180;

                let axis = this.camera.position.clone();
                axis.normalize();

                this.objs.rotateOnWorldAxis(axis, -change);
                this.currentRotation = newRotation;
                break;
            default: // Gesture canceled or ended
                this.startEvRotation = 0;
                this.currentRotation = 0;
                break;
        }
    }

    onResize = () => {
        this.width = this.threeMount.clientWidth;
        this.height = this.threeMount.clientHeight;

        let n = 5.5;
        let sceneWidth = (this.width <= this.height) ? n : n / (this.height / this.width);
        let sceneHeight = (this.width >= this.height) ? n : n / (this.width / this.height);

        this.camera.left = sceneWidth / - 2;
        this.camera.right = sceneWidth / 2;
        this.camera.top = sceneHeight / 2;
        this.camera.bottom = sceneHeight / -2;

        this.camera.updateProjectionMatrix();

        this.renderer.setSize(this.width, this.height);
        
        this.calcLabelScale();
    }

    update = () => {
        this.controls.update();
        
        this.renderer.render( this.scene, this.camera );
        
        this.updateLabelStates();

        this.raycaster.setFromCamera( this.mouse, this.camera );
        const intersects = this.raycaster.intersectObjects( this.scene.children, true );
        if (intersects.length > 0 && "cylinder" === intersects[0].object.name) {
            this.threeMount.style.cursor ="pointer";
        } else {
            this.threeMount.style.cursor ="initial";
        }
        
        this.requestID = window.requestAnimationFrame(this.update);
    }

    updateLabelStates = () => {
        let updateNeccessary = false;
        let deltaT = performance.now() - this.lastLabelUpdate;

        let cameraPos = new THREE.Vector3();
        this.camera.getWorldPosition(cameraPos);

        if (deltaT > 20) {
            let spherePos = new THREE.Vector3();

            for (let i=0; i<this.spheres.children.length; i++) {
                let sphere = this.spheres.children[i];
                sphere.getWorldPosition(spherePos);

                let visible = (cameraPos.distanceTo(spherePos) < 6);
                if (visible !== this.labelData[i].visible) {
                    this.labelData[i].visible = visible; 
                    updateNeccessary = true;
                }
    
                let newPos = this.worldToScreenPosition(sphere);
                if (this.labelData[i].updatePosition(newPos)) {
                    updateNeccessary = true;
                }
            }
            
            if (updateNeccessary) {
                this.lastLabelUpdate = performance.now();
                this.labelUpdateFlagHandle(this.lastLabelUpdate);
            }
        }
    }

    worldToScreenPosition(obj) {
        var vector = new THREE.Vector3();
        let halfWidth = 0.5*this.width;
        let halfHeight = 0.5*this.height;

        obj.updateMatrixWorld();
        vector.setFromMatrixPosition(obj.matrixWorld);
        vector.multiplyScalar(1.15);
        vector.project(this.camera);

        vector.x = ( vector.x * halfWidth ) + halfWidth;
        vector.y = - ( vector.y * halfHeight ) + halfHeight;

        return { 
            x: vector.x,
            y: vector.y
        };
    }

    calcLabelScale = () => {
        let unitInPixel = Math.min(this.width, this.height) / 6;
        this.labelScaleHandle(Math.round(unitInPixel*0.25))
    }

    saveCurrentSceneSetupToLocalStorage() {
        // Save current camera state in local storage
        localStorage.setItem("cameraPos", this.camera.position.toArray());
        localStorage.setItem("cameraRot", this.camera.rotation.toArray());
        localStorage.setItem("cameraUp", this.camera.up.toArray());
        localStorage.setItem("objWrapperRot", this.objs.rotation.toArray());
    }

    restoreVector3FromLocalStorage(key) {
        let storedObj = localStorage.getItem(key);
        if (storedObj) {
            storedObj = storedObj.split(",");
            
            return new THREE.Vector3(
                parseFloat(storedObj[0]),
                parseFloat(storedObj[1]),
                parseFloat(storedObj[2])
            );
        }
        return undefined;
    }

    cleanup = () => {
        window.cancelAnimationFrame(this.requestID);
        window.removeEventListener('resize', this.onWindowResize);
        this.renderer.domElement.removeEventListener('mousedown', this.onMouseDown);
        this.renderer.domElement.removeEventListener('mousemove', this.onMouseMove);
    }

}

export default VisualizerController;