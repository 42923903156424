import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import WorldsContentController from '../../../components/WorldsContentController';

// Styles 
import style from '../../../styles/ExhibitionContent.module.css';

function H0() {
    const {search} = useLocation();

    return <WorldsContentController teaserUrl={"/teaser-labels/h0.png"} comic={"/comics/H0.svg"} title={"Die klassische Welt"}>
        <section className={style.subtitle}>
        Sie umfasst die folgenden vier Theorien: <NavLink to={"/theories/newtonian-mechanics"+search}>Newtonsche Mechanik</NavLink>,&nbsp;
            <NavLink to={"/theories/newtonian-gravitation"+search}>Newtonsche Gravitation</NavLink>, und <NavLink to={"/theories/special-relativity"+search}>Spezielle Relativität</NavLink>,&nbsp;
            sowie die <NavLink to={"/theories/general-relativity"+search}>Allgemeine Relativitätstheorie</NavLink>. 
            <br/>
            Ihre Gegenwelt ist die <NavLink to={"/worlds/h-1"+search}>Quantenwelt</NavLink>.
        </section>
        <section>
            Die klassische Welt ist uns am zugänglichsten, auch wenn sie nur eine Illusion ist. Sie umfasst 
            deterministische Prozesse, die unseren menschlichen Sinnen am nächsten sind und die dadurch 
            oftmals direkt erfahrbar werden. Das idealisierte Verhalten einer gestreckten oder gestauchten 
            Spiralfeder nach dem Hookeschen Gesetz oder die Bewegung von Tief- und Hochdruckgebieten 
            entsprechend der Coriolis-Kraft sind typische Alltagsphänomene, die durch die Newtonsche 
            Mechanik beschrieben werden. Die Bahnkurve eines geworfenen Balls oder die Bewegung der 
            uns umgebenden Himmelskörper können mit Hilfe der Newtonschen Gravitationstheorie in guter 
            Näherung berechnet werden. Die Genauigkeit ist dabei so hoch, dass Abweichungen von Newtons 
            Gravitationsgesetz nur mit hohem Aufwand festgestellt werden können. Beide Theorien operieren 
            in der Galileischen Raum-Zeit, in welcher Geschwindigkeiten linear addiert werden und die Zeit 
            ein unabhängiger absoluter Parameter ist, der universell und beständig das Verrinnen der 
            Sekunden, Minuten und Stunden verzeichnet.
        </section>
        <section>
            Die Spezielle und Allgemeine Relativitätstheorie zählen ebenso zur Klassischen Welt des Würfels. 
            Obwohl sich mit ihnen die zugrundeliegende Raum-Zeitstruktur dramatisch verändert, bleibt doch 
            der deterministische Charakter der eindeutig berechenbaren Bahnkurven erhalten. Physikalische 
            Effekte der Speziellen Relativitätstheorie wie die Zeitdilatation oder die Längenkontraktion 
            sind auf menschlichen Zeit- und Längenskalen winzig, sind aber im heutigen technischen Alltag 
            wie beispielsweise der GPS-Navigation fest verankert. Zur Bestimmung der Lichtablenkung des 
            Sternenlichts im Schwerefeld der Sonne wurden im Mai 1919 einfache Teleskope und photographische 
            Verfahren verwendet. Damit konnte erstmals die Gültigkeit der Allgemeinen Relativitätstheorie 
            gezeigt werden, was mit einem bis dahin unerhörten medialen und öffentlichen Interesse verbunden war.
        </section>
        <section>
            Die Klassische Welt besteht also aus denjenigen vier der insgesamt acht Theorien des Würfels, in 
            denen Quanteneffekte keine Rolle spielen und die Prozesse somit a priori deterministisch erscheinen. 
            Die Bahnen von Objekten können prinzipiell mit unendlicher Genauigkeit bestimmt werden. So ist es 
            beispielsweise möglich, eine Sonde auf dem Mars punktgenau zu landen oder die relative Bewegung 
            von Galaxien ungeachtet ihrer enormen Entfernung von der Erde zu berechnen.
        </section>
    </WorldsContentController>;
}

export default H0;