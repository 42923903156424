import React from 'react';

// Styles 
import style from '../styles/ExhibitionContent.module.css';

function TwoColumnImage(props) {
    const { src, alt } = props;

    return <div className={style.fullRowElement}>
        <img className={style.overflowImage} src={src} alt={alt} ></img>
    </div>;
}
export default TwoColumnImage;