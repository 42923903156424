import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import WorldsContentController from '../../../components/WorldsContentController';
import InlineFormula from '../../../components/InlineFormula';

// Styles 
import style from '../../../styles/ExhibitionContent.module.css';

function H1() {
    const {search} = useLocation();

    return <WorldsContentController teaserUrl={"/teaser-labels/h1.png"} comic={"/comics/H1.svg"} title={"Quantum World"}>
        <section className={style.subtitle}>
            It comprises the following four theories: <NavLink to={"/theories/quantum-mechanics"+search}>Quantum Mechanics</NavLink>,&nbsp;
            <NavLink to={"/theories/non-relativistic-quantum-gravitation"+search}>Non-relativistic Quantum Gravitation</NavLink>,&nbsp;
            <NavLink to={"/theories/quantum-field-theory"+search}>Quantum Field Theory</NavLink>,&nbsp;
            and the <NavLink to={"/theories/theory-of-everything"+search}>Theory of Everything</NavLink>.
            <br/>Its antipode is the <NavLink to={"/worlds/h-0"+search}>Classical World</NavLink>.
        </section>
        <section>
            Towards the end of the 19th century, physics seemed to be largely understood. Significant milestones had been reached: the unification 
            of electricity and magnetism into electromagnetism, and the formulation of thermodynamics as a statistical theory of ensembles of 
            classical particles. Hence, Max Planck was deeply troubled by his inability to explain the precisely measured radiation spectrum of a 
            black body in terms of classical statistical physics. Instead, he needed the ad hoc assumption that the energy of microscopic oscillations 
            only shows up in discrete packets, later called quanta. In order to formulate the exact black body radiation law, Planck was forced to 
            introduce a new fundamental constant, his quantum of action <InlineFormula math="h" />. Amusingly, <InlineFormula math="h" /> stands 
            for <i>hilfskonstante</i>, meaning auxiliary constant in German.
        </section>
        <section>
            Planck thus inadvertently initiated the exploration of the quantum world. Its fundamental features are quite different from the 
            laws of the classical world. In quantum mechanical systems, deterministic temporal evolution does not apply to well defined particles
            trajectories, but merely to so-called probability amplitudes. However, measurement processes typically modify these amplitudes in a 
            severe but well-described fashion. All in all, many precise and verifiable predictions are still possible. They lead to the 
            development of a large array of quantum technologies. For example, all modern information and communication media are based on 
            quantum mechanics.
        </section>
        <section>
            Combining Quantum Mechanics and Special Relativity, i.e. taking into account the constants <InlineFormula math="h" /> and <InlineFormula math="c^{-1}" />, 
            leads to quantum field theory. It allows to formulate the so-called Standard Model of elementary particles, whose astonishing 
            precision and scope is unprecedented. On the other hand, the combination of (non-relativistic) Quantum Mechanics and Newtonian 
            Gravity, which takes only the constants <InlineFormula math="G" /> and <InlineFormula math="h" /> into account, has so far never been a productive object of research in physics. 
            For example, various experiments using slow neutrons in the terrestrial gravitational field have been performed in the past. 
            Sadly, they agreed well with straightforward predictions based on the Schrödinger equation with a Newtonian gravitational potential. 
        </section>
        <section>
            The situation becomes much more challenging when attempting to combine all three constants <InlineFormula math="G" />, <InlineFormula math="c^{-1}" /> and <InlineFormula math="h" />&nbsp; 
            into a Theory of Everything. There are a number of hotly debated contenders, such as string theory or loop quantum theory. 
            However, none of these candidates have made any  experimentally verified predictions to date. The game is far from being over!
        </section>
    </WorldsContentController>;
}

export default H1;