import React from 'react';

// Styles
import baseStyle from '../styles/ExhibitionTitle.module.css';

function ExhibitionTitle(props) {

    function scrollSection() {
        window.scrollTo(0, window.innerHeight * 0.5);
    }

    const subtitle = (props.language === "de") ? "Reise durch ein Metamodell" : "A journey through the meta model";

    return <div className={baseStyle.titleWrapper}>
        <h1 className={baseStyle.mainTitle}>Cube of Physics</h1>
        <h2 className={baseStyle.subTitle}>{subtitle}</h2>
        <img className={baseStyle.scrollArrow} alt="Simple icon of an arrow pointing down, implying to scroll down the page" 
            src="/button-icons/arrow-right.svg" onClick={scrollSection}></img>
    </div>;
}
export default ExhibitionTitle;