import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LegalFooter from '../../../general-components/LegalFooter';

import style from '../styles/ExhibitionFooter.module.css';

function ExhibitionFooter(props) {
    const navigate = useNavigate();
    const {search} = useLocation();
    
    function onEnterExploreClick() {
        navigate("/explore"+search);
    }

    let text = (props.language === "de") ? "Nehmen Sie das virtuelle Modell in die Hand, um mehr über die Theorien und Welten des Würfels der Physik zu erfahren!"
        : "To learn more about the theories and worlds of the Cube of Physics, take the virtual cube in your hand and start exploring!"

    let button = (props.language === "de") ? "Zum Explore Mode" 
        : "Enter Explore Mode";

    return <div className={style.footerFlexBox}>
        <div className={style.modeSwitchBox}>
            <div className={style.cubeIconWrapper}><img src={"/cube.svg"} alt=""></img></div>
            <div className={style.exploreDescription}>
                <span>{text}</span>
                <div className={style.modeSwitchLink} onClick={onEnterExploreClick}>{button}</div>
            </div>
        </div>
        <LegalFooter language={props.language} />
    </div>;
}
export default ExhibitionFooter;