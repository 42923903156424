import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import style from '../styles/Footer.module.css';

function LegalFooter(props) {
    const navigate = useNavigate();
    const {search} = useLocation();

    const blackStyle = (props.black)? style.black : null;

    function handleClick (section) {
        navigate(section+search); 
    }

    const disclaimer = (props.language === "de") ? "Haftungsausschluss" : "Disclaimer";
    const imprint = (props.language === "de") ? "Impressum" : "Imprint";
    const contact = (props.language === "de") ? "Kontakt" : "Contact"; 

    return <div className={`${style.minorLinkBox} ${blackStyle}`}>
        <span onClick={() => { handleClick("/legal") }} className={style.minorLink}>{disclaimer}</span>
        <span onClick={() => { handleClick("/legal") }} className={style.minorLink}>{imprint}</span>
        <span onClick={() => { handleClick("/legal") }} className={style.minorLink}>{contact}</span>
    </div>;
}

export default LegalFooter;
