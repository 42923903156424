import React from 'react';

import QuickNavButton from './ExhibitionShortTextQuickNavButton';

import style from '../styles/ExhibitionIntroduction.module.css';

function ExhibitionShortTextQuickNavMenu(props) {
    const {onPageUpdate, page} = props;

    return <div className={style.quickNavWrapper}>
        <QuickNavButton pageID={1} activePage={page} onPageUpdate={onPageUpdate} />
        <QuickNavButton pageID={2} activePage={page} onPageUpdate={onPageUpdate} />
        <QuickNavButton pageID={3} activePage={page} onPageUpdate={onPageUpdate} />
        <QuickNavButton pageID={4} activePage={page} onPageUpdate={onPageUpdate} />
    </div>;
}
export default ExhibitionShortTextQuickNavMenu;