import React from 'react';

import MiscPage from '../../general-components/MiscPage';
import TeamMember from './components/TeamMember';

import style from '../../styles/Misc.module.css';
import teamStyle from './styles/TeamMember.module.css';

function About(props) {
	
	const de = <MiscPage backgroundColor={"#F59B7E"} language={props.language}>
		<h1 className={style.title}>Über Uns</h1>
		<p>
			Seit 2015 arbeitet eine interdisziplinäre Forschergruppe unter der Leitung von Christian Kassung, 
			Jürgen P. Rabe und Matthias Staudacher an gemeinsamen Projekten, die sowohl experimentelle und 
			theoretische Physik als auch die Natur- und Kulturwissenschaften miteinander verbinden
		</p>
		<p>
			In Vorträgen, Aufsätzen und Ausstellungen hat die Forschergruppe den Würfel der Physik konzeptionell 
			und gestalterisch weiterentwickelt. 2022 entstand eine neue multimediale Installation des Würfels im 
			Lise-Meitner-Haus, dem Sitz des Instituts für Physik der Humboldt-Universität zu Berlin. Aktuell 
			arbeitet die Gruppe an Theorie und Design einer erweiterten Version des Würfels, die mit der 
			Boltzmann-Konstanten in die vierte Dimension führt.
		</p>
		<h1 className={style.title}>Team</h1>
		<div className={teamStyle.teamListWrapper}>
			<TeamMember title={"Prof. Dr."} name={"Christian Kassung"} role={"Konzept & Inhalt"} language={props.language}
				imgUrl={"/team/ChristianKassung.jpg"} link={"https://orcid.org/0000-0001-6177-4833"} mediaStationMode={props.mediaStationMode}>
				Christian Kassung ist seit 2006 Professor für "Kulturtechniken und Wissensgeschichte" 
				an der Humboldt-Universität zu Berlin. Er ist Mitglied im "Hermann von Helmholtz-Zentrum 
				für Kulturtechniken" und Prinzipal Investigator im Exzellenzcluster "Matters of Activity. 
				Image Space Material". Seit 2018 leitet er als Dekan die Fakultät für Geistes- und 
				Sozialwissenschaften.
				<br/><br/>
				© Image by Matters of Activity
			</TeamMember>
			<TeamMember title={"Prof. Dr."} name={"Jürgen P. Rabe"} role={"Konzept & Inhalt"} language={props.language}
				imgUrl={"/team/JuergenRabe.jpg"} link={"https://www.physik.hu-berlin.de/de/pmm/jprabe/jprabehome"} mediaStationMode={props.mediaStationMode}>
				Jürgen P. Rabe ist Professor für Experimentalphysik an der Humboldt-Universität zu Berlin 
				und externes wissenschaftliches Mitglied der Max-Planck-Gesellschaft am MPI für Kolloid- und 
				Grenzflächenforschung. Er ist Gründungsdirektor des "Integrative Research Institute for the Sciences" 
				IRIS Adlershof, Mitglied des "Hermann von Helmholtz-Zentrums für Kulturtechniken", Vorstandsmitglied 
				des Exzellenzclusters "Matters of Activity. Image Space Material" und Mitglied des Sonderforschungsbereichs 
				"Hybrid Inorganic/Organic Systems for Opto-Electronics".
				<br/><br/>
				© Image by Matters of Activity
			</TeamMember>
			<TeamMember title={"Prof. Dr."} name={"Matthias Staudacher"} role={"Konzept & Inhalt"} language={props.language}
				imgUrl={"/team/MatthiasStaudacher.jpg"} link={"https://www.mathematik.hu-berlin.de/~staudacher/"} mediaStationMode={props.mediaStationMode}>
				Matthias Staudacher ist Professor für Mathematische Physik von Raum, Zeit und Materie an der 
				Humboldt-Universität zu Berlin. Er arbeitet an neuartigen Zugängen zu Quantenfeld- und Stringtheorien, 
				und somit an der sogenannten Theorie von Allem.
				Er ist Mitglied der Arbeitsgruppe »Experimentalsysteme« am Institut für Kulturwissenschaft und 
				am Institut für Physik.
				<br/><br/>
				© Image by Matters of Activity
			</TeamMember>
			<TeamMember title={"PhD"} name={"José D. Cojal González"} role={"Konzept & Inhalt"} language={props.language}
				imgUrl={"/team/JoseGonzales.jpg"} link={"https://www.matters-of-activity.de/en/members/1617/jos-d-cojal-gonzlez-phd"} mediaStationMode={props.mediaStationMode}>
				José David Cojal González ist wissenschaftlicher Mitarbeiter am Exzellenzcluster "Matters of Activity. 
				Image Space Material", wo er sich gerne in interdisziplinären Übersetzungen verliert (und findet). 
				Seine Arbeit konzentriert sich auf die Entwicklung neuartiger Filter, die fundamentale Schwingungen 
				von (supra)molekularen Gittern nutzen und dabei topologische Indikatoren zur Klassifizierung 
				dynamischer Materie entwickeln. Er hat Physik und Chemie studiert und 2018 in Experimentalphysik 
				promoviert.
				<br/><br/>
				© Image by Matters of Activity
			</TeamMember>
			<TeamMember title={"Dipl.-Ing."} name={"Franziska J. Paul"} role={"Austellungs- & Visuelles Design"} language={props.language}
				imgUrl={"/team/FranziskaPaul.jpg"} link={"https://studio-gid.com"} mediaStationMode={props.mediaStationMode}>
				Franziska Paul ist eine Szenografin und interdisziplinäre Designerin. Ihre Arbeiten bewegen sich zwischen 
				Kunst und Design und werden projektbezogen individuell und entlang der inhaltlichen Anforderungen gestaltet. 
				Als Mitgründerin von “studio gid”, einem Berliner Büro für visuelle und virtuelle Kommunikation, realisiert 
				sie zahlreiche Projekte in analogen und digitalen Erlebnisräumen.
			</TeamMember>
			<TeamMember title={"M.Sc."} name={"Michael Droste"} role={"Technische Umsetzung"} language={props.language}
				imgUrl={"/team/MichaelDroste.jpg"} link={"https://www.htw-berlin.de/hochschule/personen/person/?eid=9094"} mediaStationMode={props.mediaStationMode}>
				Michael Droste ist ein ehemaliger wissenschaftlicher Mitarbeiter der Hochschule für Technik und Wirtschaft Berlin und arbeitete dort als Entwickler
				und Dozent. Seine Arbeit konzentriert sich auf Human Computer Interaction und innovative Interaktionskonzepte
				Er studierte Informatik und erhielt seinen Master of Science im internationalen Studiengang Medieninformatik im Jahr 2017.
				<br/><br/>
				© Image by Matters of Activity
			</TeamMember>
		</div>
		<h1 className={style.title}>Installation</h1>
        <img className={style.foto} src="/installation/01.jpg" alt="Foto der Skulptur der Ausstellung"></img>
		<img className={style.foto} src="/installation/02.jpg" alt="Foto der Skulptur der Ausstellung"></img>
		<img className={style.foto} src="/installation/03.jpg" alt="Foto der Skulptur der Ausstellung"></img>
		<video className={style.foto} src="/installation/04.mov" alt="Video des Oktahedron" autoPlay loop muted></video>
		<h1 className={style.title}>Unterstützung</h1>
        <p>
            Das Projekt dankt für die Unterstützung des Exzellenzclusters »Matters of Activity. Image 
            Space Material«, gefördert durch die Deutsche Forschungsgemeinschaft (DFG) im Rahmen 
            der Exzellenzstrategie des Bundes und der Länder – EXC 2025 – 390648296.
        </p>
        <img className={style.logo} src="/Logo_MoA_White.png" alt="Logo des Exzellensclusters Matters of Activty"></img>
	</MiscPage>;
	
	const en = <MiscPage backgroundColor={"#F59B7E"} language={props.language}>
		<h1 className={style.title}>About</h1>
		<p>
			Since 2015, an interdisciplinary group of scholars headed by Christian Kassung,
			Jürgen P. Rabe und Matthias Staudacher has been working on projects bridging
			experimental and theoretical physics as well as natural sciences and cultural
			history and theory.
		</p>
		<p>
			Within this group, the Cube of Physics served as a constant
			subject in lectures, papers and exhibitions. In 2022, a new multimedia installation
			of the Cube was opened in the Lise-Meitner-Haus of Humboldt-Universität zu
			Berlin. Currently, the group is working on a web-based version of the Cube
			including theoretical and design considerations on a fourth dimension related
			to Boltzmann's constant.
		</p>
		<h1 className={style.title}>Team</h1>
		<div className={teamStyle.teamListWrapper}>
			<TeamMember title={"Prof. Dr."} name={"Christian Kassung"} role={"Concept & Content"} language={props.language}
			imgUrl={"/team/ChristianKassung.jpg"} link={"https://orcid.org/0000-0001-6177-4833"} mediaStationMode={props.mediaStationMode}>
				Christian Kassung has been Professor of "Cultural Techniques and History of
				Knowledge" at Humboldt-Universität zu Berlin since 2006. He is a member of the
				"Hermann von Helmholtz Centre for Cultural Techniques" and Principal
				Investigator at the Cluster of Excellence "Matters of Activity". Since 2018, he
				has headed the Faculty of Humanities and Social Sciences as Dean.
				<br/><br/>
				© Image by Matters of Activity
			</TeamMember>
			<TeamMember title={"Prof. Dr."} name={"Jürgen P. Rabe"} role={"Concept & Content"} language={props.language}
			imgUrl={"/team/JuergenRabe.jpg"} link={"https://www.physik.hu-berlin.de/de/pmm/jprabe/jprabehome"} mediaStationMode={props.mediaStationMode}>
				Jürgen P. Rabe is Professor of Experimental Physics at Humboldt-Universität zu Berlin
				and external scientific member of the Max Planck Institute of Colloids and Interfaces.
				He is founding director of the ”Integrative Research Institute of the Sciences” IRIS Adlershof,
				member of the ”Hermann von Helmholtz Centre for Cultural Techniques”, board member of the Cluster
				of Excellence ”Matters of Activity. Image Space Material”, and member of the Collaborative
				Research Center ”Hybrid Inorganic/Organic Systems for Opto-Electronics”.
				<br/><br/>
				© Image by Matters of Activity
			</TeamMember>
			<TeamMember title={"Prof. Dr."} name={"Matthias Staudacher"} role={"Concept & Content"} language={props.language}
				imgUrl={"/team/MatthiasStaudacher.jpg"} link={"https://www.mathematik.hu-berlin.de/~staudacher/"} mediaStationMode={props.mediaStationMode}>
				Matthias Staudacher is Professor of Mathematical Physics of Space, Time and Matter at Humboldt-Universität 
				zu Berlin. He works on novel formulations of quantum field theory and string theory and thereby 
				on the theory of everything. He is a member of the »Experimental Systems« working group in the 
				Department of Cultural History and Theory and the Department of Physics.
				<br/><br/>
				© Image by Matters of Activity
			</TeamMember>
			<TeamMember title={"PhD"} name={"José D. Cojal González"} role={"Concept & Content"} language={props.language}
			imgUrl={"/team/JoseGonzales.jpg"} link={"https://www.matters-of-activity.de/en/members/1617/jos-d-cojal-gonzlez-phd"} mediaStationMode={props.mediaStationMode}>
				José David Cojal González is a research associate at the Cluster of Excellence ”Matters of
				Activity. Image Space Material”, where he enjoys getting lost (and found) in interdisciplinary
				translations. His work focuses on the design of new types of filters using fundamental vibrations
				of (supra)molecular grids and thereby developing topological indicators to classify dynamic
				matter. He studied Physics and Chemistry and obtained a PhD in Experimental Physics in 2018.
				<br/><br/>
				© Image by Matters of Activity
			</TeamMember>
			<TeamMember title={"Dipl.-Ing."} name={"Franziska J. Paul"} role={"Spatial & Visual Design"} language={props.language}
			imgUrl={"/team/FranziskaPaul.jpg"} link={"https://studio-gid.com"} mediaStationMode={props.mediaStationMode}>
				Franziska Paul is a scenographer and interdisciplinary designer. Her work oscillates between art and 
				design - always with a strong focus on developing an individual, content-driven appearance for each 
				project. As co-founder of "studio gid", a Berlin-based office for visual and virtual communication, 
				she realizes numerous projects in analog and digital experiential spaces.
			</TeamMember>
			<TeamMember title={"M.Sc."} name={"Michael Droste"} role={"Technical Implementation"} language={props.language}
			imgUrl={"/team/MichaelDroste.jpg"} link={"https://www.htw-berlin.de/hochschule/personen/person/?eid=9094"} mediaStationMode={props.mediaStationMode}>
				Michael Droste is a former research associate at the HTW Berlin – University of Applied Sciences and worked as a developer
				and lecturer. He focuses his work on Human Computer Interaction and innovative interactive implementations. He studied
				computer science and obtained his Master of Science in "Media and Computing" in 2017.
				<br/><br/>
				© Image by Matters of Activity
			</TeamMember>
		</div>
		<h1 className={style.title}>Installation</h1>
        <img className={style.foto} src="/installation/01.jpg" alt="Foto der Skulptur der Ausstellung"></img>
		<img className={style.foto} src="/installation/02.jpg" alt="Foto der Skulptur der Ausstellung"></img>
		<img className={style.foto} src="/installation/03.jpg" alt="Foto der Skulptur der Ausstellung"></img>
		<video className={style.foto} src="/installation/04.mov" alt="Video of the octahedron" autoPlay loop muted></video>
		<h1 className={style.title}>Acknowledgments</h1>
        <p>
            The project acknowledges the support of the Cluster of Excellence »Matters of Activity. 
            Image Space Material« funded by the Deutsche Forschungsgemeinschaft (DFG, German Research Foundation) 
            under Germany's Excellence Strategy – EXC 2025 – 390648296.
        </p>
        <img className={style.logo} src="/Logo_MoA_White.png" alt="Logo of the cluster of excellence Matters of Activty"></img>
	</MiscPage>;
	
	
	return (props.language === "de") ? de : en;
}

export default About;
