import React from 'react';

// Components
import ExhibitionTitle from './components/ExhibitionTitle';
import NCubeAnimation from './components/NCubeAnimation';
import ExhibitionIntroText from './components/ExhibitionIntrotext';
import ExhibitionIntroTeaser from './components/ExhibitionIntroTeaser';
import ExhibitionBackground from './components/BackgroundGradient';
import ExhibitionFooter from './components/ExhibitionFooter';
import ExhibitionFullPageContent from './components/ExhibitionFullPageContent';
import BurgerMenu from '../../general-components/BurgerMenu';

// Config
import backgroundOffsetConfig from './config/BackgroundOffsetByPageID';

// Styles
import baseStyle from './styles/Exhibition.module.css';

// Helper
import ScrollLocker from './helper/ScrollLocker';

class Exhibition extends React.Component {
    constructor(props) {
        super(props);

        this.MAX_PAGE = 5;
        this.state = {
            page: 0, // 0 - intro / 1 - origin / 2 - constants / 3 - worlds / 4 - theories / 5 - footer
            backgroundOffset: 0,
            animationActive: true
        };

        this.lastPageUpdate = 0;
        this.scrollLockTime = 2000; // in ms, how long before a scroll can happen again (in fullpage scroll)
    

        this.introWrapper = React.createRef();
        this.footerWrapper = React.createRef();

        this.scrollLocker = new ScrollLocker();

        this.ticking = false;
    }

    componentDidMount() {
        // Set scroll modifiers for delta modes pixel (0), lines (1) and pages (2) -> TODO
        this.scrollOffsetModifiers = [
            1, // Pixels
            Number(window.getComputedStyle(document.body).fontSize.match(/\d+/)[0]),
            window.innerHeight
        ];

        window.addEventListener('scroll', this.aggregateScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.aggregateScroll);
        //this.scrollLocker.enableScroll();
        document.body.style.overflow = "";
    }

    handleHomeClick = () => {
        this.setState({
            page: 0,
            backgroundOffset: 0
        });
    }

    aggregateScroll = () => {
        if (!this.ticking) {
            this.ticking = true;
            window.requestAnimationFrame(() => {
                this.handleScrollAttempt();
                this.ticking = false;
            });
        }
    }

    handleScrollAttempt = () => {
        const now = performance.now();
        
        this.scrollModeThreshold = this.introWrapper.current.scrollHeight;
        this.maxScrollThreshold = this.scrollModeThreshold - this.footerWrapper.current.clientHeight;

        if (this.state.page === 0 || this.state.page === 5) { // Normal scrolling behaviour
            this.tryUpdateScroll(now);
        } else { // Fullpage scroll
            let scrollDelta = document.documentElement.scrollTop - this.scrollModeThreshold;
            let scrollSign = Math.sign(scrollDelta);
            
            document.documentElement.scrollTop = this.scrollModeThreshold;
            
            // Check if scrolling is allowed (lockout window)
            if (now - this.lastPageUpdate > this.scrollLockTime) {
                this.lastPageUpdate = now;
                this.tryUpdatePage(scrollSign, scrollDelta);
            }
        }
    }

    tryUpdatePage = (scrollSign, scrollDelta) => {
        if ((scrollSign > 0 && this.state.page < this.MAX_PAGE) || (this.state.page > 0 && scrollSign < 0)) {
            let newPageID = this.state.page + scrollSign;
            this.setState({
                page: newPageID,
                backgroundOffset: backgroundOffsetConfig[newPageID],
                animationActive: newPageID < 2
            });

            // Edge cases to leave fullpage scroll
            if (newPageID === 0 || newPageID === this.MAX_PAGE) {
                document.documentElement.scrollTop += scrollDelta;
            } else {
                this.lockScroll();
                //this.scrollLocker.disableScroll(this.scrollLockTime);
            }
        }
    }

    tryUpdateScroll = (timestamp) => {
        let newScrollPos = document.documentElement.scrollTop;
        let scrollProgress = (newScrollPos / this.scrollModeThreshold);

        let newScrollState = {
            backgroundOffset: (this.state.page === 5) ? backgroundOffsetConfig["4"] + (1 - scrollProgress) * 100 : scrollProgress * backgroundOffsetConfig["1"]
        };

        if (this.state.page === 0 && newScrollPos >= this.scrollModeThreshold) {
            document.documentElement.scrollTop = this.scrollModeThreshold;
            // Lock scrolling to avoid skipping of first slide and update page
            this.lastPageUpdate = timestamp;
            newScrollState.page = 1;
            this.lockScroll();
            //this.scrollLocker.disableScroll(this.scrollLockTime);
        } else if (this.state.page === 5 && newScrollPos <= this.scrollModeThreshold) {
            newScrollPos = this.scrollModeThreshold;
            // Lock scrolling to avoid skipping of first slide and update page
            this.lastPageUpdate = timestamp;
            newScrollState.page = 4;
            this.lockScroll();
            //this.scrollLocker.disableScroll(this.scrollLockTime);
        }

        if (newScrollState.backgroundOffset !== this.state.backgroundOffset) {
            this.setState(newScrollState);
        }
    }

    lockScroll = () => {
        document.body.style.overflow = "hidden";
        setTimeout(()=>{
            document.body.style.overflow = "";    
        }, this.scrollLockTime);
    }

    onPageUpdate = (newPageID) => {
        this.currentLockoutDecrease = 0;
        this.lastPageUpdate = performance.now();
        this.setState({
            page: newPageID,
            backgroundOffset: backgroundOffsetConfig[newPageID]
        });
    }

    render = () => {
        return (
            <div className={baseStyle.app} >
                <ExhibitionBackground targetTop={this.state.backgroundOffset} />
                <BurgerMenu language={this.props.language} 
                    onHomeClick={this.handleHomeClick} mediaStationMode={this.props.mediaStationMode}/>
                <div className={baseStyle.contentWrapper} ref={this.introWrapper}>
                    <NCubeAnimation active={this.state.animationActive} />
                    <ExhibitionTitle language={this.props.language} />
                    <ExhibitionIntroText language={this.props.language} />
                    <ExhibitionIntroTeaser language={this.props.language} />
                </div>
                <ExhibitionFullPageContent page={this.state.page} scrollOffset={this.state.scrollOffset} 
                    onPageUpdate={this.onPageUpdate} language={this.props.language} />
                <div className={baseStyle.footerWrapper} ref={this.footerWrapper}>
                    <ExhibitionFooter language={this.props.language} />
                </div>
            </div>
        );
    }
}

export default Exhibition;
