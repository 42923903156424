import React from 'react';

import TheoryContentPageWrapper from '../../../components/TheoryContentPageWrapper';
import ParagraphWithTitle from '../../../components/ParagraphWithTitle';
import FullRowImage from '../../../components/FullRowImage';
import InlineFormula from '../../../components/InlineFormula';
import TeaserVector from '../../../components/TeaserVector';
import CaptionedImage from '../../../components/CaptionedImage';
import Cite from '../../../components/Cite';

// Styles 
import style from '../../../styles/ExhibitionContent.module.css';

function SRT() {
    return <TheoryContentPageWrapper>
        <TeaserVector g={false} c={true} h={false}/>
        <div className={style.singleColumnParagraph}>
            <h1 className={`${style.title} ${style.mainTitleTopOffset}`}>Spezielle Relativitätstheorie</h1>
            <section>
                Die Idee, dass die Lichtgeschwindigkeit <InlineFormula math="c" /> eine für alle möglichen 
                Beobachter fundamentale Konstante ist, also auch unabhängig von deren eigenem Bewegungszustand, 
                erscheint zumindest auf den ersten Blick widersprüchlich. So lässt sich beispielsweise ein 
                Lichtstrahl niemals einholen, wenn seine Geschwindigkeit relativ zum Beobachter stets konstant 
                ist. Und wie soll man erklären, dass ein Lichtstrahl, der von einem sich bewegenden Auto ausgeht, 
                die gleiche Geschwindigkeit für den Fahrer wie für einen am Straßenrand stehenden Beobachter hat? 
                Diese scheinbaren Widersprüche wurden von Albert Einstein 1905 im Rahmen seiner Speziellen 
                Relativitätstheorie gelöst. Dies erforderte, die Annahmen von Galilei und Newton zur Struktur 
                von Raum und Zeit radikal zu hinterfragen. Hermann Minkowski zog 1907 die mathematische und 
                konzeptionelle Konsequenz aus Einsteins Ideen und schlug vor, den dreidimensionalen Euklidischen 
                Raum durch eine vierdimensionale Raum-Zeit zu ersetzen.
            </section>
        </div>
        <FullRowImage src="/intro-images/special-relativity.svg" />
        <ParagraphWithTitle titlePos="right" title="Elektromagnetismus und Relativität">
            <section>
                Auch wenn mit der Speziellen Relativitätstheorie die Lichtgeschwindigkeit als 
                neue fundamentale Konstante im Theoriegebäude der Physik verankert und damit 
                neben <InlineFormula math="G"/> und <InlineFormula math="h"/> eine dritte Dimension 
                für das Würfelmodell notwendig wurde, reichen deren Anfänge weit ins 19. Jahrhundert 
                hinein.
            </section>
            <section>
                1873 vereinheitlichte James Clerk Maxwell nicht nur Elektrizität und Magnetismus, 
                sondern identifizierte zudem Licht als ein elektromagnetisches Phänomen. Da der 
                Elektromagnetismus als physikalisches Phänomen auf der Grundlage eines alles
                durchdringenden Äthers erklärt wurde, lag es nahe, dessen Existenz experimentell 
                zu etablieren. Die berühmten Interferometer-Experimente aus den 1880er Jahren von 
                Edward Morley und Albert Michelson zeigten allerdings, dass die Ausbreitungsgeschwindigkeit 
                von Licht in keiner Weise von der Bewegung der Erde durch einen hypothetischen Äther abhängt. 
                Es begann die komplexe Geschichte der Lorentz-Transformationen, die die exakten Änderungen 
                der Maxwell-Gleichungen unter einem Wechsel des Bezugssystems beschreiben, und deren korrekte 
                physikalische Interpretation erst 1905 mit der Speziellen Relativitätstheorie gelang.
            </section>
        </ParagraphWithTitle>
        <CaptionedImage src="/theory-illustrations/special-relativity.png" caption="Hermann Minkowskis Raum-Zeitkegel von 1909." />
        <ParagraphWithTitle titlePos="left" title="Minkowski-Raum">
            <section>
                In seiner Speziellen Relativitätstheorie führte Einstein die Elektrodynamik 
                bewegter Körper auf zwei fundamentale Postulate zurück: die Relativität 
                von Gleichzeitigkeit und die absolute Konstanz der 
                Lichtgeschwindigkeit <InlineFormula math="c"/> in allen Inertialsystemen. 
                Damit einher ging eine grundlegend neue, ätherlose Vorstellung von Raum 
                und Zeit. Die entscheidenden Konsequenzen daraus zog Hermann Minkowski. 
                Er zeigte, dass sich die Arbeiten von Lorentz und Einstein am elegantesten 
                in einem vierdimensionalen, nicht-euklidischen Vektorraum formulieren 
                lassen &ndash; zu seinen Ehren Minkowski-Raum genannt. Raum und Zeit 
                verschmelzen darin zur Einheit der Raum-Zeit. Bei einem Vortrag am 21. 
                September 1908 begann Minkowski seine Ausführungen daher mit den 
                bestechenden Worten:
            </section>
            <Cite>
                Die Anschauungen über Raum und Zeit, die ich Ihnen entwickeln möchte, 
                sind auf experimentell-physikalischem Boden erwachsen. Darin liegt ihre 
                Stärke. Ihre Tendenz ist eine radikale. Von Stund' an sollen Raum für 
                sich und Zeit für sich völlig zu Schatten herabsinken und nur noch eine 
                Art Union der beiden soll Selbständigkeit bewahren. (Minkowski 1908)
            </Cite>
            <section>
                Die Spezielle Relativitätstheorie machte das Konzept der Raum-Zeit jedoch 
                nicht zwingend notwendig, und so lehnte Einstein es zunächst ab.
            </section>
        </ParagraphWithTitle>
        <ParagraphWithTitle titlePos="right" title="Lorentz-Transformationen">
            <section>
                Die Spezielle Relativitätstheorie offenbart, dass die Galilei-Transformationen, 
                auf denen Newtons Mechanik und Gravitationstheorie aufbauen, durch neue 
                Transformationsgesetze ersetzt werden müssen, die von dem holländischen 
                Physiker Hendrik Lorentz ersonnen wurden. Sie erklären, warum sich 
                Geschwindigkeiten von Objekten nicht einfach addieren lassen und führen 
                zu den bekannten, zunächst rätselhaft erscheinenden Effekte der 
                Zeitdilatation und Längenkontraktion. Die Lichtgeschwindigkeit 
                im Vakuum <InlineFormula math="c" /> spielt in dieser Theorie eine zentrale Rolle: Kein 
                Signal kann mit einer höheren Geschwindigkeit als <InlineFormula math="c" /> übertragen 
                werden &ndash; sie wird als fundamentale Naturkonstante postuliert.
            </section>
            <section>
                Für beschleunigte Bezugssysteme ist allerdings eine vollkommen neue Struktur 
                notwendig, die erst im Rahmen der Allgemeinen Relativitätstheorie, also der 
                Würfelecke <InlineFormula math="(G, c^{-1}, 0)" />, erfasst werden kann. 
                Eine unabdingbare Notwendigkeit hierfür ist eine viel ausgefeiltere Vereinigung 
                von Raum und Zeit, die Einsteins 1915 vorgeschlagene, gekrümmte Verallgemeinerung 
                von Minkowskis flacher vierdimensionaler Raum-Zeit erfordert.
            </section>
        </ParagraphWithTitle>
    </TheoryContentPageWrapper>
}
export default SRT;
