import React from 'react';

import TheoryContentPageWrapper from '../../../components/TheoryContentPageWrapper';
import ParagraphWithTitle from '../../../components/ParagraphWithTitle';
import FullRowImage from '../../../components/FullRowImage';
import InlineFormula from '../../../components/InlineFormula';
import TeaserVector from '../../../components/TeaserVector';
import CaptionedImage from '../../../components/CaptionedImage';

// Styles 
import style from '../../../styles/ExhibitionContent.module.css';;

function NRQG() {
    return <TheoryContentPageWrapper>
        <TeaserVector g={true} c={false} h={true}/>
        <div className={style.singleColumnParagraph}>
            <h1 className={`${style.title} ${style.mainTitleTopOffset}`}>Non-relativistic Quantum Gravitation</h1>
            <section>
                Adding the gravitational constant <InlineFormula math="G"/> to nonrelativistic quantum mechanics is
                in principle quite straightforward: one solves the Schrödinger equation for
                massive particles moving in the Newtonian gravitational potential. A more
                interesting consideration is to assume the existence of a Theory of Everything,
                still to be established, and to imagine carefully switching off the inverse
                speed of light <InlineFormula math="c^{-1}"/> by mathematically taking the limit 
                &nbsp;<InlineFormula math="c \rightarrow \infty"/> in all of that putative theory's formulas.
            </section>
            <section>
                Does this really not lead to any unexpected measurable effects? Do we really
                only have the rather boring quantum mechanical scenario outlined at the
                beginning? The answers are still pending. In this respect, nonrelativistic
                quantum gravity does not currently represent a theory of its own in physics,
                and there is hardly any research on it. However, it should be mentioned that a
                number of unconventional approaches to quantum gravity precisely play with the
                possibility of breaking the Lorentz symmetry (which requires <InlineFormula math="c^{-1}=1"/>)
                between space and time.
            </section>
        </div>
        <FullRowImage src="/intro-images/non-relativistic-quantum-gravity.svg" />
        <ParagraphWithTitle titlePos="right" title="An ugly duckling?">
            <section>
                The logic of the cube of physics, which names eight different theories
                according to <InlineFormula math="2^3"/> possible choices of either <i>switching on</i> or <i>switching off</i>
                the three fundamental constants <InlineFormula math="G"/>, <InlineFormula math="c^{-1}"/> and <InlineFormula math="h"/>, reflects both the
                history and the systematics of physics in many ways, even if it largely ignores
                a number of further important developments such as those of thermodynamics and
                statistical physics. Most importantly, it succinctly points to the need of
                building a <i>final</i> theory that would consistently unite all three constants
                &nbsp;<InlineFormula math="G"/>, <InlineFormula math="c^{-1}"/> and <InlineFormula math="h"/>.
            </section>
            <section>
                However, at one corner of the cube a theory is situated that vexingly has not
                yet played a significant role in the history of knowledge of physics. It should
                be called non-relativistic quantum gravity. Here one imagines performing the
                non-relativistic limit <InlineFormula math="c\rightarrow\infty"/>, i.e. infinite speed of light,
                or equivalently <InlineFormula math="c^{-1}\rightarrow 0"/>, on the sought-after Theory of
                Everything. The open question is whether this procedure leads to an
                interesting theory at all, and, perhaps more importantly, whether there are any
                meaningful experiments. Unfortunately, none of the current candidates for a
                Theory of Everything seem to yield any clues here.
            </section>
        </ParagraphWithTitle>
        <CaptionedImage src="/theory-illustrations/non-relativistic-quantum-gravity.png" caption="Neutron interference pattern in the gravitational field as a function of the angle of rotation Φ." />
        <ParagraphWithTitle titlePos="left" title="Experimental findings">
            <section>
                Since the 1960s, numerous experiments with quantum particles in classical
                gravitational fields, such as the one of the Earth, have been carried out.
                Clearly it is essential to use slow quantum particles, singling out neutrons to
                be particularly suitable. In 1975, for example, Colella, Overhauser and Werner
                carried out an experiment whose result depended on both Newton's theory of
                gravity and quantum mechanics. A beam of very slow neutrons was first split and
                then examined interferometrically. By rotating the measuring device by an angle
                &nbsp;<InlineFormula math="\varphi"/>, they could show that a quantum mechanical phase shift of the slow
                neutrons occurs due to their interaction with the gravitational field of the
                Earth. Somewhat disappointingly, the experimental findings are in perfect
                agreement with the theoretical prediction of the Schrödinger equation equipped
                with a Newtonian gravitational potential. More recent experiments have even
                been able to demonstrate the quantisation of weak binding states of ultracold,
                i.e. very slow neutrons to the Earth's gravitational field.
            </section>
        </ParagraphWithTitle>
        <ParagraphWithTitle titlePos="right" title="More clues">
            <section>
                These experiments are potentially relevant for supporting or excluding various
                scenarios for the Theory of Everything. For example, some versions of
                superstring theory predict deviations from Newton's gravitational potential on
                length scales far above the Planck scale. However, no such deviations could so
                far be found.
            </section>
            <section>
                The breaking of Lorentz symmetry between space and time generated by the
                &nbsp;<InlineFormula math="c^{-1}\rightarrow 0"/> limit also inspires research on a purely theoretical
                level. For example, in 2009 Petr Hořava proposed a new theory of gravity that
                modifies general relativity on small length scales. This has already resulted
                in more than 2,000 subsequent publications. Nonetheless, the status of this
                so-called Hořava-Lifshitz Gravity Theory remains unclear.
            </section>
        </ParagraphWithTitle>
    </TheoryContentPageWrapper>;
}

export default NRQG;