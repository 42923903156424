import React from 'react';

import TheoryContentPageWrapper from '../../../components/TheoryContentPageWrapper';
import ParagraphWithTitle from '../../../components/ParagraphWithTitle';
import FullRowImage from '../../../components/FullRowImage';
import InlineFormula from '../../../components/InlineFormula';
import TeaserVector from '../../../components/TeaserVector';
import CaptionedImage from '../../../components/CaptionedImage';
import Cite from '../../../components/Cite';

// Styles 
import style from '../../../styles/ExhibitionContent.module.css';

function NewtonianGravitation() {
    return <TheoryContentPageWrapper>
        <TeaserVector g={true} c={false} h={false}/>
        <div className={style.singleColumnParagraph}>
            <h1 className={`${style.title} ${style.mainTitleTopOffset}`}>Newtonian Gravitation</h1>
            <section>
                One of Newton's greatest achievements was the discovery of his general law of
                gravitation. It states that every point mass in the universe instantaneously
                attracts every other point mass with a force <InlineFormula math="F"/> that is proportional to the
                product of their masses <InlineFormula math="M"/> and <InlineFormula math="m"/> and inversely proportional to the square of
                the distance <InlineFormula math="r"/> between them. The associated constant of proportionality is
                Newton's gravitational constant <InlineFormula math="G"/>. It is the only fundamental natural
                constant that is considered in this theory, and thus at this corner of the
                cube. 
            </section>
            <section>
                In combination with Newtonian Mechanics, at <InlineFormula math="(0, 0, 0)"/>, this theory can
                be used to describe with astonishing accuracy both the falling of objects on
                Earth and the 'falling' of celestial objects such as moons, planets, meteorites
                and satellites in the planetary system.
            </section>
        </div>
        <FullRowImage src="/intro-images/newtonian-gravity.png" />
        <ParagraphWithTitle titlePos="right" title="Newton's theory of gravitation">
            <section>
                In the third book "De mundi systemate" of his "Philosophiæ Naturalis Principia
                Mathematica", Isaac Newton deduces the motion of the planets and their
                satellites from his law of gravitation. According to Newton, the resulting
                force keeps all celestial objects in their orbits. It is inversely proportional
                to the square of the distance between the centres of mass of two objects with a
                spherically symmetric mass distribution:
            </section>
            <Cite>
                If the matter of two spheres that are mutually heavy towards each other is
                homogeneous in the areas that are at equal distance from the centre, the
                weight of each sphere will behave inversely proportional to the square of the
                distance between their centres. (Newton 1687)
            </Cite>
            <section>
                Expressed in modern terms, the law of gravitation is:
            </section>
            <Cite center>
                <InlineFormula math="F=G\frac{Mm}{r^2}"/> <br/>
                Newton's law of Gravitation
            </Cite>
            <section>
                where <InlineFormula math="M"/> and <InlineFormula math="m"/> are equal to the total masses of the above two bodies.
            </section>
        </ParagraphWithTitle>
        <CaptionedImage src="/theory-illustrations/newtonian-gravity.jpg" caption="Comparing the fall of a 1.3 kg aluminium hammer and a 30 g falcon feather on the moon." />
        <ParagraphWithTitle titlePos="left" title="Equivalence principle">
            <section>
                An integral part of Newton's theory of gravitation is the weak equivalence
                principle, which states that the inertial and gravitational masses of every
                object are identical. Galileo had already demonstrated experimentally that two
                objects with equal masses fall at the same speed. The necessary proof of this
                equivalence was provided experimentally by Newton using a special pair of
                pendulums. To this day, this equivalence is still being investigated using
                highly complex experiments. Yet a difference between heavy and inertial mass
                has not been found. A particularly impressive demonstration was shown during
                the lunar landing mission Apollo 15: a feather and a hammer, dropped
                simultaneously from the same height, hit ground at the same time.
            </section>
        </ParagraphWithTitle>
        <ParagraphWithTitle titlePos="right" title="Action at a distance">
            <section>
                For Newton, however, the law of gravitation had an exclusively relational
                character, he did not know of a numerical value for the gravitational constant
                <InlineFormula math="G"/>. However, he also derived epochal consequences from the proportionality of
                the universal gravitation. First and foremost, every planet moves in an
                elliptical orbit around the sun, which is located at one of the ellipse's focal
                points. Newton thus formulated a dynamic and mathematical derivation for the
                already empirically known orbit of planets: "whereas Kepler guessed right at
                the Ellipsis". (Newton to Halley 1686) Despite the revolutionary successes, the
                character of the instantaneous long-distance effect in his law of gravitation
                remained extremely questionable for many of Newton's contemporaries and
                successors, up to and including Einstein.
            </section>
        </ParagraphWithTitle>
        <ParagraphWithTitle titlePos="left" title="Cavendish experiment">
            <section>
                The introduction of the fundamental natural constant <InlineFormula math="G"/> for the interaction of
                gravitation thus takes place historically within Newton's theory formation. It
                defines the first of the three directions of the cube's Cartesian coordinate
                system. Experimentally, it was first determined as late as 1798 by Henry
                Cavendish, who based his calculation on an estimated density of the earth. The
                current value is
            </section>
            <Cite center><InlineFormula math="6,6740\times10^{-11} m^3\cdot kg^{-1}\cdot s^{-2}"/>,</Cite>
            <section>
                with a surprisingly large uncertainty of <InlineFormula math="2,2\times 10^{-5}"/>.
            </section>
        </ParagraphWithTitle>
    </TheoryContentPageWrapper>;
}

export default NewtonianGravitation;