import React from 'react';

import TheoryContentPageWrapper from '../../../components/TheoryContentPageWrapper';
import ParagraphWithTitle from '../../../components/ParagraphWithTitle';
import FullRowImage from '../../../components/FullRowImage';
import InlineFormula from '../../../components/InlineFormula';
import TeaserVector from '../../../components/TeaserVector';
import CaptionedImage from '../../../components/CaptionedImage';
import Cite from '../../../components/Cite';

// Styles 
import style from '../../../styles/ExhibitionContent.module.css';

function QFT() {
    return <TheoryContentPageWrapper>
        <TeaserVector g={false} c={true} h={true}/>
        <div className={style.singleColumnParagraph}>
            <h1 className={`${style.title} ${style.mainTitleTopOffset}`}>Quantum Field Theory</h1>
            <section>
                Quantum mechanics was initially formulated in the 1920s in the nonrelativistic
                limit only, i.e. <InlineFormula math="c^{-1}=0"/>, although the correctness of Einstein's Special
                Theory of Relativity had already been generally accepted for years, with the
                notable exception of a few anti-Semitic idiots. In 1928, Dirac succeeded in
                establishing a curious relativistic wave equation that generalised the
                Schrödinger equation in a highly non-trivial fashion. It is now engraved in
                front of Isaac Newton's tomb in Westminster Abbey. In particular, it explained
                the electron's spin and predicted its antiparticle. A triumph was the
                experimental confirmation of this positron by Carl Anderson in 1932.
            </section>
            <section>
                Nevertheless, the interpretation of the Dirac equation as a wave equation led
                to puzzling contradictions. In addition, problems arose with the quantisation
                of electrodynamics, which is also a <InlineFormula math="c^{-1}=1"/> theory. As a way out, quantum
                field theory was developed in the late 1940's as a relativistically consistent
                many-particle theory. This eventually led some 20 years later to the standard
                model of elementary particles, which precisely describes all forces of nature
                with the exception of gravity.
            </section>
        </div>
        <FullRowImage src="/intro-images/quantum-field-theory.svg" />
        <ParagraphWithTitle titlePos="right" title="Klein-Gordon equation">
            <section>
                The direct generalisation of the quantum mechanical, Galilei-invariant
                Schrödinger equation for the probability amplitude <InlineFormula math="\Psi"/> of a free particle of
                mass <InlineFormula math="m"/> leads to the Lorentz invariant Klein-Gordon equation, in the convenient notation <InlineFormula math="\hbar=\frac{h}{2\pi}"/>:
            </section>
            <Cite center>
                <InlineFormula math="(\frac{1}{c^2}\frac{\partial^2}{\partial t^2}-\frac{\partial ^2}{\partial x^2}-\frac{\partial^2}{\partial y^2}-\frac{\partial^2}{\partial z^2}+\frac{m^2 c^2}{\hbar^2})\Psi=0"/>
                <br/>Klein-Gordon equation
            </Cite>
            <section>
                The relativistic symmetries between space and time have been restored: both the
                derivative with respect to time as well as all partial derivatives with respect to the spatial coordinates 
                <InlineFormula math="x, y, z"/> are now of second order. In addition, in comparison to the Schrödinger
                equation, also the speed of light <InlineFormula math="c"/> appears along with Planck's quantum
                of action <InlineFormula math="h"/>.
            </section>
        </ParagraphWithTitle>
        <CaptionedImage src="/theory-illustrations/quantum-field-theory.jpg" caption="The constituents of the Standard Model of particle physics." />
        <ParagraphWithTitle titlePos="left" title="Dirac's equation">
            <section>
                Interestingly, however, it turns out that the interpretation of <InlineFormula math="\Psi"/> as
                probability amplitude is no longer consistent in the relativistic case. Quantum
                field theory solves the problem conceptually in an abstract mathematical way:
                &nbsp;<InlineFormula math="\Psi"/> becomes an operator-valued quantum field acting on a Hilbert space:
                &nbsp;<InlineFormula math="\Psi"/> is now capable of creating and destroying particles. Thus quantum field
                theory turns into a many-body model by construction. However, the free matter
                particles detected so far are not yet completely described by the above
                equation, even in the operator interpretation. In 1928 Dirac found an equation
                which is much better suited for this purpose and which bears his name:
            </section>
            <Cite center>
                <InlineFormula math="\Big(i\hbar(\gamma^0\frac1c\frac{\partial}{\partial t}+\gamma^1\frac{\partial}{\partial x}+\gamma^2\frac{\partial}{\partial y}+\gamma^3\frac{\partial}{\partial z})-mc\Big)\Psi=0" />
                <br/>Dirac's equation
            </Cite>
            <section>
                <InlineFormula math="\Psi"/> now has four components, it is a so-called spinor. It is first-order in
                derivatives, and requires the introduction of four numerical <InlineFormula math="4 \times 4"/> Dirac
                matrices <InlineFormula math="\gamma^0, \gamma^1, \gamma^2, \gamma^3"/>, with <InlineFormula math="i"/> denoting the imaginary unit.
                Two times two is four: The first two corresponds to the two possible
                polarisations of the spin <InlineFormula math="\frac12"/> of the matter particles. The latter is an
                (unfortunately unimaginable) rotation of the point particle around itself. The
                second two means that <InlineFormula math="\Psi"/> can create and destroy not only particles but also
                antiparticles. Thus <InlineFormula math="\Psi"/> describes in addition to the electron field also the
                corresponding positron field. For all other leptons, namely muons, tauons, the
                corresponding three generations of neutrinos, and the six known quarks,
                additional Dirac fields are required. However, the quarks never appear as free
                particles, but are firmly enclosed in non-elementary particles such as the
                protons and neutrons of the atomic nuclei.
            </section>
        </ParagraphWithTitle>
        <ParagraphWithTitle titlePos="right" title="Standard model">
            <section>
                Similar operator equations describe the electromagnetic fields &ndash; more
                precisely, the so-called Abelian gauge fields &ndash; of quantum electrodynamics.
                This is the most thoroughly tested theory ever established. The quanta of
                these fields are nothing other than Einstein's photons, which also have a spin.
                But unlike the case of the electron, its value is one. The nuclear forces are
                also very successfully described by quantum field theories, employing further
                spin 1 particles: the eight gluons of the strong nuclear forces and the 
                W<InlineFormula math="^+"/>, W<InlineFormula math="^-"/>
                and Z-bosons of the weak nuclear forces. To describe these particles and the
                forces of nature corresponding to them, the Abelian gauge fields have to be
                replaced by mathematically much more involved non-Abelian fields. Overall, this
                results in the current standard model of elementary particle physics.
            </section>
            <section>
                Of particular note is the Higgs field, which was already predicted in the early
                1960s and, after a long search, was finally discovered in 2012 at the Large Hadron Collider at
                CERN, Geneva. It gives most elementary particles a mass, see the <InlineFormula math="m"/> in the
                Dirac equation above in the case of the spin <InlineFormula math="\frac12"/> particle.
            </section>
        </ParagraphWithTitle>
        <ParagraphWithTitle titlePos="left" title="The Graviton">
            <section>
                So far it has not been possible to integrate the theory of gravity into the
                Standard Model. However, the hypothetical particle carrying the
                gravitational force has already been christened Graviton. If it exists, it
                should have spin 2. Its inclusion would lead from the corner <InlineFormula math="(0, c^{-1}, h)"/>
                to the corner <InlineFormula math="(G, c^{-1}, h)"/> of the cube, i.e. to the Theory of Everything.
            </section>
        </ParagraphWithTitle>
    </TheoryContentPageWrapper>;
}

export default QFT;