import style from '../styles/MediaStation.module.css';
import TopNavMenuEntry from './TopNavMenuEntry';

function TopNavMenu(props) {
    const { page } = props;
    return <div className={style.topNavMenuWrapper}>
        <TopNavMenuEntry page={page} id={0} />
        <TopNavMenuEntry page={page} id={1} />
        <TopNavMenuEntry page={page} id={2} />
        <TopNavMenuEntry page={page} id={3} />
    </div>;
}

export default TopNavMenu;