import React from 'react';

// Styles
import labelStyle from '../../../styles/Label.module.css';
import exLabelStyle from '../styles/ExhibitionLabel.module.css';

function ExhibitionLabel(props) {
    const {pos, right, padding, offset, visible, children, typeStyle} = props;

    let style = [labelStyle.label];

    let left = pos.x;
    let top = pos.y;

    if (typeStyle === "bold") {
        style.push(exLabelStyle.boldLabel);
    } else {
        style.push(exLabelStyle.lightLabel);
    }

    if (right) {
        style.push(exLabelStyle.rightNoWrap);
    }

    if (padding) {
        style.push(exLabelStyle.padding);
    }

    if (offset) {
        style.push(exLabelStyle[offset]);
    }

    style.push((visible) ? labelStyle.fadeIn : labelStyle.fadeOut);

    return <div className={style.join(" ")} style={{ top: "calc(" + top + "px + 10%)", left: left + "px" }}>
        {children}
    </div>;

}
export default ExhibitionLabel;