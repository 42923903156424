// Theories (EN)
import GeneralRelativity from './content/theories/en/GeneralRelativity';
import SpecialRelativity from './content/theories/en/SpecialRelativity';
import QuantumMechanics from './content/theories/en/QuantumMechanics';
import NewtonianMechanics from './content/theories/en/NewtonianMechanics';
import NonRelativisticQuantumGravitation from './content/theories/en/NonRelativisticQuantumGravitation';
import QuantumFieldTheory from './content/theories/en/QuantumFieldTheory';
import TheoryOfEverything from './content/theories/en/TheoryOfEverything';
import NewtonianGravitation from './content/theories/en/NewtonianGravitation';

// Theories (EN)
import GeneralRelativityDE from './content/theories/de/GeneralRelativity';
import SpecialRelativityDE from './content/theories/de/SpecialRelativity';
import QuantumMechanicsDE from './content/theories/de/QuantumMechanics';
import NewtonianMechanicsDE from './content/theories/de/NewtonianMechanics';
import NonRelativisticQuantumGravitationDE from './content/theories/de/NonRelativisticQuantumGravitation';
import QuantumFieldTheoryDE from './content/theories/de/QuantumFieldTheory';
import TheoryOfEverythingDE from './content/theories/de/TheoryOfEverything';
import NewtonianGravitationDE from './content/theories/de/NewtonianGravitation';

// Worlds (EN)
import C0 from './content/worlds/en/C0';
import C1 from './content/worlds/en/C1';
import G0 from './content/worlds/en/G0';
import G1 from './content/worlds/en/G1';
import H0 from './content/worlds/en/H0';
import H1 from './content/worlds/en/H1';

// Worlds (EN)
import C0DE from './content/worlds/de/C0';
import C1DE from './content/worlds/de/C1';
import G0DE from './content/worlds/de/G0';
import G1DE from './content/worlds/de/G1';
import H0DE from './content/worlds/de/H0';
import H1DE from './content/worlds/de/H1';

const pages = {
    theories: {
        "newtonian-mechanics": {
            "en": <NewtonianMechanics/>,
            "de": <NewtonianMechanicsDE/>
        },
        "newtonian-gravitation": {
            "en": <NewtonianGravitation/>,
            "de": <NewtonianGravitationDE/>
        },
        "general-relativity": {
            "en": <GeneralRelativity/>,
            "de": <GeneralRelativityDE/>
        },
        "special-relativity": {
            "en": <SpecialRelativity/>,
            "de": <SpecialRelativityDE/>
        },
        "quantum-field-theory": {
            "en": <QuantumFieldTheory/>,
            "de": <QuantumFieldTheoryDE/>
        },
        "quantum-mechanics": {
            "en": <QuantumMechanics/>,
            "de": <QuantumMechanicsDE/>
        },
        "non-relativistic-quantum-gravitation": {
            "en": <NonRelativisticQuantumGravitation/>,
            "de": <NonRelativisticQuantumGravitationDE/>
        },
        "theory-of-everything": {
            "en": <TheoryOfEverything/>,
            "de": <TheoryOfEverythingDE/>
        }
    },
    worlds: {
        "c-0": {
            "en": <C0/>,
            "de": <C0DE/>
        },
        "c-1": {
            "en": <C1/>,
            "de": <C1DE/>
        },
        "g-0": {
            "en": <G0/>,
            "de": <G0DE/>
        },
        "g-1": {
            "en": <G1/>,
            "de": <G1DE/>
        },
        "h-0": {
            "en": <H0/>,
            "de": <H0DE/>
        },
        "h-1": {
            "en": <H1/>,
            "de": <H1DE/>
        }
    }
}
export default pages;
