const tripel = {
    "000": "newtonian-mechanics",
    "001": "special-relativity",
    "010": "quantum-mechanics",
    "011": "quantum-field-theory",
    "100": "newtonian-gravitation",
    "101": "general-relativity",
    "110": "non-relativistic-quantum-gravitation",
    "111": "theory-of-everything"
}

export default tripel;