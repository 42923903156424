const localization = {
    "NONE" : {
        "de" : "",
        "en" : ""
    },
    /////////////////////////////////////////////
    //              CUBE Titles
    /////////////////////////////////////////////
    "H0" : {
        "de" : "Die Klassische Welt",
        "en" : "The Classical World"
    },
    "H1" : {
        "de" : "Die Welt der Quanten",
        "en" : "The Quantum World"
    },
    "C0" : {
        "de" : "Die Galileische Welt",
        "en" : "The Galilean World"
    },
    "C1" : {
        "de" : "Die Welt der Relativität",
        "en" : "The Relativistic World"
    },
    "G0" : {
        "de" : "Die Welt ohne Gravitation",
        "en" : "The World without Gravitation"
    },
    "G1" : {
        "de" : "Die Welt der Gravitation",
        "en" : "The World with Gravitation"
    },
    /////////////////////////////////////////////
    //            OCTAHEDRON Titles
    /////////////////////////////////////////////
    "NGT" : {
        "de" : "Newton'sche Gravitationstheorie",
        "en" : "Newtonian Gravitation"
    },
    "NM" : {
        "de" : "Newton'sche Mechanik",
        "en" : "Newtonian Mechanics"
    },
    "QM" : {
        "de" : "Quantenmechanik",
        "en" : "Quantum Mechanics"
    },
    "NRQG" : {
        "de" : "Nichtrelativistische Quantengravitation",
        "en" : "Non-Relativistic Quantum Gravitation"
    },
    "TOE" : {
        "de" : "Theorie von Allem",
        "en" : "Theory of Everything"
    },
    "QFT" : {
        "de" : "Quantenfeldtheorie",
        "en" : "Quantum Field Theory"
    },
    "SR" : {
        "de" : "Spezielle Relativitätstheorie",
        "en" : "Special Relativity"
    },
    "GR" : {
        "de" : "Allgemeine Relativitätstheorie",
        "en" : "General Relativity"
    }
};

export default localization;