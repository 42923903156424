import React from 'react';

// Styles 
import style from '../styles/ExhibitionContent.module.css';

function TeaserVector(props){
    //static 
    const {g, h, c, wrapperWidth} = props;

    let gLabel = (g) ? "G" : "0";
    let hLabel = (h) ? "h" : "0";
    let cLabel = (c) ? "c" : "0";

    let elStyle = null;
    if (wrapperWidth < 700) {
        elStyle = { height: "50vh" };
    }
    
    return <div className={style.teaserWrapper} style={elStyle}>
        <img src={"/teaser-vectors/"+gLabel+cLabel+hLabel+".svg"} alt=""></img>
    </div>;
}
TeaserVector.displayName = "TeaserVector";
export default TeaserVector;