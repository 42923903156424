const worldPlanes = [
    {
        title: "G = 0",
        detailEN: "The world without gravitation",
        detailDE: "Die Welt ohne Gravitation"
    },
    {
        title: "G = 1",
        detailEN: "The world with gravitation",
        detailDE: "Die Welt mit Gravitation"
    },
    {
        title: <span>c<sup>-1</sup> = 0</span>,
        detailEN: "The Galilean world",
        detailDE: "Die Galileische Welt"
    },
    {
        title: <span>c<sup>-1</sup> = 1</span>,
        detailEN: "The relativistic world",
        detailDE: "Die relativistische Welt"
    },
    {
        title: "h = 0",
        detailEN: "The classical world",
        detailDE: "Die klassische Welt",
    },
    {
        title: "h = 1",
        detailEN: "The quantum world",
        detailDE: "Die Welt der Quanten"
    }
];

const theories = {
    "en": [
        "Newtonian Mechanics",
        "Newtonian Gravitation",
        "Special Relativity",
        "Quantum Mechanics",
        "Non-relativistic Quantum Gravity",
        "General Relativity",
        "Quantum Field Theory",
        "Theory of Everything"
    ],
    "de": [
        "Newtonsche Mechanik",
        "Newtonsche Gravitationstheorie",
        "Spezielle Relativitätstheorie",
        "Quantenmechanik",
        "Nichtrelativistische Quantengravitation",
        "Allgemeine Relativitätstheorie",
        "Quantenfeldtheorie",
        "Theorie von Allem"
    ]
};

const columnVectors = [
    {   // Theory NM
        x: 0.365, 
        y: 0.59
    },
    {   // Theory NG
        x: 0.125, 
        y: 0.775
    },
    {   // Theory SRT
        x: 0.85, 
        y: 0.59
    },
    {   // Theory QM
        x: 0.365, 
        y: 0.225
    },
    {   // Theory NRQG
        x: 0.125, 
        y: 0.4
    },
    {   // Theory GRT (TR)
        x: 0.615, 
        y: 0.775
    },
    {   // Theory QFT
        x: 0.85, 
        y: 0.225
    },
    
    {   // Theory TOE
        x: 0.615, 
        y: 0.4
    }
];

const config = {
    vectors: columnVectors,
    worldPlanes: worldPlanes,
    theories: theories
};

export default config;