import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import WorldsContentController from '../../../components/WorldsContentController';
import InlineFormula from '../../../components/InlineFormula';

// Styles 
import style from '../../../styles/ExhibitionContent.module.css';


function G0() {
    const {search} = useLocation();
    
    return <WorldsContentController teaserUrl={"/teaser-labels/G0.png"} comic={"/comics/G0.svg"} title={"The World without Gravitation"}>
        <section className={style.subtitle}>
            It comprises the following four theories: <NavLink to={"/theories/newtonian-mechanics"+search}>Newtonian Mechanics</NavLink>,&nbsp;
            <NavLink to={"/theories/quantum-mechanics"+search}>Quantum Mechanics</NavLink>,&nbsp;
            <NavLink to={"/theories/special-relativity"+search}>Special Relativity</NavLink>,&nbsp;
            and <NavLink to={"/theories/quantum-field-theory"+search}>Quantum Field Theory</NavLink>. 
            <br/>Its antipode is the <NavLink to={"/worlds/g-1"+search}>Gravitational World</NavLink>.
        </section>
        <section>
            Can we imagine a world without gravity? Gravity is the weakest of all four basic forces. However, in contradistinction to
            the electromagnetic, weak and strong force, it cannot be shielded, making it the most obvious of all forces in everyday life. 
            Actually, there are real life settings that simulate worlds where gravitation seems to be absent: for example, the parabolic 
            flight of an airplane where weightlessness occurs.
        </section>
        <section>
            Describing our macroscopic world without taking gravity into account is futile, of course. In the microscopic world of particle 
            physics, however, it may safely be dispensed with. In Quantum Mechanics, for example, the classical trajectories of Newtonian 
            Mechanics are resolved into blurry, stochastic processes. Gravity a priori plays no role. The Special Theory of Relativity 
            generalises Galilei's space-time structure, once again without reference to gravity. Unsurprisingly, the unification of Quantum 
            Mechanics and Special Relativity into Quantum Field Theory neither required the inclusion of gravity.
        </section>
        <section>
            In the <InlineFormula math="G=0" /> world, space and time form merely a stage where all events take place. Bodies move according to classical or quantum 
            mechanical laws, forces act between these bodies, but the fabric of space-time itself is completely unaffected. Theories of this 
            <InlineFormula math="G=0" /> world allow to infer statements about the local nature of space-time. In Quantum Field Theory, for example, one may deduce 
            the astonishing behaviour of elementary particles under spatial reflection and time reversal. As a second example, in Special 
            Relativity one can determine the dependence of time and position measurements on the observer's frame of reference. Nevertheless, 
            the structure of space-time itself never changes in the theories of the <InlineFormula math="G=0" /> world.
        </section>
    </WorldsContentController>;
}

export default G0;