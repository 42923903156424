import React, { useEffect, useRef, useState } from 'react';

// Styles 
import style from '../styles/ExhibitionContent.module.css';

function TheoryContentPageWrapper(props) {
    const { children } = props;
    const [wrapperWidth, setWrapperWidth] = useState(0);

    const wrapper = useRef(null);

    useEffect(()=>{
        window.addEventListener("resize", handleResize);
        setWrapperWidth(wrapper.current.clientWidth);

        return () => {
            window.removeEventListener("resize", handleResize); 
        }
    }, []);

    function handleResize() {
        setWrapperWidth(wrapper.current.clientWidth);
    }

    const childrenWithProps = React.Children.map(children, child => {
        if (React.isValidElement(child) && (child.type.displayName === "ParagraphWithTitle" || child.type.displayName === "TeaserVector")) {
            return React.cloneElement(child, { wrapperWidth: wrapperWidth });
        }
        return child;
    });

    return <div className={style.contentWrapper} ref={wrapper}>
        {childrenWithProps}
    </div>;

}
export default TheoryContentPageWrapper;