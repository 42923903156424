export default class CanvasUtility {
    static drawPercentageOfAxis (ctx, arrowLength, aX, aY, bX, bY, p) {
        if (p === 0) { return; }

        let dX = (bX - aX); let dY = (bY - aY);
        let endX = dX * p + aX;
        let endY = dY * p + aY;

        let l = Math.sqrt(dX * dX + dY * dY);
        let nX = dX / l; let nY = dY / l;  

        // Line
        ctx.beginPath();
        ctx.moveTo(aX, aY);
        ctx.lineTo(endX, endY);
        ctx.stroke();

        ctx.beginPath();
        ctx.moveTo(endX + nX * arrowLength, endY + nY * arrowLength);
        ctx.lineTo(endX + nY * arrowLength * 0.25 + nX * -arrowLength * 0.1, endY + nX * -arrowLength * 0.25  + nY * -arrowLength * 0.1);
        ctx.lineTo(endX, endY);
        ctx.lineTo(endX + nY * -arrowLength * 0.25  + nX * -arrowLength * 0.1, endY + nX * arrowLength * 0.25  + nY * -arrowLength * 0.1);
        ctx.closePath();
        ctx.fill();
    }

    static drawPercentageOfLine (ctx, aX, aY, bX, bY, p) {
        if (p === 0) { return; }

        ctx.beginPath();
        ctx.moveTo(aX, aY);
        ctx.lineTo((bX - aX) * p + aX, (bY - aY) * p + aY);
        ctx.stroke();
    }

    static drawKissingLines (ctx, aX, aY, bX, bY, p) {
        let dX = (bX - aX); let dY = (bY - aY);
        let endX = dX * 0.5 + aX; // Touching Point
        let endY = dY * 0.5 + aY;

        this.drawPercentageOfLine(ctx, aX, aY, endX, endY, p);
        this.drawPercentageOfLine(ctx, bX, bY, endX, endY, p);
    }

    static drawGrowingCircle (ctx, x, y, r, p) {
        ctx.beginPath();
        ctx.arc(
            x, y, r * p, 0, 2 * Math.PI
        );
        ctx.fill();
    }

    static drawText (ctx, x, y, fontSize, text, font="Arial", italic = false) {
        ctx.font = (italic ? "italic " : "") + fontSize+"px "+font;
        ctx.fillText(text, x, y);
    }
}