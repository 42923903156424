import React from 'react';

import style from '../styles/ExhibitionIntroduction.module.css';

function ExhibitionShortText(props) {
    const { visibilityStyle, page, language } = props;

    const introTexts = {
        "en": {
            "1": {
                "title": "Origin",
                "text": <span>At the origin <i>O</i> of the cube the three fundamental constants <i>G</i>, <i>c<sup>-1</sup></i> and <i>h</i> are zero. It corresponds to pure Newtonian Mechanics without gravity. As opposed to Aristotelian natural philosophy, in the absence of external forces a body’s motion continues steadily forever.</span>
            },
            "2": {
                "title": "Natural constants",
                "text": <span>Physics knows at least three fundamental dimensionful constants: the gravitational force <i>G</i>, the inverse velocity of light <i>c<sup>-1</sup></i> and Planck's quantum of action <i>h</i>. At the origin these constants are set to zero. Every constant then adds a dimension, thus spanning the Cube of Physics.</span>
            },
            "3": {
                "title": "Worlds",
                "text": <span>Switching a fundamental constant on or off portrays one of the six faces or worlds. Space without weight is a different world than ours on earth. The world close to the speed of light, seen from our familiar environment, functions very differently, just as the world of atomic particles is different.</span>
            },
            "4": {
                "title": "Theories",
                "text": <span>While at the origin all three fundamental constants are set to zero, at the other seven corners of the cube they are set to zero or non-zero in all remaining combinations. Thereby every corner corresponds to a physical theory, up to the still to be established Theory of Everything <i>(G, c<sup>-1</sup>, h)</i>.</span>
            }
        },
        "de": {
            "1": {
                "title": "Ursprung",
                "text": <span>Am Ursprungspunkt <i>O</i> des Würfels sind die drei Konstanten <i>G</i>, <i>c<sup>-1</sup></i> and <i>h</i> gleich Null. Dies entspricht der reinen Newtonschen Mechanik ohne Schwerkraft. Ein Körper bewegt sich, ganz im Gegensatz zum Aristotelischen Denken, bis in alle Ewigkeit gleichmäßig fort, so lange keine äußeren Kräfte auf ihn einwirken.</span>
            },
            "2": {
                "title": "Naturkonstanten",
                "text": <span>Die Physik kennt mindestens drei fundamentale dimensionsbehaftete Konstanten: die Newtonsche Gravitationskonstante <i>G</i>, die reziproke Lichtgeschwindigkeit <i>c<sup>-1</sup></i> und das Plancksche Wirkungsquantum <i>h</i>. Am Würfelursprung werden diese Konstanten gleich Null gesetzt. Das Zuschalten der drei Konstanten erzeugt die Koordinatenachsen, die den Würfel aufspannen.</span>
            },
            "3": {
                "title": "Welten",
                "text": <span>Dem Ein- oder Ausschalten einer der drei Naturkonstanten entspricht jeweils eine der sechs Würfelflächen bzw. Welten. Der schwerelose Raum ist eine vollkommen andere Welt als unsere irdische. Die Welt mit endlicher Lichtgeschwindigkeit, genau wie auch die Welt der atomaren Teilchen, folgen jeweils ganz anderen Regeln als unsere gewohnte Umwelt.</span>
            },
            "4": {
                "title": "Theorien",
                "text": <span>Während am Ursprung alle drei Naturkonstanten zu Null gesetzt sind, gehen die übrigen sieben Ecken des Würfels aus den jeweils möglichen Kombinationen von Null und Nicht-Null hervor. Somit entspricht jede Ecke einer physikalischen Theorie bis hin zu der noch zu entdeckenden Theorie von Allem <i>(G, c<sup>-1</sup>, h)</i>.</span>
            }
        }
    };


    return <div className={style.shortTextWrapper}>
        <div className={`${style.shortText} ${visibilityStyle}`}>
            <span className={style.shortTextTitle}>{introTexts[language][props.page].title}</span>
            {introTexts[language][page].text}
        </div>
    </div>;

}
export default ExhibitionShortText;