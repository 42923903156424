import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import style from './styles/ScreenSaver.module.css'

function ScreenSaver() {
    const navigate = useNavigate();
    const {search} = useLocation();

    useEffect(() => {
        // NOTE: This is a hacky fail-safe-switch, as the app freezes 
        // sometimes after hours / daysof staying in screen saver screen
        //
        // "FIX": Reload page every hour
        const timeoutId = window.setTimeout(()=>{
            window.location.reload();
        }, 3600000); 

        return () => {
            window.clearTimeout(timeoutId);
        }
    }, [])

    const onClick = () => {
        navigate('/explore'+search);
    };

    return <div className={style.wrapper} onClick={onClick}>
        <a className={style.iconWrapper} href={"/explore"+search}>
            <img src="/button-icons/screensaver-icon-touch.svg"></img>
        </a>
    </div>;
}

export default ScreenSaver;