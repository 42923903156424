import React from 'react';

import ExhibitionLabel from './ExhibitionLabel';
import ColumnVectorLabel from '../../../general-components/ColumnVectorLabel';

// Helper
import CanvasUtility from '../helper/CanvasUtility';

// Configs
import animConfig from '../config/IntroAnimationConfig';
import labelConfig from '../config/LabelConfig';

// Styles
import style from '../styles/ExhibitionIntroduction.module.css';
import labelStyle from '../styles/ExhibitionLabel.module.css';
import generalLabelStyle from '../../../styles/Label.module.css';


class ExhibitionAnimationVisualiser extends React.Component {

    constructor(props) {
        super(props);

        this.canvas = React.createRef();
        
        this.maxSubContentIndex = 0;

        this.state = {
            subContentIndex : 0
        };
    }
    
    componentDidMount() {
        window.addEventListener('resize', this.onWindowResize);
        this.updateCanvasProps();

        this.animStartTime = performance.now();
        this.update();
    }

    componentDidUpdate(oldProps) {
        if (this.props.page !== oldProps.page) {
            this.animStartTime = performance.now();
            this.prevPage = oldProps.page;

            switch(this.props.page) {
                case 3:
                    this.maxSubContentIndex = 6;
                    break;
                case 4:
                case 5:
                    this.maxSubContentIndex = 9;
                    break;
                default:
                    this.maxSubContentIndex = 0;
                    break;
            }
        }
    }

    componentWillUnmount() {
        window.cancelAnimationFrame(this.requestID);
        window.removeEventListener('resize', this.onWindowResize);
    }

    updateCanvasProps = () => {
        this.dpr = window.devicePixelRatio || 1;
        this.width = this.canvas.current.clientWidth * this.dpr;
        this.height = this.canvas.current.clientHeight * this.dpr;

        this.canvas.current.width = this.width;
        this.canvas.current.height = this.height;
        
        this.refLength = Math.min(this.width, this.height);
        this.offset = {
            x: (this.width - this.refLength) / 2,
            y: (this.height - this.refLength) / 2,
        };
    
        this.ctx = this.canvas.current.getContext("2d");
    }

    canvasToScreenPosition(canvasPosX, canvasPosY) {
        return { 
            x: (canvasPosX * this.refLength + this.offset.x) / this.dpr, 
            y: (canvasPosY * this.refLength + this.offset.y) / this.dpr
        };
    }

    update = () => {
        this.ctx.clearRect(0,0,this.width, this.height);

        let deltaT = performance.now() - this.animStartTime;
        
        // Update Subcontentindex
        let newSubcontentIndex = (Math.trunc((performance.now() - this.animStartTime - 1000) / 3000)) % this.maxSubContentIndex;
        if (newSubcontentIndex !== this.state.subContentIndex) {
            this.setState({subContentIndex: newSubcontentIndex});
        }

        // Calculate stage progress 
        let stepProgress = 0;
        stepProgress = Math.min(deltaT / animConfig.stepDuration, 1);
        
        this.ctx.save();
        this.ctx.translate(this.offset.x, this.offset.y);
        this.ctx.strokeStyle = "#ffffff";
        this.ctx.fillStyle = "#ffffff";
        this.ctx.lineWidth = 1 * this.dpr;

        
        this.drawOrigin(stepProgress, 1);
        this.drawAxes(stepProgress, 2);
        this.drawCube(stepProgress, 3);
        this.drawWorldPlanes(stepProgress, 3);
        this.drawVertices(stepProgress, 4);

        this.ctx.restore();

        this.requestID = window.requestAnimationFrame(this.update);
    }

    drawOrigin = (p, page) => {
        if (this.props.page === 0 && this.prevPage === page) {
            p = 1 - p;
        } else if (this.props.page < page) {
            p = 0;
        } else if (this.props.page !== page || (this.props.page === page && this.prevPage > page)) {
            p = 1;
        }
        
        CanvasUtility.drawGrowingCircle(
            this.ctx,
            animConfig.xPos[2] * this.refLength, 
            animConfig.yPos[3] * this.refLength, 
            this.refLength * 0.005, p
        );
    }

    drawAxes = (p, page) => {
        if (this.props.page < page) {
            p = (this.prevPage >= page) ? 1 - p : 0;
        } else if (this.props.page >= page) {
            p = (this.prevPage < page) ? p : 1;
        }

        for (let i=8; i<11; i++) {
            CanvasUtility.drawPercentageOfAxis(
                this.ctx, this.refLength * 0.04,
                animConfig.vertices[2][0] * this.refLength, animConfig.vertices[2][1] * this.refLength, 
                animConfig.vertices[i][0] * this.refLength, animConfig.vertices[i][1] * this.refLength,
                p
            );
        }
    }

    drawCube = (p, page) => {
        if (this.props.page < page) {
            p = (this.prevPage === page) ? 1 - p : 0;
        } else if (this.props.page >= page) {
            p = (this.props.page === page && this.prevPage !== page + 1) ? p : 1;
        }

        animConfig.edges.forEach((edge) => {
            CanvasUtility.drawKissingLines(
                this.ctx,
                animConfig.vertices[edge[0]][0] * this.refLength, animConfig.vertices[edge[0]][1] * this.refLength,
                animConfig.vertices[edge[1]][0] * this.refLength, animConfig.vertices[edge[1]][1] * this.refLength,
                p
            );
        });
    }

    drawWorldPlanes = (p, page) => {
        // World planes are only drawn when page 4 is actice, after the animation finishes
        if (page !== this.props.page || p < 1) { return; }

        let face = animConfig.faces[this.state.subContentIndex];
        
        if (face) {
            this.ctx.save();
            this.ctx.globalAlpha = 0.5;
            this.ctx.beginPath();
            
            let vert = animConfig.vertices[face[0]];
            this.ctx.moveTo(vert[0]*this.refLength, vert[1]*this.refLength);
            vert = animConfig.vertices[face[1]];
            this.ctx.lineTo(vert[0]*this.refLength, vert[1]*this.refLength);
            vert = animConfig.vertices[face[2]];
            this.ctx.lineTo(vert[0]*this.refLength, vert[1]*this.refLength);
            vert = animConfig.vertices[face[3]];
            this.ctx.lineTo(vert[0]*this.refLength, vert[1]*this.refLength);
            
            this.ctx.closePath();
            this.ctx.fill();
            this.ctx.restore();
        }
    }

    drawWorldLabels = (p, page) => {
        if (page !== this.props.page) {
            return;
        }

        this.drawBoldLabel(0.45, 0.075, labelConfig.worldPlanes[this.state.subContentIndex].title);
        if (this.props.language === "de") {
            this.drawLightLabel(0.45, 0.125, labelConfig.worldPlanes[this.state.subContentIndex].detailDE);
        } else {
            this.drawLightLabel(0.45, 0.125, labelConfig.worldPlanes[this.state.subContentIndex].detailEN);
        }
        
        if (this.state.subContentIndex === 2 || this.state.subContentIndex === 3) {
            CanvasUtility.drawText(
                this.ctx,
                0.48 * this.refLength, 
                0.04 * this.refLength,
                Math.min(this.refLength * 0.03, 44*this.dpr), 
                "-1",
                "Oswald"
            );
        }
    }

    drawVertices = (p, page) => {
        if (this.props.page < page) {
            p = (this.prevPage === page) ? 1 - p : 0;
        } else if (this.props.page >= page) {
            p = (this.props.page === page && this.prevPage !== page + 1) ? p : 1;
        }

        animConfig.vertices.forEach((vert, index) => {
            if (index >= 8) { return; }

            CanvasUtility.drawGrowingCircle(
                this.ctx,
                vert[0] * this.refLength, 
                vert[1] * this.refLength, 
                this.refLength * 0.005, p
            );
        });
    }

    onWindowResize = () => {
        this.updateCanvasProps();
    };

    calcLabelPosition = (x, y) => {
        return { 
            x: (x*this.refLength+this.offset?.x)/this.dpr, 
            y: (y*this.refLength+this.offset?.y)/this.dpr
        };
    }

    calcVectorLabelPosition = (id) => {
        return { 
            x: (animConfig.vertices[id][0] * this.refLength+this.offset?.x)/this.dpr, 
            y: (animConfig.vertices[id][1] * this.refLength+this.offset?.y)/this.dpr
        };
    }

    isTheoryVectorVisible = (id) => {
        return (this.props.page === 4 && (this.state.subContentIndex === id || this.state.subContentIndex === 8)) 
            || this.props.page === 5;
    }

    render() {
        const detailAxisStyle = (this.props.page === 2) ? generalLabelStyle.fadeIn : generalLabelStyle.fadeOut;

        const light = (this.props.language === "de") ? "Lichtgeschwindigkeit" : "Velocity of light";
        const h = (this.props.language === "de") ? "Wirkungsquantum" : "quantum of action";

        return <div className={style.visualiser}>
            <canvas className={style.introAnimationCanvas} ref={this.canvas}/>
            <ExhibitionLabel typeStyle="bold" pos={this.calcLabelPosition(animConfig.xPos[2], animConfig.xPos[3])} offset={"topRight"} visible={this.props.page === 1}>O</ExhibitionLabel>
            <ExhibitionLabel typeStyle="bold" pos={this.calcLabelPosition(animConfig.vertices[8][0], animConfig.vertices[8][1])} offset={"bottomRight"} visible={this.props.page >= 2}>
                <div>G</div>
                <div className={`${labelStyle.lightLabel} ${detailAxisStyle}`}>Gravitation</div>
            </ExhibitionLabel>
            <ExhibitionLabel typeStyle="bold" pos={this.calcLabelPosition(animConfig.vertices[9][0], animConfig.vertices[9][1])} offset={"bottomLeft"} right visible={this.props.page >= 2}>
                <div>c<sup>-1</sup></div>
                <div className={`${labelStyle.lightLabel} ${detailAxisStyle}`}>{light}</div>
            </ExhibitionLabel>
            <ExhibitionLabel typeStyle="bold" pos={this.calcLabelPosition(animConfig.vertices[10][0], animConfig.vertices[10][1])} offset={"bottomLeft"} right visible={this.props.page >= 2}>
                <div>h</div>
                <div className={`${labelStyle.lightLabel} ${detailAxisStyle}`}>Planck's<br/>{h}</div>
            </ExhibitionLabel>
            <ExhibitionLabel typeStyle="bold" pos={this.calcLabelPosition(animConfig.vertices[10][0], animConfig.vertices[10][1])} offset={"rightHalfTop"} padding visible={this.props.page === 3}>
                <div>{labelConfig.worldPlanes[this.state.subContentIndex]?.title}</div>
                <div className={labelStyle.lightLabel}>{labelConfig.worldPlanes[this.state.subContentIndex]?.["detail"+this.props.language.toUpperCase()]}</div>
            </ExhibitionLabel>
            <ExhibitionLabel typeStyle="bold" pos={this.calcLabelPosition(animConfig.vertices[10][0], animConfig.vertices[10][1])} offset={"rightHalfTop"} padding visible={this.props.page === 4}>
                <div className={labelStyle.lightLabel}>{labelConfig.theories[this.props.language][this.state.subContentIndex]}</div>
            </ExhibitionLabel>
            
            <ColumnVectorLabel g={false} h={false} c={false} pos={this.calcVectorLabelPosition(2)}
                visible={this.isTheoryVectorVisible(0)} offset={"topRight"} width={this.refLength*0.025} />
            <ColumnVectorLabel g={true}  h={false} c={false} pos={this.calcVectorLabelPosition(0)}
                visible={this.isTheoryVectorVisible(1)} offset={"topLeft"} width={this.refLength*0.025} />
            <ColumnVectorLabel g={false} h={false} c={true}  pos={this.calcVectorLabelPosition(3)}
                visible={this.isTheoryVectorVisible(2)} offset={"topRight"} width={this.refLength*0.025} />
            <ColumnVectorLabel g={false} h={true}  c={false} pos={this.calcVectorLabelPosition(6)}
                visible={this.isTheoryVectorVisible(3)} offset={"bottomRight"} width={this.refLength*0.025} />             
            <ColumnVectorLabel g={true}  h={true}  c={false} pos={this.calcVectorLabelPosition(4)}
                visible={this.isTheoryVectorVisible(4)} offset={"bottomLeft"} width={this.refLength*0.025} />
            <ColumnVectorLabel g={true}  h={false} c={true}  pos={this.calcVectorLabelPosition(1)}
                visible={this.isTheoryVectorVisible(5)} offset={"topLeft"} width={this.refLength*0.025} />
            <ColumnVectorLabel g={false} h={true}  c={true}  pos={this.calcVectorLabelPosition(7)}
                visible={this.isTheoryVectorVisible(6)} offset={"bottomRight"} width={this.refLength*0.025} />
            <ColumnVectorLabel g={true}  h={true}  c={true}  pos={this.calcVectorLabelPosition(5)}
                visible={this.isTheoryVectorVisible(7)} offset={"bottomLeft"} width={this.refLength*0.025} />
        </div>;
    }
}
export default ExhibitionAnimationVisualiser;