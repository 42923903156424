import React from 'react';

// Components
import ExploreVisualizer from './components/ExploreVisualizer';
import BurgerMenu from '../../general-components/BurgerMenu';

// Styles
import baseStyle from './styles/NewExplore.module.css';

function Explore(props) {
    return (
        <div className={baseStyle.wrapper}>
            <BurgerMenu language={props.language} mediaStationMode={props.mediaStationMode}/>
            <ExploreVisualizer />
        </div>
    );
}

export default Explore;
