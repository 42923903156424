import React from 'react';

import TheoryContentPageWrapper from '../../../components/TheoryContentPageWrapper';
import ParagraphWithTitle from '../../../components/ParagraphWithTitle';
import FullRowImage from '../../../components/FullRowImage';
import InlineFormula from '../../../components/InlineFormula';
import TeaserVector from '../../../components/TeaserVector';
import CaptionedImage from '../../../components/CaptionedImage';

// Styles 
import style from '../../../styles/ExhibitionContent.module.css';

function TheoryOfEverything() {
    return <TheoryContentPageWrapper>
        <TeaserVector g={true} c={true} h={true}/>
        <div className={style.singleColumnParagraph}>
        <h1 className={`${style.title} ${style.mainTitleTopOffset}`}>Theorie von Allem</h1>
            <section>
                Der bisher unentdeckte heilige Gral der modernen Physik. Diese "Theorie von 
                Allem" würde alle drei fundamentalen Konstanten <InlineFormula math="G, c^{-1}"/> und <InlineFormula math="h"/> umfassen. 
                Es gibt eine ganze Reihe von ambitionierten Anwärtern auf den Thron, allen 
                voran die Stringtheorie und die Schleifenquantengravitation. Allerdings ließ 
                sich bisher für keine dieser beiden Theorien überprüfen, ob sie korrekt 
                ist &ndash; vermutlich sind am Ende sogar beide falsch, zumindest aber 
                unvollständig. Durch die Kombination der drei fundamentalen 
                Naturkonstanten <InlineFormula math="G, c^{-1}"/> und <InlineFormula math="h"/> können wir
                natürliche Einheiten für Raum, Zeit und Masse ableiten, die sogenannten 
                Planck-Einheiten. Dabei stellt sich heraus, dass die Quantengravitation in 
                Größendimensionen relevant wird, die diesen Planck-Einheiten entsprechen. 
            </section>
            <section>
                Leider sind diese Skalen extrem weit von allem entfernt, was wir experimentell 
                direkt erschließen können. So liegt die Planck-Länge bei etwa <InlineFormula math="10^{-35}" /> Metern, 
                das ist eine geradezu unvorstellbare <InlineFormula math="10^{20}"/>-mal kleinere 
                Skala als die Größe des Protons.
            </section>
        </div>
        <FullRowImage src="/intro-images/theory-of-everything.svg"/>
        <ParagraphWithTitle titlePos="right" title="Eine Weltformel?">
            <section>
                Was ist nun die Theorie an der Ecke <InlineFormula math="(G, c^{-1}, h)"/> des 
                Würfels der Physik? Sie sollte mathematisch konsistent und falsifizierbar sein, 
                also experimentell überprüfbare Vorhersagen liefern können. Zudem sollte sie alle 
                anderen Theorien, die an den restlichen Ecken des Würfels sitzen, als Grenzfälle 
                enthalten, indem man die Konstanten <InlineFormula math="G, c^{-1}"/> und <InlineFormula math="h"/> auf 
                geeignete Weise individuell oder in Kombinationen auf Null setzt. Im Deutschen wird diese 
                gesuchte Theorie oft verkürzt als "Weltformel" bezeichnet, obwohl es natürlich a priori 
                unklar ist, ob sie im Falle ihrer Existenz auf einer einzigen Formel basiert. Im Englischen 
                heißt sie dagegen ganz unbescheiden "Theory of Everything", übersetzt also "Theorie von Allem".
            </section>
            <section>
                Die Theorie von Allem sollte insbesondere Quantenfeldtheorie und Allgemeine Relativitätstheorie 
                vereinen. Die Gravitation soll also 'quantisiert' und die Konstante <InlineFormula math="h"/> mit 
                der Gravitation in Verbindung gebracht werden. 'Quantisiert' man jedoch die Allgemeine 
                Relativitätstheorie in Analogie etwa zur Elektrodynamik auf naive Weise, z.B. indem man über 
                alle möglichen Metriken summiert, führt dies zu einer Katastrophe: Eine unendliche Vielzahl 
                von unbezähmbaren 'Divergenzen' zerstört die Voraussagekraft der Theorie.
            </section>
            <section>
                Die beiden prominentesten aller aktuell verfolgten Ansätze, die diese Schwierigkeiten zu 
                umgehen suchen, sind die Superstringtheorie und die Schleifenquantengravitation. Beiden 
                ist gemein, dass sie von einer experimentellen Überprüfbarkeit weit entfernt sind, und, 
                damit zusammenhängend, dass es jeweils eine ungeheure Vielzahl unterschiedlicher Versionen 
                gibt. Es handelt sich also eher um Theorieklassen als um eindeutig definierte Theorien.
            </section>
        </ParagraphWithTitle>
        <CaptionedImage src="/theory-illustrations/theory-of-everything.jpg" caption="Kosmische Hintergrundstrahlung." />
        <ParagraphWithTitle titlePos="left" title="Superstring theory">
            <section>
                Die Superstringtheorie hat ihre Wurzeln in der Quantenfeldtheorie. Deren 
                Punktteilchen werden durch schwingende Saiten, die sogenannten Strings, ersetzt, 
                um die verschiedenen Teilchen der Natur als Schwingungszustände der Strings 
                darstellen zu können. Durch diesen Ansatz verschwinden die Divergenzen, 
                Teilchen- und Gravitationsphysik werden vereint. Ein hypothetisches "Graviton" 
                tritt auf, alle Welten werden zehn- oder sogar elfdimensional, und unsere eigene 
                Welt ist offenbar nur eine von mindestens <InlineFormula math="10^{500}"/> vielen. 
                Einige dieser Welten ähneln dem Standardmodell der Teilchenphysik, aber leider 
                wurde darunter bisher noch keine entdeckt, die Letzteres genau liefert. Ein 
                spekulativer Ausweg ist die Idee des Multiversums: Viele dieser möglichen 
                Welten existieren demzufolge in nicht miteinander kommunizierenden Paralleluniversen. 
                In diesem Szenario wären die vielen bisher unerklärten Parameter des Standardmodells 
                letztlich entweder zufällig gesetzt oder aber schlichtweg dadurch festgelegt, dass 
                sie intelligentes Leben ermöglichen (anthropisches Prinzip). Kein besonders 
                attraktives Szenario.
            </section>
        </ParagraphWithTitle>
        <ParagraphWithTitle titlePos="right" title="Schleifenquantengravitation">
            <section>
                Die Schleifenquantengravitation hat ihre Wurzeln in der Allgemeinen Relativitätstheorie. 
                Letztere wird direkt quantisiert; dies führt zu einer Art 'Quantenschaum' auf der Planck-Skala 
                im Bereich von <InlineFormula math="10^{-35}"/> m. Es gibt viele sich teilweise 
                widersprechende Zugänge, aber in bisher keinem eine natürliche Vereinheitlichung mit 
                den Quantenfeldtheorien des Standardmodells. Die Quantenfeldtheorien des Standardmodells 
                müssen gewissermaßen 'hinzugeheftet' werden. Auch nicht sonderlich attraktiv.
            </section>
        </ParagraphWithTitle>
        <ParagraphWithTitle titlePos="left" title="Offene Fragen ...">
            <section>
                Benötigt man überhaupt eine Theorie, die Quantenfeldtheorie und Allgemeine 
                Relativitätstheorie vereint? In dieser Frage herrscht weitgehende, bejahende 
                Einigkeit in der Physik. Es ist nämlich offensichtlich, dass sowohl die 
                Detailbeschreibung Schwarzer Löcher als auch diejenige des Urknalls die 
                Quantenmechanik erfordern. Zudem ist es zumindest experimentell klar, 
                dass etwas Grundlegendes im aktuellen Theoriegebäude der Physik fehlt: 
                Etwa 96 Prozent der Materie und der Energie unseres Universums sind wohl 
                noch unbekannt, oder wie man sagt, 'dunkel'.
            </section>
        </ParagraphWithTitle>
    </TheoryContentPageWrapper>;
}

export default TheoryOfEverything;