import React from 'react';

import TheoryContentPageWrapper from '../../../components/TheoryContentPageWrapper';
import ParagraphWithTitle from '../../../components/ParagraphWithTitle';
import FullRowImage from '../../../components/FullRowImage';
import InlineFormula from '../../../components/InlineFormula';
import TeaserVector from '../../../components/TeaserVector';
import CaptionedImage from '../../../components/CaptionedImage';
import Cite from '../../../components/Cite';

// Styles 
import style from '../../../styles/ExhibitionContent.module.css';

function SRT() {
    return <TheoryContentPageWrapper>
        <TeaserVector g={false} c={true} h={false}/>
        <div className={style.singleColumnParagraph}>
            <h1 className={`${style.title} ${style.mainTitleTopOffset}`}>Special Relativity</h1>
            <section>
                The idea that the speed of light <InlineFormula math="c" /> is a fundamental
                constant for all possible observers, i.e. independent of their own state of
                motion, seems contradictory at least at first sight. For example, a light
                beam can never be caught up if its speed relative to the observer is always
                constant. And how can one explain, that a light beam emitted by a moving
                car has the same speed for the driver as for an observer standing at the
                side of the road? These apparent contradictions were solved by Albert
                Einstein in 1905 as part of his Special Theory of Relativity. This required
                radical questioning of the assumptions of Galileo and Newton on the
                structure of space and time. In 1907, Hermann Minkowski drew the
                mathematical and conceptual consequences from Einstein's ideas and proposed
                to replace the three-dimensional Euclidean space with a four-dimensional
                space-time.
            </section>
        </div>
        <FullRowImage src="/intro-images/special-relativity.svg" />
        <ParagraphWithTitle titlePos="right" title="Electromagnetism and relativity">
            <section>
                Even though the speed of light was anchored as a new fundamental constant
                in physics with the Special Theory of Relativity, and thus a third
                dimension became necessary for the cube model in addition to <InlineFormula math="G"/> and <InlineFormula math="h" />, 
                its beginnings reach far into the 19th century.
            </section>
            <section>
                In 1873 James Clerk Maxwell not only unified electricity and magnetism, but
                also identified light as an electromagnetic phenomenon. Since
                electromagnetism was explained as the physical state of an all-pervading
                ether, it was obvious to prove its existence experimentally. However, the
                famous interferometer experiments of the 1880s by Edward Morley and Albert
                Michelson showed that the speed at which light travels does not depend in
                any way on the motion of the earth through the ether. The complex history
                of the Lorentz transformation began, which described the exact
                transformation behavior of Maxwell's equations, but whose physical
                interpretation was not made until 1905 with the Special Theory of
                Relativity.
            </section>
        </ParagraphWithTitle>
        <CaptionedImage src="/theory-illustrations/special-relativity.png" caption="Space-time cone by Hermann Minkowski (1909)." />
        <ParagraphWithTitle titlePos="left" title="Minkowski space">
            <section>
                In it, Einstein attributed the electrodynamics of moving bodies to two
                fundamental postulates: the relativity of simultaneity and the absolute
                constancy of the speed of light <InlineFormula math="c"/> in all inertial
                systems. This was accompanied by a fundamentally new, etherless concept of
                space and time.  Hermann Minkowski drew the decisive conclusions from this.
                He showed that the works of Lorentz and Einstein can be formulated most
                elegantly in a four-dimensional, non-euclidean vector space &ndash; the
                Minkowski space. Space and time merge into the unity of space-time. In a
                lecture on September 21, 1908, Minkowski therefore began his remarks with
                the captivating words:
            </section>
            <Cite>
                The concepts about time and space, which I would like to develop before you
                today, have grown on experimental physical grounds. Herein lies their
                strength. Their tendency is radical. Henceforth, space for itself, and time
                for itself shall completely reduce to a mere shadow, and only some sort of
                union of the two shall preserve independence. (Minkowski 1908)
            </Cite>
            <section>
                However, the Special Theory of Relativity did not make the concept of
                space-time absolutely necessary, and so Einstein rejected it for the time
                being.
            </section>
        </ParagraphWithTitle>
        <ParagraphWithTitle titlePos="right" title="Lorentz transformations">
            <section>
                The Special Theory of Relativity reveals that the Galilean transformation
                must be replaced as the pervasive concept of Newtonian mechanics and
                gravitation.  The simple addition of velocities according to Galilei in two
                inertial systems is only correct within the limits of vanishing relative
                velocities; in the case of finite relative velocities, lengths and times
                depend on the observer's state of motion. The laws of physics must
                therefore behave invariantly under Lorentz transformations. The
                consequences for reference systems moving uniformly against each other are
                captured by the Special Theory of Relativity and are reflected in well
                known phenomena like time dilation or length contraction. The speed of
                light in vacuum <InlineFormula math="c" /> plays a central role in this
                theory. No signal can be transmitted at a higher speed than the vacuum
                speed of light <InlineFormula math="c" /> &ndash; <InlineFormula math="c" /> is
                declared a fundamental constant of nature.
            </section>
            <section>
                For accelerated reference systems, however, a completely new structure is
                required, which can only be detected within the framework of General
                Relativity, i.e. the cube corner <InlineFormula math="(G, c^{-1}, 0)" />.
                Indispensable necessity for this is also the unification of space and time,
                as formulated by Minkowski's four-dimensional space-time.
            </section>
        </ParagraphWithTitle>
    </TheoryContentPageWrapper>
}
export default SRT;
