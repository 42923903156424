import React, { useEffect, useState } from 'react';

// Styles
import style from '../styles/ExhibitionIntroduction.module.css';

import ExhibitionShortText from './ExhibitionShortText';
import ExhibitionAnimationVisualiser from './ExhibitionAnimationVisualiser';
import ExhibitionShortTextQuickNavMenu from './ExhibitionShortTextQuickNavMenu';

function ExhibitionFullPageContent(props) {
    const { page, onPageUpdate, scrollOffset, language } = props;

    const [activePage, setActivePage] = useState(2);

    useEffect(() => {
        let cappedPageId = getCappedPageId(page);
        if (cappedPageId !== activePage) {
            setTimeout(() => {
                setActivePage(cappedPageId);
            }, 250);
        }
    }, [page]);

    function getCappedPageId(pageId) {
        return Math.min(Math.max(1, pageId), 4);
    }


    let cappedPageId = getCappedPageId(page);
    let visibilityStyle = (cappedPageId !== activePage) ? style.fadeOut : style.fadeIn;

    return <div className={style.fullHeightWrapper} style={{ top: scrollOffset + "px" }}>
        <ExhibitionShortTextQuickNavMenu page={page} onPageUpdate={onPageUpdate} />
        <div className={style.contentBox}>
            <ExhibitionAnimationVisualiser active={page > 0} page={page} language={language} />
            <ExhibitionShortText page={activePage} language={language} visibilityStyle={visibilityStyle} />
        </div>
    </div>;

}
export default ExhibitionFullPageContent;