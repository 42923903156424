import React from 'react';

// Styles
import baseStyle from '../styles/Exhibition.module.css';

function ExhibitionIntrotext(props) {
    const en = <div className={baseStyle.introText}>
        <p>
            The Cube of Physics is a model of our knowledge about physics. It entangles its history and structure 
            based on the three fundamental constants, <i>G</i>, <i>c<sup>-1</sup></i> and <i>h</i>.
        </p>
        <p>
            With this website you can explore the interrelation of physical theories from Newtonian Mechanics 
            to the Theory of Everything. You will understand the difference between theories and worlds and dive 
            into a virtual cube.
        </p>
    </div>;

    const de = <div className={baseStyle.introText}>
        <p>
            Der Würfel der Physik ist ein Modell des Theoriengebäudes der Physik. Ausgehend von den drei 
            Naturkonstanten <i>G</i>, <i>c<sup>-1</sup></i> und <i>h</i> verbindet er Geschichte und Struktur der Theorien.
        </p>
        <p>
            Auf dieser Webseite können Sie das Ineinandergreifen der physikalischen Theorien von Newtonscher Mechanik 
            hin zur Theorie von Allem erkunden. Entdecken Sie den Unterschied zwischen Theorien und Welten und 
            tauchen Sie in einen virtuellen Würfel ein.
        </p>
    </div>;

    return (props.language === "de") ? de : en;
}
export default ExhibitionIntrotext;