import React from 'react';

// Styles 
import style from '../styles/ExhibitionContent.module.css';

function SingleColumnParagraph(props) {
    const {children, scrollable} = props;
    
    const scrollableStyle = scrollable ? style.scrollableTextWrapper : null;

    return <div className={scrollableStyle}>{children}</div>;
}
export default SingleColumnParagraph;