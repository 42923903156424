import React from 'react';

import TheoryContentPageWrapper from '../../../components/TheoryContentPageWrapper';
import ParagraphWithTitle from '../../../components/ParagraphWithTitle';
import FullRowImage from '../../../components/FullRowImage';
import InlineFormula from '../../../components/InlineFormula';
import TeaserVector from '../../../components/TeaserVector';
import CaptionedImage from '../../../components/CaptionedImage';

// Styles 
import style from '../../../styles/ExhibitionContent.module.css';

function NM() {
    
    return <TheoryContentPageWrapper>
        <TeaserVector g={false} c={false} h={false}/>
        <div className={style.singleColumnParagraph}>
            <h1 className={`${style.title} ${style.mainTitleTopOffset}`}>Newtonian Mechanics</h1>
            <section>
                Imagine a universe in which gravity can be neglected. Where things move very
                slowly compared to the speed of light. And in which quantum effects are too
                small to be perceived. Such a universe would be fully described by the laws of
                Galilei's space-time model and Newtonian mechanics. 
            </section>
            <section>
                In the cube of physics,
                this corresponds to the theory in which the gravitational constant <InlineFormula math="G"/>, the
                inverse of speed of light <InlineFormula math="c^{-1}"/> and Planck's quantum of action <InlineFormula math="h"/> are all
                set to zero. All objects in this universe have a certain position in an
                absolute space. Time is a parameter that uniformly and perpetually counts the
                passing of seconds, minutes and hours everywhere. Every movement corresponds to
                a fixed path, which can be calculated. Sounds familiar? Strangely enough, this
                theory does not describe reality. It is merely an approximation.
            </section>
        </div>
        <FullRowImage src="/intro-images/newtonian-mechanics.svg"/>
        <ParagraphWithTitle title="Newton's foundations" titlePos="right">
            <section>
                From the perspective of modern physics, Newtonian mechanics deals with the
                motion of sufficiently slow classical bodies under the influence of a system of
                arbitrary physical forces. Thus, pure Newtonian mechanics <i>per se</i> knows no
                gravity <InlineFormula math="G"/>, no finite speed of light <InlineFormula math="c"/>, and no quantum of action <InlineFormula math="h"/>. It is
                the theory <InlineFormula math="(0, 0, 0)"/> of the cube of physics and thus marks the origin of the
                coordinate system with the three fundamental natural constants defining its
                axes. At the same time, it assigns all other theories their proper place in the
                model.
            </section>
            <section>
                If gravity is added, Newtonian mechanics remains intact. It is merely extended
                to the theory <InlineFormula math="(G, 0, 0)"/>, which adds Newton's law of gravity and the
                conceptual idea of the identity between inertial and heavy mass. Hence, this
                theory defines a new corner of the cube.
            </section>
        </ParagraphWithTitle>
        <CaptionedImage src="/theory-illustrations/newtonian-mechanics.jpg" 
            caption={"Frontispiece from the \"Dialogue concerning the two chief world systems\" (1623) with Galilei's thought experiment in the background."} />
        <ParagraphWithTitle title="Galilei's space-time model" titlePos="left">
            <section>
                Newtonian mechanics is based on Galilei's model of space-time. It assumes that
                two inertial systems are physically indistinguishable. Take Galilei's ship
                moving uniformly and rectilinearly in his famous thought experiment: Standing
                below decks, all hatches closed and no smartphone at hand, one can determine
                one's own movement relative to the ship, but not relative to the sea. His
                thought experiment was so important to Galilei, that he placed it at the
                beginning of his "Dialogue concerning the two chief world systems", between the
                figures of Aristotle, Ptolemy and Copernicus. Moreover, Newtonian mechanics
                asserts an absolute time, identical in all inertial systems. Velocities of
                inertial systems add up linearly. This is sufficiently accurate for the
                description of everyday mechanical systems. 
            </section>
        </ParagraphWithTitle>
        <ParagraphWithTitle title="Subsequent corrections" titlePos="right">
            <section>
                However, Galilei's space-time model
                leads to logical contradictions with Maxwell's equations of electrodynamics.
                Thus fundamental corrections for high velocities are needed. These can only be
                understood within the framework of Einstein's Lorentz-invariant Special
                Relativity, i.e. the theory <InlineFormula math="(0, c^{-1}, 0)"/>.
            </section>
            <section>
                Classical mechanics experiences even more drastic corrections on atomic and
                subatomic length scales. It loses its deterministic character and is
                statistically reformulated within the framework of quantum mechanics, the
                theory <InlineFormula math="(0, 0, h)"/>. Hence, all three corrections to classical mechanics point
                to new corners of the cube. The two theories <InlineFormula math="(0, 0, 0)"/>, Newtonian mechanics,
                and <InlineFormula math="(G, 0, 0)"/>, Newtonian gravitational theory, were developed by Newton at
                the same time; so here, the systematics of the cube contradicts the history of
                physics in a certain way. Newton is thus located on two corners of the cube,
                but he never made this distinction himself. The corrections <InlineFormula math="(0, c^{-1}, 0)"/>
                and <InlineFormula math="(0, 0, h)"/> on the other hand, as is well known, were only obtained at the
                beginning of the 20th century; here, the cube reflects the history of
                knowledge.
            </section>
        </ParagraphWithTitle>
    </TheoryContentPageWrapper>;
}
export default NM;