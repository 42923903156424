const faceMap = {
    "0": "c-1",
    "1": "c-1",
    "2": "h-1",
    "3": "h-1",
    "4": "g-0",
    "5": "g-0",
    "6": "c-0",
    "7": "c-0",
    "8": "g-1",
    "9": "g-1",
    "10": "h-0",
    "11": "h-0"
}

export default faceMap;