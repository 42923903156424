import React from 'react';

import TheoryContentPageWrapper from '../../../components/TheoryContentPageWrapper';
import ParagraphWithTitle from '../../../components/ParagraphWithTitle';
import FullRowImage from '../../../components/FullRowImage';
import InlineFormula from '../../../components/InlineFormula';
import TeaserVector from '../../../components/TeaserVector';
import CaptionedImage from '../../../components/CaptionedImage';
import Cite from '../../../components/Cite';

// Styles 
import style from '../../../styles/ExhibitionContent.module.css';

function NewtonianGravitation() {
    return <TheoryContentPageWrapper>
        <TeaserVector g={true} c={false} h={false}/>
        <div className={style.singleColumnParagraph}>
            <h1 className={`${style.title} ${style.mainTitleTopOffset}`}>Newtonsche Gravitationstheorie</h1>
            <section>
                Eine der großen Leistungen Newtons bestand in der Entdeckung eines allgemeinen Gesetzes der Gravitation. 
                Dieses besagt, dass jeder Massenpunkt im Universum jeden anderen Massenpunkt mit einer 
                Kraft <InlineFormula math="F"/> anzieht, die proportional zum Produkt ihrer 
                Massen <InlineFormula math="M"/> und <InlineFormula math="m"/> und umgekehrt proportional zum 
                Quadrat ihres Abstands <InlineFormula math="r"/> ist: Die zugehörige Proportionalitätskonstante wird als Newtonsche 
                Gravitationskonstante <InlineFormula math="G"/> bezeichnet. Sie ist die einzige fundamentale Naturkonstante, 
                die in dieser Theorie und damit an dieser Ecke des Würfels berücksichtigt wird. 
            </section>
            <section>
                In Kombination mit der Newtonschen Mechanik der Ecke <InlineFormula math="(0, 0, 0)"/> kann 
                man mit dieser Theorie in erstaunlicher Genauigkeit sowohl das Herunterfallen von Objekten 
                auf der Erde, als auch das 'Herumfallen' von Himmelsobjekten wie Monden, Planeten, Meteoriten 
                und Satelliten im Planetensystem beschreiben.
            </section>
        </div>
        <FullRowImage src="/intro-images/newtonian-gravity.png" />
        <ParagraphWithTitle titlePos="right" title="Newtons Gravitationstheorie">
            <section>
                Im dritten Buch "De mundi systemate" seiner "Philosophiae Naturalis Principia Mathematica" 
                führt Isaac Newton die Bewegung der Planeten und ihrer Satelliten auf die Wirkung einer 
                universellen Gravitation zurück. Diese Kraft hält die Objekte des Himmels in ihrer Bahn, 
                schreibt Newton, und verhält sich umgekehrt proportional zum Quadrat des Abstandes zum 
                Schwerpunkt der Sonne bzw. des jeweiligen Planeten. Aber auch die Masse spielte dabei eine 
                wesentliche Rolle:
            </section>
            <Cite>
                Wenn die Materie zweier Kugeln, die zueinander hin wechselseitig Schwere zeigen, in den Bereichen, 
                die vom Mittelpunkt gleichen Abstand haben, homogen ist, so wird sich das Gewicht jeder Kugel 
                zur anderen umgekehrt wie das Quadrat des Abstandes zwischen ihren Mittelpunkten verhalten. (Newton 1687)
            </Cite>
            <section>
            In heutiger Formulierung lautet das Gravitationsgesetz
            </section>
            <Cite center>
                <InlineFormula math="F=G\frac{Mm}{r^2}"/> <br/>
                Newtons Gravitationsgesetz
            </Cite>
            <section>
            wobei <InlineFormula math="M"/> und <InlineFormula math="m"/> die Gesamtmassen der beiden Körper sind.
            </section>
        </ParagraphWithTitle>
        <CaptionedImage src="/theory-illustrations/newtonian-gravity.jpg" caption="Ein 1,3 kg schwerer Aluminiumhammer und eine 30 g schwere Falkenfeder fallen auf dem Mond gleich schnell." />
        <ParagraphWithTitle titlePos="left" title="Äquivalenzprinzip">
            <section>
                Ein integraler Bestandteil von Newtons Gravitationstheorie ist das schwache Äquivalenzprinzip. Dieses 
                besagt, dass träge und schwere Masse eines jeden Körpers identisch sind. Bereits Galileo hatte erkannt, 
                dass alle Körper daher gleich schnell fallen. Den notwendigen Beweis für diese Äquivalenz lieferte 
                Newton experimentell mit einem speziellen Paar von Pendeln. Bis heute wird diese Äquivalenz mit 
                höchst komplexen Experimenten erforscht. Eine Abweichung zwischen schwerer und träger Masse hat 
                sich dabei noch nie offenbart. Besonders eindrucksvoll demonstrierte die Mondmission Apollo 15, 
                wie eine Feder und ein Hammer gleich schnell fallen.
            </section>
        </ParagraphWithTitle>
        <ParagraphWithTitle titlePos="right" title="Fernwirkung">
            <section>
                Für Newton besaß das Gravitationsgesetz allerdings einen ausschließlich relationalen Charakter; 
                er kannte keinen numerischen Wert für die Gravitationskonstante <InlineFormula math="G"/>. 
                Doch auch aus der Proportionalität der universellen Gravitation leitete er epochale Konsequenzen 
                ab – zuvorderst, dass jedes Binärsystem aus Sonne und Planet eine elliptische Umlaufbahn um 
                die Sonne zur Folge hat, in deren einem Fokus die Sonne liegt. Newton formulierte damit eine 
                dynamische und mathematische Herleitung für die bereits empirisch bekannte Bahn von Planeten: 
                "whereas Kepler guessed right at the Ellipsis". (Newton an Halley 1686) Trotz der revolutionären 
                Erfolge blieb für viele Zeitgenossen und Nachfolger Newtons bis hin zu Einstein der Charakter der
                instantanen Fernwirkung in seinem Gravitationsgesetz äußerst fragwürdig.
            </section>
        </ParagraphWithTitle>
        <ParagraphWithTitle titlePos="left" title="Cavendish Experiment">
            <section>
                Konzeptionell aber nicht historisch fällt die Einführung der Naturkonstante <InlineFormula math="G"/> für 
                die Gravitationswechselwirkung also mit Newtons Theoriebildung zusammen. <InlineFormula math="G"/> definiert 
                die erste der drei Achsen des kartesischen Koordinatensystems des Würfels. Experimentell wurde sie 
                erst 1798 durch Henry Cavendish bestimmt, der für seine Berechnung eine geschätzte Dichte der Erde 
                zugrunde gelegt hat. Der heutige Wert beträgt 
            </section>
            <Cite center><InlineFormula math="6,6740\times10^{-11} m^3\cdot kg^{-1}\cdot s^{-2}"/>,</Cite>
            <section>
                mit einer erstaunlich großen Unsicherheit von <InlineFormula math="2,2\times 10^{-5}"/>.
            </section>
        </ParagraphWithTitle>
    </TheoryContentPageWrapper>;
}

export default NewtonianGravitation;