export default class LabelHelper {
    constructor(pos = {x: 0, y: 0}, visible= true) {
        this.pos = pos;
        this.visible = visible;
    }

    updatePosition = (newPos) => {
        let unchanged = ((newPos.x === this.pos.x) && (newPos.y === this.pos.y));
        if (!unchanged) {
            this.pos = newPos;
        }

        return !unchanged;
    }
}