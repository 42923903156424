import React from 'react';

import TheoryContentPageWrapper from '../../../components/TheoryContentPageWrapper';
import ParagraphWithTitle from '../../../components/ParagraphWithTitle';
import FullRowImage from '../../../components/FullRowImage';
import InlineFormula from '../../../components/InlineFormula';
import TeaserVector from '../../../components/TeaserVector';
import CaptionedImage from '../../../components/CaptionedImage';
import Cite from '../../../components/Cite';

// Styles 
import style from '../../../styles/ExhibitionContent.module.css';

function ART() {
    return <TheoryContentPageWrapper>
        <TeaserVector g={true} c={true} h={false}/>
        <div className={style.singleColumnParagraph}>
            <h1 className={`${style.title} ${style.mainTitleTopOffset}`}>General Relativity</h1>
            <section>
                In order to unite Newtonian gravity with special relativity, both natural
                constants <InlineFormula math="G"/> and <InlineFormula math="c^{-1}"/> must be
                taken into account. This is exactly what Einstein's General Theory of
                Relativity (1915) does. It transforms the static four-dimensional
                Minkowskian space-time into a dynamic and curved object, whose shape is
                uniquely fixed by Einstein's field equations. In curved space-time, objects
                always move on the shortest possible path as determined by an indefinite
                metric. A historically important example is Mercury's orbit around the Sun.
            </section>
            <section>
                According to Einstein, the Sun curves space-time in a way that results in a
                nearly elliptic orbit, up to a small perihelion shift. Contrary to Newton,
                the reason for Mercury's curved path is not some mysterious gravitational
                force emanating from the Sun, but simple economy. An analogy is the
                trajectory of an airplane from Prague to Seoul: it is curved, but by no
                means due to a hypothetical force at the North Pole.
            </section>
        </div>
        <FullRowImage src="/intro-images/general-relativity.svg" />
        <ParagraphWithTitle title="Action Principle" titlePos="right">
            <section>
                Why was Albert Einstein so sure that he had to develop a new theory of
                gravity?  In fact, Max Planck was not aware of the cube's missing fourth
                classical corner, corresponding to <InlineFormula math="c^{-1}"/> and
                &nbsp;<InlineFormula math="G"/>. Accordingly, Planck strongly recommended him to
                not waste his time, but to rather focus on the development of quantum
                mechanics. However, Einstein was acutely aware that Newtonian gravitational
                theory and special relativity are fundamentally incompatible. For example,
                in the former, a change in the distance between two masses results in an
                instantaneously felt change in the gravitational force acting between them.
                In contradistinction, in special relativity signal transmission at
                velocities greater than <InlineFormula math="c"/> is impossible. This fundamental 
                feature, that Newton's law of gravity depends merely on location but not on time, 
                had to be eliminated by general relativity.
            </section>
            <section>
                In hindsight, the most elegant way to derive the latter theory involves a
                suitable action principle. Let us first recall the much simpler derivation
                of Newton's laws based on such a principle. One considers a body's movement
                over a period of time and determines the actual path traveled according to
                a suitable criterion. 
            </section>
            <Cite>
                The path of actual reproduction is that to which the smallest amount of
                action belongs! (Maupertuis 1744)
            </Cite>
        </ParagraphWithTitle>
        <CaptionedImage src="/theory-illustrations/general-relativity.jpg" caption="LIGO measurement of gravitational waves at Hanford and Livingston compared with the theoretically predicted values." />
        <ParagraphWithTitle titlePos="left" title="Einstein's Field Equations">
            <section>
                This action principle then implies the famous Euler-Lagrange equations:
            </section>
            <Cite center>
                <InlineFormula math={"\\frac{d}{dt}\\frac{\\partial L}{\\partial \\dot{q}_i}-\\frac{\\partial L}{\\partial q_i} = 0"}/><br/>(Equation of Euler-Lagrange)
            </Cite>
            <section>
                Here, <InlineFormula math="q_{i}(t)"/> is the coordinate of the i-th
                particle at time <InlineFormula math="t"/>, and <InlineFormula math="L"/> the
                Lagrangian, given by the difference between the particles' kinetic and
                potential energies. The Euler-Lagrange equations precisely reproduce
                Newton's equations of motion.  Incidentally, note that this derivation is
                based on an erroneous fundamental asymmetry of space and time: the
                different states of a particle are only integrated over time.
            </section>
            <section>
                Generalizing this idea to Einsteinian gravity, while repairing the
                space-time asymmetry, one is looking for a suitable action <InlineFormula math="S"/> 
                that allows for its minimisation under arbitrary variations
                of the metric:
            </section>
            <Cite center>
                <InlineFormula math="\frac{\delta S}{\delta g_{\mu\nu}} = 0"/><br/>(Variation
                of the Einstein-Hilbert action)
            </Cite>
            <section>                                                                     
                The actual metric is then once again calculated by using an analogue of the Euler-Lagrange 
                equations. The correct choice is now called the Einstein-Hilbert action and leads to 
                Einstein's field equations:       
            </section>                                                                    
            <Cite center>
                <InlineFormula math="R_{\mu\nu}-\frac{1}{2}g_{\mu\nu}R = \frac{8\pi G}{c^4}T_{\mu\nu}"/><br/>(Einsteins's equation)
            </Cite>
            <section>                                                                     
                They describe in a mathematically rigorous fashion that matter (<InlineFormula 
                math="T_{μν}"/>) bends (<InlineFormula math="R_{μν}"/> and <InlineFormula    
                math="R"/>) space-time (<InlineFormula math="g_{μν}"/>). Objects move
                in this metric on curved tracks without experiencing any force: gravity   
                simply corresponds to the curvature of space-time.                        
            </section>                                                                    
            <Cite> 
                Therefore, in the following we want to assume the complete physical
                equivalence of gravitational field and corresponding acceleration of the
                reference system. (Einstein 1907)
            </Cite>
            <section>
                All objects in the cosmos then simply <i>travel</i> on the shortest and
                fastest possible connection in a curved space-time. On their way they bend
                with their mass and energy the space-time structure in their immediate
                vicinity.  Stated differently: Everything moves on a curved
                four-dimensional Lorentzian manifold, whose local structure is, in turn,
                modified by the mover.
            </section>
            <section>
                General relativity thus solves the contradictions between Newton's theory
                of gravity and special relativity connecting constants <InlineFormula math="G"/> 
                &nbsp;and <InlineFormula math="c^{-1}"/>.This yields the 
                theory <InlineFormula math="(G,c^{-1},0)"/> of the cube.
            </section>
        </ParagraphWithTitle>
        <ParagraphWithTitle titlePos="right" title="Gravitational Waves">
            <section>
                In 1916, Einstein also predicted in his General Theory of Relativity that
                gravitational waves must exist in analogy to electromagnetic waves. But
                their experimental proof-of-existence appeared to him out of reach forever.
                Notwithstanding, pretty much exactly 100 years later this was nevertheless
                achieved for the first time with the Laser Interferometer Gravitational
                Wave Observatory (LIGO). In 2016, it detected the gravitational waves
                produced by the collapse of a binary system of black holes.
            </section>
        </ParagraphWithTitle>
    </TheoryContentPageWrapper>;
}
export default ART;
