import React from 'react';

import TheoryContentPageWrapper from '../../../components/TheoryContentPageWrapper';
import ParagraphWithTitle from '../../../components/ParagraphWithTitle';
import FullRowImage from '../../../components/FullRowImage';
import InlineFormula from '../../../components/InlineFormula';
import TeaserVector from '../../../components/TeaserVector';
import CaptionedImage from '../../../components/CaptionedImage';
import Cite from '../../../components/Cite';

// Styles 
import style from '../../../styles/ExhibitionContent.module.css';

function QFT() {
    return <TheoryContentPageWrapper>
        <TeaserVector g={false} c={true} h={true}/>
        <div className={style.singleColumnParagraph}>
            <h1 className={`${style.title} ${style.mainTitleTopOffset}`}>Quantenfeldtheorie</h1>
            <section>
                Die Quantenmechanik wurde in den 1920er Jahren zunächst im nichtrelativistischen 
                Limes <InlineFormula math="c^{-1}=0"/> formuliert, obwohl die Richtigkeit von 
                Einsteins Spezieller Relativitätstheorie bereits seit Jahren, von einigen 
                antisemitischen Idioten abgesehen, allgemein anerkannt war. Dirac gelang 
                es 1928, eine eigenartige relativistische Wellengleichung aufzustellen, die 
                die Schrödinger-Gleichung nichttrivial verallgemeinerte. Sie ist heute 
                direkt vor Isaac Newtons Grab in der Westminster Abbey im Boden eingraviert. 
                Insbesondere erklärte die Gleichung den Spin des Elektrons und sagte dessen 
                Antiteilchen voraus. Ein Triumph war die experimentelle Bestätigung dieses 
                Positrons durch Carl Anderson 1932.
            </section>
            <section>
                Allerdings führte die Interpretation der Dirac-Gleichung als Wellengleichung 
                zu Widersprüchen. Zudem ergaben sich Probleme bei der Quantisierung der 
                Elektrodynamik, die ebenfalls eine Theorie ist, bei der <InlineFormula math="c^{-1}"/> angeschaltet 
                ist: <InlineFormula math="c^{-1}=1"/>. Als Ausweg wurde die Quantenfeldtheorie 
                in den späten 1940er Jahren als eine relativistisch konsistente Vielteilchentheorie 
                entwickelt. Diese führte etwa 20 Jahre später zum Standardmodell der Elementarteilchen, 
                das alle Naturkräfte mit Ausnahme der Gravitation präzise beschreibt.
            </section>
        </div>
        <FullRowImage src="/intro-images/quantum-field-theory.svg" />
        <ParagraphWithTitle titlePos="right" title="Klein-Gordon-Gleichung">
            <section>
                Die direkte Verallgemeinerung der quantenmechanischen, Galilei-invarianten Schrödinger-Gleichung 
                für die Wahrscheinlichkeitsamplitude <InlineFormula math="\Psi"/> eines freien 
                Teilchens der Masse <InlineFormula math="m"/> führt zur Lorentz-invarianten Klein-Gordon Gleichung, 
                geschrieben mithilfe der nützlichen Notation <InlineFormula math="\hbar=\frac{h}{2\pi}"/>:
            </section>
            <Cite center>
                <InlineFormula math="(\frac{1}{c^2}\frac{\partial^2}{\partial t^2}-\frac{\partial ^2}{\partial x^2}-\frac{\partial^2}{\partial y^2}-\frac{\partial^2}{\partial z^2}+\frac{m^2 c^2}{\hbar^2})\Psi=0"/>
                <br/>Klein-Gordon-Gleichung
            </Cite>
            <section>
                Die relativistischen Symmetrien zwischen Raum und Zeit sind wiederhergestellt, denn sowohl 
                die zeitliche als auch alle partiellen Ableitungen nach den Raumkoordinaten <InlineFormula math="x, y, z"/> sind 
                nun zweiter Ordnung. Zudem hat sich im Vergleich zur Schrödinger-Gleichung die 
                Lichtgeschwindigkeit <InlineFormula math="c"/> zum Planckschen Wirkungsquantum <InlineFormula math="h"/> hinzugesellt.
            </section>
        </ParagraphWithTitle>
        <CaptionedImage src="/theory-illustrations/quantum-field-theory.jpg" caption="Die Bausteine des Standardmodells der Elementarteilchenphysik." />
        <ParagraphWithTitle titlePos="left" title="Diracs Gleichung">
            <section>
                Interessanterweise stellt sich jedoch heraus, dass die Interpretation von <InlineFormula math="\Psi"/> als 
                Wahrscheinlichkeitsamplitude im relativistischen Fall nicht mehr konsistent ist. Die Quantenfeldtheorie 
                löst das Problem konzeptionell auf abstrakt mathematische Weise: <InlineFormula math="\Psi"/> wird 
                ein operatorwertiges Quantenfeld, das auf einen Hilbert-Raum wirkt. <InlineFormula math="\Psi"/> ist nun 
                in der Lage, Teilchen zu erzeugen und zu vernichten. Damit wird die Quantenfeldtheorie per 
                Konstruktion ein Vielteilchenmodell. Die bisher nachgewiesenen freien Materieteilchen allerdings 
                werden auch in der Operatorinterpretation noch nicht vollständig durch obige Gleichung beschrieben. 
                Dirac fand 1928 seine hierfür weitaus besser geeignete Gleichung:
            </section>
            <Cite center>
                <InlineFormula math="\Big(i\hbar(\gamma^0\frac1c\frac{\partial}{\partial t}+\gamma^1\frac{\partial}{\partial x}+\gamma^2\frac{\partial}{\partial y}+\gamma^3\frac{\partial}{\partial z})-mc\Big)\Psi=0" />
                <br/>Dirac's Gleichung
            </Cite>
            <section>
                <InlineFormula math="\Psi"/> hat nun vier Komponenten, es ist ein sogenannter Spinor. 
                Hier bedeutet <InlineFormula math="i"/> die imaginäre Einheit. Die Gleichung ist erster 
                Ordnung in allen partiellen Ableitungen und verlangt die Einführung von <InlineFormula math="4 \times 4"/> Dirac-Matrizen <InlineFormula math="\gamma^0, \gamma^1, \gamma^2, \gamma^3"/>.
            </section>
            <section>
                Zwei mal zwei ist vier: Die erste Zwei entspricht den beiden möglichen Polarisationen 
                des Spins <InlineFormula math="\frac12"/> der Materieteilchen. Letzterer ist eine 
                (leider unanschauliche) Rotation des Punktteilchens um sich selber. Die zweite Zwei 
                bedeutet, dass <InlineFormula math="\Psi"/> nicht nur Teilchen, sondern auch Antiteilchen 
                erzeugen und vernichten kann. Damit beschreibt <InlineFormula math="\Psi"/> also z.B. nicht 
                nur das Elektronenfeld, sondern zugleich auch das dazugehörige Positronenfeld. 
                Für alle anderen Leptonen, nämlich Myonen, Tauonen, die dazugehörigen drei Generationen 
                von Neutrinos sowie die sechs bekannten Quarks, benötigt man jeweils zusätzliche Dirac-Felder. 
                Die Quarks treten allerdings nie als freie Teilchen auf, sondern sind fest eingeschlossen 
                in Nicht-Elementarteilchen wie etwa den Protonen und Neutronen der Atomkerne.
            </section>
        </ParagraphWithTitle>
        <ParagraphWithTitle titlePos="right" title="Standard-Modell">
            <section>
                Ähnliche Operatorgleichungen beschreiben die elektromagnetischen Felder &ndash; genauer, die 
                sogenannten abelschen Eichfelder &ndash; der Quantenelektrodynamik. Sie ist die quantitativ 
                am genauesten überprüfte Theorie, die jemals aufgestellt wurde. Die Quanten dieser 
                Felder sind nichts anderes als Einsteins Photonen, die ebenfalls einen Spin besitzen, 
                und zwar Spin 1. Und auch die Kernkräfte beschreibt man sehr erfolgreich mit Quantenfeldtheorien 
                von weiteren Spin 1 Teilchen, den acht Gluonen der starken und den W<InlineFormula math="^+"/>, W<InlineFormula math="^-"/> und 
                Z-Bosonen der schwachen Kernkräfte. Um diese Teilchen und die ihnen entsprechenden Naturkräfte 
                zu beschreiben, muss man die abelschen Eichfelder durch mathematisch deutlich komplexere 
                nicht-abelsche Felder ersetzen. Insgesamt ergibt sich daraus das aktuelle Standardmodell 
                der Elementarteilchenphysik.
            </section>
            <section>
                Besonders zu erwähnen ist hier noch das bereits in den 1960er Jahren vorhergesagte 
                Higgs-Feld, das endlich 2012 mit dem Large Hadron Collider (LHC) am CERN in Genf entdeckt 
                wurde. Es verleiht den meisten Elementarteilchen eine Masse, siehe das <InlineFormula math="m"/> in 
                der obigen Dirac-Gleichung im Fall der Spin <InlineFormula math="\frac12"/> Teilchen.
            </section>
        </ParagraphWithTitle>
        <ParagraphWithTitle titlePos="left" title="Das Graviton">
            <section>
                Bisher nicht gelungen ist es, auch die Gravitationstheorie in das Standardmodell zu 
                integrieren. Das dazugehörige hypothetische Teilchen wurde allerdings schon getauft, 
                es heißt Graviton und müsste den Spin 2 besitzen. Seine Existenz würde von 
                der Ecke <InlineFormula math="(0, c^{-1}, h)"/> zur Ecke <InlineFormula math="(G, c^{-1}, h)"/> des 
                Würfels führen, also zur Theorie von Allem.
            </section>
        </ParagraphWithTitle>
    </TheoryContentPageWrapper>;
}

export default QFT;