import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import WorldsContentController from '../../../components/WorldsContentController';
import InlineFormula from '../../../components/InlineFormula';

// Styles 
import style from '../../../styles/ExhibitionContent.module.css';


function G0() {
    const {search} = useLocation();

    return <WorldsContentController teaserUrl={"/teaser-labels/G0.png"} comic={"/comics/G0.svg"} title={"Die Welt ohne Gravitation"}>
        <section className={style.subtitle}>
            Die Schwerelose Welt umfasst vier Theorien: die <NavLink to={"/theories/newtonian-mechanics"+search}>Newtonsche Mechanik</NavLink>,&nbsp;
            die <NavLink to={"/theories/quantum-mechanics"+search}>Quantenmechanik</NavLink>,&nbsp;
            die <NavLink to={"/theories/special-relativity"+search}>Spezielle Relativitätstheorie</NavLink>,&nbsp;
            und die <NavLink to={"/theories/quantum-field-theory"+search}>Quantenfeldtheorie</NavLink>. 
            <br/>Ihre Gegenwelt ist die <NavLink to={"/worlds/g-1"+search}>Welt der Schwerkraft</NavLink>.
        </section>
        <section>
            Können wir uns eine Welt ohne Gravitation vorstellen? Die Gravitation ist die schwächste der vier 
            Naturkräfte. Im Gegensatz zur elektromagnetischen Wechselwirkung und den schwachen und starken Kernkräften 
            lässt sie sich nicht abschirmen und ist somit die offensichtlichste Naturkraft. Allerdings gibt es 
            Zustände, in denen man scheinbar gravitationslose Welten erzeugen kann: zum Beispiel beim Parabelflug 
            eines Flugzeugs.
        </section>
        <section>
            Warum oder wie aber lässt sich Physik erfolgreich betreiben, ohne die Gravitation zu berücksichtigen? 
            Während eine makroskopische Welt ohne Gravitation kaum vorstellbar ist, kann in der mikroskopischen 
            Welt der Teilchenphysik darauf verzichtet werden. So werden in der Quantenmechanik die strengen 
            Bahnen der Newtonsche Mechanik in stochastische Prozesse aufgelöst, ohne dass dabei die Gravitation 
            ins Spiel kommt. Die Spezielle Relativitätstheorie verallgemeinert die Raum-Zeitstruktur Galileis 
            ebenfalls ohne Berücksichtigung der Gravitation. Folglich spielt die Schwerkraft auch in der 
            Quantenfeldtheorie als der Vereinheitlichung von Spezieller Relativitätstheorie und Quantenmechanik 
            keine Rolle.
        </section>
        <section>
            Raum und Zeit sind in der <InlineFormula math="G=0" />-Welt die Bühne, auf der sämtliche Ereignisse 
            stattfinden. Körper bewegen sich klassisch oder quantenmechanisch, Kräfte wirken zwischen diesen Körpern, 
            aber Raum und Zeit bleiben hiervon vollkommen unberührt. Theorien dieser <InlineFormula math="G=0" />-Welt 
            können Aussagen über die lokale Beschaffenheit dieser raumzeitlichen Bühne machen. So kann beispielsweise 
            in der Quantenfeldtheorie das Verhalten von bewegten Körpern bei Raumspiegelung oder Zeitumkehr oder in der 
            Speziellen Relativitätstheorie die Abhängigkeit von Zeit und Raum vom Beobachter bestimmt werden. 
            Allerdings verändern Theorien der <InlineFormula math="G=0" />-Welt mit ihren Aussagen die Bühne nicht, sie 
            bleibt davon unabhängig.
        </section>
    </WorldsContentController>;
}

export default G0;