import style from '../styles/MediaStation.module.css';

function Title(props) {
    const {page, language} = props;

    let titles = [
        "01 The Problem",
        "02 The Metamodel",
        "03 The Visualization",
        "04 The History of the Cube"
    ];

    if (language === "de") {
        titles = [
            "01 Das Problem",
            "02 Das Metamodell",
            "03 Die Visualisierung",
            "04 Die Geschichte des Würfels"
        ];
    }
    const title = titles[page];
    
    return <h1 className={style.title}>{title}</h1>;
}

export default Title;