import React, {useState, useRef, useEffect} from 'react';

import Hammer from 'hammerjs';

import style from '../styles/Label.module.css';

function ColumnVectorLabel(props) {
    const {visible, g, h, c, pos, width, offset, onSelect} = props;

    const [fade, setFade] = useState((visible) ? style.fadeIn : style.fadeOut);

    let tripel = "";
    tripel += (g) ? "1" : "0";
    tripel += (h) ? "1" : "0";
    tripel += (c) ? "1" : "0"; 

    const wrapper = useRef(null);
    
    useEffect(() => {
        const hammer = new Hammer(wrapper.current);
        hammer.on('tap', handleClick);

        return () => {
            hammer.off('tap');
        }
    }, [])
    
    useEffect(() => {
        setFade((visible) ? style.fadeIn : style.fadeOut)
    }, [visible]);
   
    function handleClick(ev) {
        if (onSelect) {
            onSelect(tripel);
        }

        if (ev.stopPropagation) {
            ev.stopPropagation();
        }
    }

    let labelStyle = [style.label, style.columnVector];

    let top = pos.y+"px";
    let left = pos.x+"px";
    if (offset) {
        labelStyle.push(style[offset]);
        top = "calc("+pos.y+"px + 10%)";
    }

    if (onSelect) {
        labelStyle.push(style.clickable);
    }
    
    labelStyle.push(fade);
    
    let gLabel = (g) ? "G" : "0";
    let hLabel = (h) ? "h" : "0";
    let cLabel = (c) ? "c" : "0";

    return <div className={labelStyle.join(" ")} style={{top: top, left: left, width: width+"px"}} onClick={handleClick} ref={wrapper}>
        <img src={"teaser-vectors/"+gLabel+cLabel+hLabel+".svg"} alt=""></img>
    </div>;
};

export default ColumnVectorLabel;