import React from 'react';

// Styles 
import style from '../styles/ExhibitionContent.module.css';

function Teaser(props) {
    const {imgUrl} = props;
    
    return <div className={style.teaser}>
        <img className={style.teaserImg} alt="large label of the worlds name" src={imgUrl}></img>
    </div>;
}
export default Teaser;