import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import WorldsContentController from '../../../components/WorldsContentController';

// Styles 
import style from '../../../styles/ExhibitionContent.module.css';

function H0() {
    const {search} = useLocation();

    return <WorldsContentController teaserUrl={"/teaser-labels/h0.png"} comic={"/comics/H0.svg"} title={"Classical World"}>
        <section className={style.subtitle}>
            It comprises the following four theories: <NavLink to={"/theories/newtonian-mechanics"+search}>Newtonian Mechanics</NavLink>,&nbsp;
            <NavLink to={"/theories/newtonian-gravitation"+search}>Newtonian Gravitation</NavLink>,&nbsp;<NavLink to={"/theories/special-relativity"+search}>Special Relativity</NavLink>,&nbsp;
            and <NavLink to={"/theories/general-relativity"+search}>General Theory of Relativity</NavLink>. 
            <br/>
            Its antipode is the <NavLink to={"/worlds/h-1"+search}>Quantum World</NavLink>.
        </section>
        <section>
            The Classical World, while being a mere illusion, is the most accessible to us. It encompasses deterministic 
            processes that are closest to our human senses and which can often be experienced directly. The idealised 
            behaviour of a stretching or compressing spiral spring according to Hooke's law or the movement of low and 
            high pressure areas due to the Coriolis force are everyday phenomena that are covered by Newton's 
            mechanics. The trajectory of a thrown ball or the motion of the celestial bodies surrounding us can be 
            calculated to a good accuracy by Newton's theory of gravity. The accuracy is so high, that no deviation 
            from Newton's laws of gravity may be determined without sophisticated efforts. Both theories operate 
            in Galilean space-time, in which speeds add up linearly and time is an independent absolute parameter 
            that universally and consistently counts the elapsing of seconds, minutes and hours.
        </section>
        <section>
            Special and General Relativity also belong to the Classical World of the cube. Although the underlying 
            space-time structure changes dramatically, the deterministic character of clearly calculable 
            trajectories is preserved. Effects of Special Relativity such as time dilation and length contraction 
            are very small on human time und length scales, but are firmly anchored in modern technology. An 
            important example is GPS navigation. In 1919, simple telescopes and photographic techniques were 
            sufficient to determine the deflection of starlight in the sun's gravitational field. This demonstrated 
            the validity of General Relativity, and generated hitherto unknown levels of media coverage and 
            public interest.
        </section>
        <section>
            The Classical World thus consists of those four of the cube's eight theories where quantum effects 
            are absent, seemingly rendering all processes deterministic. The trajectories of objects are in 
            principle calculable to arbitrary precision. For example, probes can be landed on Mars with pinpoint 
            accuracy, and the relative motion of galaxies may be determined despite their great distance 
            from the earth.
        </section>
    </WorldsContentController>;
}

export default H0;