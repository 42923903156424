import React, {useState, useEffect} from 'react';

import baseStyle from '../styles/Exhibition.module.css'

// TODO: Check if this can be simplified
function BackgroundGradient(props) {
    const {targetTop} = props;
    
    const [currTop, setCurrentTop] = useState(0);

    let inAnimation = false;
    let animationStartTime = 0;
    let animationStartTop = 0;
    let animationFrameID = null;
    let intervalID = null;

    useEffect(() => {
        if (targetTop !== currTop && !inAnimation) {
            inAnimation = true;
            animationStartTime = performance.now();
            animationStartTop = currTop;
            animationFrameID = window.requestAnimationFrame(animateGradient);

            if (intervalID !== null) {
                clearInterval(intervalID);
            }
        }

        return function cleanUp() {
            window.cancelAnimationFrame(animationFrameID);
        }
    }, [targetTop, currTop]);

    function animateGradient() {
        let p = (performance.now() - animationStartTime) / 125;
        if (p >= 1) {
            intervalID = null;
            inAnimation = false;
            setCurrentTop(targetTop);
        } else {
            setCurrentTop(p * (targetTop - animationStartTop) + animationStartTop)
            animationFrameID = window.requestAnimationFrame(animateGradient);
        }
    }

    return <div className={baseStyle.background} style={{ top: currTop+"vh"}}></div>;
}
export default BackgroundGradient;