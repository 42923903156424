import React from 'react';

// Styles 
import style from '../styles/ExhibitionContent.module.css';

function CaptionedImage(props) {
    const {src, alt, caption} = props;
    return <figure className={`${style.fullRowElement} ${style.captionedImageWrapper} ${style.center}`}>
        <img className={style.fullRowImage} src={src} alt={alt}></img>
        <figcaption className={style.caption}>{caption}</figcaption>
    </figure>;
}
export default CaptionedImage;