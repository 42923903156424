import React from 'react';

import TheoryContentPageWrapper from '../../../components/TheoryContentPageWrapper';
import ParagraphWithTitle from '../../../components/ParagraphWithTitle';
import FullRowImage from '../../../components/FullRowImage';
import InlineFormula from '../../../components/InlineFormula';
import TeaserVector from '../../../components/TeaserVector';
import CaptionedImage from '../../../components/CaptionedImage';
import Cite from '../../../components/Cite';

// Styles 
import style from '../../../styles/ExhibitionContent.module.css';

function ART() {
    return <TheoryContentPageWrapper>
        <TeaserVector g={true} c={true} h={false}/>
        <div className={style.singleColumnParagraph}>
            <h1 className={`${style.title} ${style.mainTitleTopOffset}`}>Allgemeine Relativitätstheorie</h1>
            <section>
                Um die Newtonsche Gravitation mit der Speziellen Relativitätstheorie vereinen zu können, 
                müssen beide Naturkonstanten <InlineFormula math="G"/> und <InlineFormula math="c^{-1}"/> berücksichtigt 
                werden. Genau dies leistet Einsteins Allgemeine Relativitätstheorie (1915), die die statische 
                vierdimensionale Minkowskische Raum-Zeit in ein erst durch Feldgleichungen zu bestimmendes, 
                dynamisches und gekrümmtes Objekt verwandelt. In der gekrümmten Raum-Zeit bewegen sich Körper 
                immer auf der kürzest möglichen Strecke gemäß einer indefiniten Metrik. Ein historisch relevantes 
                Beispiel ist Merkurs Bahn um die Sonne.
            </section>
            <section>
                Dass diese als gekrümmt erscheint und einer leichten Periheldrehung unterworfen ist, liegt nach Einstein 
                und entgegen Newton nicht etwa an einer mysteriösen von der Sonne ausgehenden 'Gravitationskraft', sondern
                vielmehr daran, dass Merkur äußerst ökonomisch in der von der Sonne gekrümmten Raum-Zeit fliegt. 
                Eine Analogie ist die Bahn eines Flugzeugs von Prag nach Seoul: Diese ist gekrümmt, aber mitnichten 
                aufgrund einer hypothetischen Kraft am Nordpol.
            </section>
        </div>
        <FullRowImage src="/intro-images/general-relativity.svg" />
        <ParagraphWithTitle title="Wirkungsprinzip" titlePos="right">
            <section>
                Warum war sich Einstein so sicher, dass er eine neue Gravitationstheorie entwickeln musste? 
                Tatsächlich war sich Max Planck ja nicht bewusst, dass für den Würfel eine vierte klassische 
                Ecke mit den Koordinaten <InlineFormula math="c^{-1}"/> und <InlineFormula math="G"/> fehlte. 
                Planck empfahl Einstein daher dringend, seine Zeit nicht damit zu vergeuden, sondern sich lieber 
                auf die Entwicklung der Quantenmechanik zu konzentrieren. Einstein war sich allerdings sehr
                bewusst, dass Newtons Gravitationstheorie und die Spezielle Relativitätstheorie grundsätzlich 
                unverträglich sind. So führt beispielsweise eine Veränderung des Abstands zweier Massen in 
                Newtons Gravitationstheorie zu einer instantan gefühlten Veränderung der Schwerkraft zwischen 
                den beiden. Im Gegensatz dazu erlaubt die Spezielle Relativitätstheorie keine Signalübertragung 
                mit einer größeren Geschwindigkeit als <InlineFormula math="c"/>. Das grundlegende Problem, dass Newtons 
                Gravitationsgesetz lediglich vom Ort, nicht aber von der Zeit abhängt, musste mit der Allgemeinen 
                Relativitätstheorie eliminiert werden.
            </section>
            <section>
                Der aus heutiger Sicht eleganteste Weg, letztere herzuleiten, basiert auf einem geeigneten 
                Wirkungsprinzip. Dies gilt sogar für den deutlich einfacheren Fall der Newtonschen Gesetze. 
                Hierzu betrachtet man alle möglichen Bewegungen eines Körpers in einem vorgegebenen Zeitintervall 
                und bestimmt die tatsächliche Bahn durch Minimierung einer Wirkung: 
            </section>
            <Cite>
                Der Pfad der tatsächlichen Fortpflanzung ist jener, zu dem die kleinste 
                Wirkungsmenge gehört! (Maupertuis 1744)
            </Cite>
        </ParagraphWithTitle>
        <CaptionedImage src="/theory-illustrations/general-relativity.jpg" caption="LIGO-Messung der Gravitationswellen in Hanford und Livingston im Vergleich mit den theoretisch vorhergesagten Werten." />
        <ParagraphWithTitle titlePos="left" title="Einsteins Feldgleichungen">
            <section>
                Aus dem Wirkungsprinzip folgen die Euler-Langrange-Gleichungen, 
                wobei <InlineFormula math="q_{i}(t)"/> die Koordinate des <InlineFormula math="i"/>-ten Teilchens 
                zum Zeitpunkt <InlineFormula math="t"/> bedeutet:
            </section>
            <Cite center>
                <InlineFormula math={"\\frac{d}{dt}\\frac{\\partial L}{\\partial \\dot{q}_i}-\\frac{\\partial L}{\\partial q_i} = 0"}/><br/>(Euler-Lagrange-Gleichung)
            </Cite>
            <section>
                Die Lagrange-Funktion <InlineFormula math="L"/> ist hier die Differenz aus der kinetischen 
                und potentiellen Energie eines Teilchens. Die Euler-Lagrange-Gleichungen liefern dann 
                sofort die Newtonschen Bewegungsgleichungen.
            </section>
            <section>
                Damit aber beruht diese Herleitung auf einer so nicht korrekten Asymmetrie von Raum und Zeit: 
                Die unterschiedlichen Zustände eines Teilchens werden lediglich über die Zeit integriert. 
                Um diese Idee auf die Einsteinsche Gravitationstheorie anwenden zu können, muss obige 
                Raum-Zeitasymmetrie beseitigt werden. Man sucht nach einer geeigneten Wirkung <InlineFormula math="S"/>, 
                die die Minimierung unter beliebigen Variationen der Metrik erlaubt:
            </section>
            <Cite center>
                <InlineFormula math="\frac{\delta S}{\delta g_{\mu\nu}} = 0"/><br/>(Variation
                der Einstein-Hilbert-Wirkung)
            </Cite>
            <section>                                                                     
                Die tatächliche Metrik errechnet sich dann wieder aus einem Analogon der Euler-Lagrange-Gleichungen. 
                Die richtige Wahl für <InlineFormula math="S"/> wird heute als Einstein-Hilbert-Wirkung bezeichnet 
                und führt zu Einsteins Feldgleichungen:      
            </section>                                                                    
            <Cite center>
                <InlineFormula math="R_{\mu\nu}-\frac{1}{2}g_{\mu\nu}R = \frac{8\pi G}{c^4}T_{\mu\nu}"/><br/>(Einsteinss Gleichung)
            </Cite>
            <section>                                                                     
                Ihre mathematisch exakte Aussage ist: Materie (<InlineFormula math="T_{μν}"/>) krümmt
                (<InlineFormula math="R_{μν}"/> und <InlineFormula math="R"/>) die Raum-Zeit (<InlineFormula math="g_{μν}"/>). 
                Objekte bewegen sich in dieser Metrik auf gekrümmten Bahnen ohne jede Krafteinwirkung: 
                Die Gravitation entspricht schlichtweg dem Effekt der Raum-Zeitkrümmung.                        
            </section>                                                                    
            <Cite> 
                Wir wollen daher im folgenden die völlige physikalische Gleichwertigkeit von Gravitationsfeld 
                und entsprechender Beschleunigung des Bezugssystems annehmen. (Einstein 1907)
            </Cite>
            <section>
                Die Objekte, die sich im Kosmos bewegen, folgen in ihren Bahnen also nur einem Ziel: Der 
                kürzesten und schnellsten Verbindung in einer gekrümmten Raum-Zeit. Auf ihrem Weg krümmen 
                sie mit ihrer Masse und Energie wiederum die Raum-Zeit in ihrer unmittelbaren Umgebung. 
                Präzise formuliert: Alles bewegt sich auf einer vierdimensionalen Lorentzschen Mannigfaltigkeit, 
                deren lokale Struktur wiederum durch diese Bewegung verändert wird.
            </section>
            <section>
                Die Allgemeine Relativitätstheorie löst die Widersprüche zwischen der Newtonschen 
                Gravitationstheorie und der Speziellen Relativitätstheorie, indem beide 
                Konstanten <InlineFormula math="G"/> und <InlineFormula math="c^{-1}"/> miteinander 
                in Verbindung gebracht werden. Dies liefert die Theorie <InlineFormula math="(G,c^{-1},0)"/> des Würfels.
            </section>
        </ParagraphWithTitle>
        <ParagraphWithTitle titlePos="right" title="Gravitationswellen">
            <section>
                Einstein sagte 1916 mit seiner Allgemeinen Relativitätstheorie ebenfalls voraus, dass es 
                analog zu elektromagnetischen Wellen auch Gravitationswellen geben müsse. Deren experimenteller 
                Nachweis erschien ihm jedoch als praktisch unmöglich. Ziemlich genau einhundert Jahre später 
                gelang dies dennoch mithilfe des <i>Laser Interferometer Gravitational-Wave Observatory</i> (LIGO): 
                2016 wies es Gravitationswellen nach, deren Ursprung auf den Kollaps eines binären Systems von 
                Schwarzen Löchern zurückgeführt wird.
            </section>
        </ParagraphWithTitle>
    </TheoryContentPageWrapper>;
}
export default ART;
