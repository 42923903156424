import React from 'react';

import TheoryContentPageWrapper from '../../../components/TheoryContentPageWrapper';
import ParagraphWithTitle from '../../../components/ParagraphWithTitle';
import FullRowImage from '../../../components/FullRowImage';
import InlineFormula from '../../../components/InlineFormula';
import TeaserVector from '../../../components/TeaserVector';
import CaptionedImage from '../../../components/CaptionedImage';

// Styles 
import style from '../../../styles/ExhibitionContent.module.css';;

function NRQG() {
    return <TheoryContentPageWrapper>
        <TeaserVector g={true} c={false} h={true}/>
        <div className={style.singleColumnParagraph}>
            <h1 className={`${style.title} ${style.mainTitleTopOffset}`}>Nichtrelativistische Quantengravitation</h1>
            <section>
                Das Hinzuschalten der Gravitationskonstante <InlineFormula math="G"/> zur 
                nichtrelativistischen Quantenmechanik gestaltet sich zunächst denkbar unproblematisch: 
                Man löst einfach die Schrödinger-Gleichung für massive Teilchen, die sich im 
                Newtonschen Gravitationspotential bewegen. Interessanter wird es, wenn man 
                die noch zu etablierende Theorie von Allem als imaginären Ausgangspunkt nimmt 
                und die inverse Lichtgeschwindigkeit <InlineFormula math="c^{-1}"/> durch 
                mathematische Grenzwertbildung <InlineFormula math="c \rightarrow \infty"/> sorgfältig 
                abschaltet.
            </section>
            <section>
                Führt dies wirklich nicht zu messbaren Effekten und erhält man tatsächlich nur das 
                eingangs skizzierte recht langweilige Szenario? Die Antwort steht noch aus. Insofern 
                stellt die nichtrelativistische Quantengravitation gegenwärtig keine eigene Theorie 
                der Physik dar und wird auch nur relativ wenig beforscht. Es sollte aber nicht 
                unerwähnt bleiben, dass so mancher unkonventionelle Ansatz zur Quantengravitation 
                mit genau dieser Möglichkeit der Brechung der Lorentz-Symmetrie (die <InlineFormula math="c^{-1}=1"/> verlangt) 
                zwischen Raum und Zeit spielt.
            </section>
        </div>
        <FullRowImage src="/intro-images/non-relativistic-quantum-gravity.svg" />
        <ParagraphWithTitle titlePos="right" title="Ein hässliches Entlein?">
            <section>
                Die Logik des Würfels der Physik, der entsprechend der <InlineFormula math="2^3"/> Kombinationsmöglichkeiten 
                der drei fundamentalen Konstanten <InlineFormula math="G"/>, <InlineFormula math="c^{-1}"/> und <InlineFormula math="h"/> acht 
                unterschiedliche Theorien benennt, bildet sowohl Geschichte wie Systematik der Physik in vielerlei 
                Hinsicht ab, auch wenn er wesentliche Entwicklungen wie die der Thermodynamik und der statistischen 
                Physik weitgehend unbeachtet lässt. So wurde mit Beginn der modernen Physik die Bedeutung 
                dieser drei Konstanten immer klarer erkannt und fest im Theoriegebäude verankert. Die große 
                Herausforderung einer <InlineFormula math="G"/>, <InlineFormula math="c^{-1}"/> und <InlineFormula math="h"/> vereinigenden 
                Theorie ist dem Modell zugrundegelegt und verweist zugleich auf eine noch offene Zukunft.
            </section>
            <section>
                Allerdings gibt es an einer Ecke des Würfels eine Theorie, die innerhalb der 
                bisherigen Wissensgeschichte der Physik keine wesentliche Rolle gespielt hat. 
                Es handelt sich um die sogenannte nichtrelativistische Quantengravitation, 
                eine gewissermaßen nur theoretisch mögliche Theorie. Hier betrachtet man den 
                Grenzwert <InlineFormula math="c\rightarrow\infty"/> einer unendlichen Lichtgeschwindigkeit, 
                also <InlineFormula math="c^{-1}\rightarrow 0"/>, und damit die gesuchte Theorie von 
                Allem im nichtrelativistischen Limes. Die offene Frage ist, ob es hier überhaupt 
                eine interessante Theorie gibt, und, vielleicht noch wichtiger, ob es aussagekräftige 
                Experimente gibt. Leider scheint keiner der aktuellen Kandidaten für 
                eine <i>Theory of Everything</i> hier irgendwelche Anhaltspunkte zu liefern.
            </section>
        </ParagraphWithTitle>
        <CaptionedImage src="/theory-illustrations/non-relativistic-quantum-gravity.png" caption="Neutroneninterferenz im Gravitationsfeld als Funktion des Rotationswinkels Φ." />
        <ParagraphWithTitle titlePos="left" title="Experimental findings">
            <section>
                Seit den 1960er Jahren wurden Experimente mit Quantenpartikeln in klassischen 
                Gravitationsfeldern, und insbesondere dem Feld der Erde, durchgeführt. Für 
                den Limes wesentlich ist es, hier möglichst langsame Teilchen zu verwenden, 
                wofür sich Neutronen besonders anbieten. So führten etwa Colella, Overhauser 
                und Werner 1975 ein Experiment durch, dessen Resultat sowohl von der Newtonschen 
                Gravitationstheorie als auch von der Quantenmechanik abhing. Ein Strahl sehr 
                langsamer Neutronen wurde zuerst aufgespalten und anschließend interferometrisch 
                untersucht. Durch Rotation des Interferometers um einen Winkel <InlineFormula math="\varphi"/> konnten 
                sie zeigen, dass eine quantenmechanische Phasenverschiebung der langsamen Neutronen 
                durch deren Wechselwirkung mit dem Gravitationsfeld der Erde auftritt. 
                Der experimentelle Befund stimmte jedoch bestens mit der theoretischen 
                Vorhersage der Schrödinger-Gleichung mit Newtonschem Gravitationspotential 
                überein, so dass sich (leider!) keine wirklich neuen Einsichten ergaben. 
                Neuere Experimente konnten sogar die Quantisierung schwacher, durch das 
                Gravitationsfeld erzeugter Bindungszustände sehr langsamer Neutronen an die 
                Erde nachweisen.
            </section>
        </ParagraphWithTitle>
        <ParagraphWithTitle titlePos="right" title="Eine Fährte zur Theorie von Allem?">
            <section>
                Diese Art von Experiment ist möglicherweise relevant, um verschiedene Szenarien 
                für die Theory of Everything zu unterstützen oder auszuschließen. So sagen 
                gewisse Szenarien der Theorie von Allem wie etwa einige Versionen der 
                Superstringtheorie auf Längenskalen nicht nur innerhalb, sondern bereits 
                weit oberhalb der Planck-Skala Abweichungen vom Newtonschen Gravitationspotential 
                voraus. Allerdings konnten derartige Abweichungen bisher leider noch nicht 
                gemessen werden.
            </section>
            <section>
                Auch rein theoretisch inspiriert die durch den <InlineFormula math="c^{-1}\rightarrow 0"/> Limes 
                erzeugte Brechung der Lorentz-Symmetrie zwischen Raum und Zeit immer wieder die 
                Forschung. So schlug beispielsweise Petr Hořava 2009 eine neue Gravitationstheorie 
                vor, die die Allgemeine Relativitätstheorie auf kleinen Längenskalen modifiziert. 
                Dies zog immerhin bereits (Stand 2022) etwa 2.000 Folgeveröffentlichungen 
                nach sich, die den Status der sogenannten Hořava–Lifshitz Gravitationstheorie 
                allerdings weiterhin offen lassen.
            </section>
        </ParagraphWithTitle>
    </TheoryContentPageWrapper>;
}

export default NRQG;