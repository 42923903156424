const positions = [
    {x: 0, y: 1, z: 0},
    {x: 0, y:-1, z: 0},
    {x: 1, y: 0, z: 0},
    {x:-1, y: 0, z: 0},
    {x: 0, y: 0, z: 1},
    {x: 0, y: 0, z:-1}
];

const rotations = [ // in RAD
    {x: 0, y: 0, z: 0},
    {x: 0, y: 0, z: 0},
    {x: 0, y: 0, z: 1.5708},
    {x: 0, y: 0, z: 1.5708},
    {x: 1.5708, y: 0, z: 0},
    {x: 1.5708, y: 0, z: 0}
];

const conf = {
    positions: positions,
    rotations: rotations
};

export default conf;