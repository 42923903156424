import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import WorldsContentController from '../../../components/WorldsContentController';

// Styles 
import style from '../../../styles/ExhibitionContent.module.css';

function G1() {
    const {search} = useLocation();
    
    return <WorldsContentController teaserUrl={"/teaser-labels/G1.png"} comic={"/comics/G1.svg"} title={"The World with Gravitation"}>
        <section className={style.subtitle}>
            It comprises the following four theories: <NavLink to={"/theories/newtonian-gravitation"+search}>Newtonian Gravitation</NavLink>,&nbsp;
            <NavLink to={"/theories/general-relativity"+search}>General Relativity</NavLink>,&nbsp;
            <NavLink to={"/theories/non-relativistic-quantum-gravitation"+search}>Non-relativistic Quantum Gravitation</NavLink>, and the&nbsp;
            <NavLink to={"theory-of-everything"+search}>Theory of Everything</NavLink>. 
            <br/>Its antipode is the <NavLink to={"/worlds/g-0"+search}>World without Gravitation</NavLink>.
        </section>
        <section>
            Gravity is the weakest of the four basic forces of nature, yet the one most easily noticed in daily 
            life. The reason is that it has an infinite range and cannot be screened: all massive things attract 
            each other. Newton was the first to formulate a scientific theory of gravity. With an accuracy that 
            sufficed for a long time, it describes both the falling of objects on Earth as well as the orbits of 
            all celestial bodies in the universe. This gravitational theory is, however, only a first approximation
            to Einstein’s General Theory of Relativity established more than 200 years later. The latter, while 
            including subtle measurable deviations from Newton's theory, still holds some 100 years after its 
            conception, except for potential hitherto unobserved quantum effects. As such it led to a completely 
            new picture of the universe by suggesting a big bang, gravitational waves (cf. Nobel Prize 2017), 
            and black holes (cf. Nobel Prize 2020).
        </section>
        <section>
            All this changed fundamentally our understanding of space-time. Space and time are no longer just the 
            stage and background of theory, but become part of all physical events. The gravitational force as 
            such disappears, and attraction becomes motion in a locally curved space-time. Space-time is dynamic: 
            it is formed by the movement and distribution of matter and energy and is thus inseparably linked to them.
        </section>
        <section>
            However, the laws of Quantum Mechanics must surely also hold in this world. Incidentally, Non-relativistic 
            Quantum Gravity, while sitting on one of the eight corners of the cube of physics, is rather uncontentious 
            and therefore not particularly exciting. Finding a quantum mechanical generalisation of Einstein gravity, 
            on the other hand, is one of the great challenges of today: This would lead to a Theory of Everything, 
            corresponding to the most contentious corner of the cube of physics. Various attempts to build this 
            final theory, such as string theory or loop quantum gravity, have only met with limited success to-date.
        </section>
        <section>
            The Theory of Everything should provide an insight into the very nature of space-time, including the 
            principles for its emergence on the smallest scales. Accordingly, the universe is not independent of 
            the entities within it. Stage and actors cannot be separated from each other.
        </section>
    </WorldsContentController>;
}

export default G1;