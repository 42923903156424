import React from 'react';

import TheoryContentPageWrapper from '../../../components/TheoryContentPageWrapper';
import ParagraphWithTitle from '../../../components/ParagraphWithTitle';
import FullRowImage from '../../../components/FullRowImage';
import InlineFormula from '../../../components/InlineFormula';
import TeaserVector from '../../../components/TeaserVector';
import CaptionedImage from '../../../components/CaptionedImage';

// Styles 
import style from '../../../styles/ExhibitionContent.module.css';

function TheoryOfEverything() {
    return <TheoryContentPageWrapper>
        <TeaserVector g={true} c={true} h={true}/>
        <div className={style.singleColumnParagraph}>
        <h1 className={`${style.title} ${style.mainTitleTopOffset}`}>Theory of Everything</h1>
            <section>
                The so far undiscovered holy grail of modern physics. This theory would include
                all three fundamental constants <InlineFormula math="G, c^{-1}"/> and <InlineFormula math="h"/>. There are quite a few
                ambitious candidates for the throne, most notably string theory and loop
                quantum gravity. However, it has not yet been possible to test whether either
                of these theories is correct &ndash; in the end probably both are wrong, or at least
                incomplete. By combining the three fundamental natural constants <InlineFormula math="G, c"/> and
                &nbsp;<InlineFormula math="h"/> we can derive natural units for space, time and mass, the so-called Planck
                units. It turns out that quantum gravity becomes relevant in dimensions that
                correspond to these Planck units. 
            </section>
            <section>
                Unfortunately, these scales are extremely far
                away from anything that we can directly deduce experimentally. For example, the
                Planck length is about <InlineFormula math="10^{-35}"/> metres, which is an almost unthinkable scale
                &nbsp;<InlineFormula math="10^{20}"/> times smaller than the size of the proton.
            </section>
        </div>
        <FullRowImage src="/intro-images/theory-of-everything.svg"/>
        <ParagraphWithTitle titlePos="right" title="A world formula?">
            <section>
                What exactly is the theory at the corner <InlineFormula math="(G, c^{-1}, h)"/> of the cube of
                physics? It should be mathematically consistent and experimentally predictive, and thus falsifiable.
                It should also include all of the cube's theories as limiting cases by taking the
                constants <InlineFormula math="G, c^{-1}"/> and <InlineFormula math="h"/> to zero in a suitable way, individually or in
                combinations. In German, this sought-after theory is often abbreviated
                "Weltformel" ("world formula"), although it is a priori unclear whether it is
                based on a single formula, even if this theory exists. In English, on the other
                hand, it is brazenly called "Theory of Everything".
            </section>
            <section>
                The Theory of Everything seeks the unification of quantum field theory and
                general relativity. Gravity should be 'quantised' and the constant <InlineFormula math="h"/> should
                somehow be related to gravity. Likewise <InlineFormula math="G"/> should be connected to Quantum Field Theory. 
                However, if General Relativity is 'quantised' in
                a naive way in analogy to electrodynamics, e.g. by summing up all possible
                metrics, a catastrophe occurs: An infinite number of uncontrollable
                'divergences' appears, entirely destroying the predictive power of the theory.
            </section>
            <section>
                The two most prominent of all currently pursued approaches that attempt to
                circumvent this difficulty are superstring theory and loop quantum gravity.
                To date, both share the <i>feature</i> of not being connected to experiment.
                Related to this, they each come in a large variety of different versions.
                They are thus more like classes of theories than specific theories.
            </section>
        </ParagraphWithTitle>
        <CaptionedImage src="/theory-illustrations/theory-of-everything.jpg" caption="Cosmic background radiation." />
        <ParagraphWithTitle titlePos="left" title="Superstring theory">
            <section>
                Superstring theory has its roots in quantum field theory, whose point particles
                are replaced by vibrating strings. The different particles of Nature are then
                represented by the allowed oscillatory modes of these strings. Through this
                approach, the said divergences disappear, and particle and gravitational physics are
                united. A hypothetical "graviton" appears, the world becomes ten or even
                eleven-dimensional, and our world is only one of at least <InlineFormula math="10^{500}"/> many. Some of
                these worlds are at least similar to the Standard Model of particle physics, but
                unfortunately an exact match has not been discovered yet. A speculative way out is the idea of the multiverse, where many
                of these possible worlds exist in parallel universes that, somewhat depressingly, do not communicate
                with each other. In this scenario, the many yet unexplained parameters of the
                Standard Model would ultimately be constrained or fixed in that they enable
                intelligent life (anthropic principle). Not so nice.
            </section>
        </ParagraphWithTitle>
        <ParagraphWithTitle titlePos="right" title="Loop quantum gravity">
            <section>
                Loop quantum gravity has its roots in general relativity. The latter is
                directly quantised; this leads to a kind of 'quantum foam' on the Planck scale
                in the range of <InlineFormula math="10^{-35}"/> m. There are many partially contradictory
                approaches, and none of them has yet achieved a natural unification with the
                quantum field theories of the Standard Model. These have to basically be <i>added
                by hand</i>. Not nice, either.
            </section>
        </ParagraphWithTitle>
        <ParagraphWithTitle titlePos="left" title="Open questions">
            <section>
                Is there any need at all for a theory that combines quantum field theory and
                general relativity? There is a broad and affirmative consensus in physics on
                this question. For instance, given the current framework of physical theory, it is obvious that both the detailed description
                of black holes and that of the Big Bang require quantum mechanics. In addition,
                it is at least experimentally clear that something fundamental is still missing
                from the current body of theories in physics: About 96 percent of the matter
                and energy of our universe is still unknown, or, as one says, <i>dark</i>.
            </section>
        </ParagraphWithTitle>
    </TheoryContentPageWrapper>;
}

export default TheoryOfEverything;