import React from 'react';

// Styles 
import style from '../styles/ExhibitionContent.module.css';

function ParagraphWithTitle(props) {
    const { titlePos, title, wrapperWidth, children } = props;

    let flexOrder = "row";
    let border = { borderRight: "1px solid white", textAlign: "right" };
    if (wrapperWidth >= 700 && titlePos === "right") {
        flexOrder = "row-reverse";
        border = { borderLeft: "1px solid white" };
    }

    if (wrapperWidth < 700) {
        border = { borderRight: "1px solid transparent" };
    }

    return <div className={style.fullRowElement}>
        <div className={style.titledParagraphWrapper} style={{ flexDirection: flexOrder }}>
            <div className={style.titleColumn} style={border}>
                <h1 className={style.title}>{title}</h1>
            </div>
            <div className={style.singleColumnParagraph}>
                {children}
            </div>
        </div>
    </div>;
}
ParagraphWithTitle.displayName = "ParagraphWithTitle";
export default ParagraphWithTitle;