const xPositions = [
    0.0770416,    // 0
    0.158705701,  // 1
    0.33359013867,// 2
    0.6448382126, // 3
    0.8189522342, // 4
    0.96841294298 // 5
];

const yPositions = [
    0.0531587057,  // 0 
    0.16795069337, // 1
    0.34437596302, // 2
    0.65485362095, // 3
    0.830508474576,// 4
    0.911402157164 // 5
];


const vertices = [
    [xPositions[1], yPositions[4], "ng"],// p0
    [xPositions[3], yPositions[4], "tr"],// p1
    [xPositions[2], yPositions[3], "nm"],// p2
    [xPositions[4], yPositions[3], "srt"],// p3
    [xPositions[1], yPositions[2], "nrq"],// p4
    [xPositions[3], yPositions[2], "toe"],// p5
    [xPositions[2], yPositions[1], "qm"],// p6
    [xPositions[4], yPositions[1], "qft"],// p7
    [xPositions[0], yPositions[5], "g"], // \
    [xPositions[5], yPositions[3], "c"], //   --- Axis Label position 
    [xPositions[2], yPositions[0], "h"]  // /
];

const edges = [ // Not all edges, only the ones that are animated!
    [0,1],
    [1,3],
    [3,7],
    [7,5],
    [5,4],
    [5,1],
    [4,6],
    [6,7],
    [0,4]
];

const faces = [
    [2,3,7,6],
    [0,1,5,4],
    [0,2,6,4],
    [1,3,7,5],
    [0,1,3,2],
    [4,5,7,6]
];

const animationConfig = {
    stepDuration : 1000, // in ms
    xPos : xPositions,
    yPos : yPositions,
    vertices : vertices,
    faces : faces,
    edges : edges
}


export default animationConfig;