import React from 'react';

import TheoryContentPageWrapper from '../../../components/TheoryContentPageWrapper';
import ParagraphWithTitle from '../../../components/ParagraphWithTitle';
import FullRowImage from '../../../components/FullRowImage';
import InlineFormula from '../../../components/InlineFormula';
import TeaserVector from '../../../components/TeaserVector';
import CaptionedImage from '../../../components/CaptionedImage';
import Cite from '../../../components/Cite';

// Styles 
import style from '../../../styles/ExhibitionContent.module.css';

function QM() {
    return <TheoryContentPageWrapper>
        <TeaserVector g={false} c={false} h={true}/>
        <div className={style.singleColumnParagraph}>
            <h1 className={`${style.title} ${style.mainTitleTopOffset}`}>Quantum Mechanics</h1>
            <section>
                At the end of the 19th century, physical experiments penetrated deeper and
                deeper into the atomic realm, producing very strange results that contradicted
                everyday logic. The theory of quantum mechanics emerged from these experiments.
            </section>
            <section>
                In 1900, Max Planck succeeded in resolving some of the experimental
                peculiarities by introducing a new fundamental constant, which we now call
                Planck's quantum of action <InlineFormula math="h"/>. The next important development, a quarter of a
                century later, was the establishment of Schrödinger's wave mechanics and
                Heisenberg's more general matrix mechanics. This allowed to determine the
                probability for a particle to be present at a given location. Accordingly, the
                precise location may only be pinned down by a measurement. The latter, however,
                leads to a collapse of the wave function. This phenomenon is decisive for all
                measurement processes in quantum mechanics. It leads to a multitude of further
                counterintuitive effects, but also to revolutionary technical developments.
            </section>
        </div>
        <FullRowImage src="/intro-images/quantum-mechanics.svg" overflow="150vw"/>
        <ParagraphWithTitle titlePos="right" title="Planck's constant">
            <section>
                In 1900, Max Planck introduced a new, fundamental natural constant, the
                so-called action quantum <InlineFormula math="h"/>:
            </section>
            <Cite>
                We consider, however &ndash; this is the most essential point of the whole
                calculation &ndash; <InlineFormula math="E"/> to be composed of a very definite number of equal parts
                and use thereto the constant of nature <InlineFormula math="h=6.55\times10^{-27}"/>erg <InlineFormula math="\cdot"/> sec.
                (Planck 1900d: 84)
            </Cite>
            <section>
                In the meantime, it has been fixed to the exact rational number of
                &nbsp;<InlineFormula math="h=6,62607015\times10^{-27}"/>erg s. While the speed of light <InlineFormula math="c"/> as an
                experimental value was already known in the 17th century and was elevated to
                the rank of a fundamental natural constant by Einstein's theory of relativity,
                the history and systematics of the cube of physics are rooted in the year 1900:
                Planck unintentionally prepared the ground for a new theory <InlineFormula math="(0, 0, h)"/>:
                quantum mechanics. How does it conceptually relate to classical mechanics?
            </section>
        </ParagraphWithTitle>
        <CaptionedImage src="/theory-illustrations/quantum-mechanics.png" caption="Measurement results by Lummer and Pringsheim examining black body radiation." />
        <ParagraphWithTitle titlePos="left" title="Action principle">
            <section>
                Newtonian mechanics appears to describe equally well point-like masses and
                extended bodies, such as apples or planets. As was first understood by Euler
                and Lagrange, classical physics may be derived by the following assumption:
                Nature always chooses a trajectory that minimises the <i>action</i>. However, a
                justification of this action principle is needed. To this end, metaphysical
                principles were invoked in the 18th century:
            </section>
            <Cite>
                Light cannot at once travel along the shortest path and along that of the
                shortest time &ndash; why should it go by one of these paths rather than by the
                other? Further, why should it follow either of these two? [...] We cannot
                doubt that all things are regulated by a supreme Being, who, while he has
                imprinted on matter forces which show his power, has destined it to execute
                effects which mark his wisdom. (Maupertius 1744: 423 and 425) 
            </Cite>
            <section>
                Fortunately or unfortunately, on the smallest length scales, in the range of
                atomic dimensions and below, action minimisation and therefore Newtonian
                mechanics utterly fails.
            </section>
        </ParagraphWithTitle>
        <ParagraphWithTitle titlePos="right" title="Hilbert space">
            <section>
                Instead, quantum mechanics posits that a given particle takes all possible
                paths, and then assigns a probability to each of these. To be more precise, in
                quantum mechanics one considers so-called probability amplitudes, the square of
                their magnitude indicating the probability densities of the respective paths.
                If a measurement is performed, the above mentioned amplitudes are severely
                modified. Leaving aside possible new metaphysical qualms associated with this
                approach, quantum mechanics still allows to make essentially exact predictions
                on the level of macroscopic particle systems.
            </section>
            <section>
                Nevertheless, all verifiable predictions of quantum mechanics for individual
                particles are in principle statistical. Moreover, classical particles are
                strictly distinguishable. Quantum mechanical particles, on the other hand,
                cannot be distinguished from one another, while particles of different types
                may be entangled in Hilbert space. This requires different statistics than for
                classical particles and leads to apparent contradictions such as the
                Einstein-Podolsky-Rosen Paradox.
            </section>
        </ParagraphWithTitle>
        <ParagraphWithTitle titlePos="left" title="Relation to relativity">
            <section>
                However, how does quantum mechanics <InlineFormula math="(0, 0, h)"/> conceptually relate to
                relativity? The aforementioned probability amplitude, usually denoted by
                &nbsp;<InlineFormula math="\Psi"/>, for a free particle of mass <InlineFormula math="m"/> is determined by the (deterministic)
                Schrödinger equation (1926), in this case
            </section>
            <Cite center>
                <InlineFormula math=" i\hbar\frac{\partial}{\partial t}\Psi=-\frac{\hbar^2}{2m}(\frac{\partial^2}{\partial x^2}+\frac{\partial^2}{\partial y^2}+\frac{\partial^2}{\partial z^2})\Psi "/>
                <br/> Schrödinger equation
            </Cite> 
            <section>
                Note the asymmetry of space and time: The time derivative is of first order,
                the derivatives according to the space coordinates <InlineFormula math="x, y, z"/> are of second
                order. No wonder, since the equation originates from the quantum mechanical
                generalisation of Newton's mechanics, thereby flatly contradicting the special
                theory of relativity. And indeed, the speed of light <InlineFormula math="c"/> does not even occur in
                Schrödinger's equation.
            </section>
        </ParagraphWithTitle>
    </TheoryContentPageWrapper>;
}

export default QM;