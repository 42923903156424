import React from 'react';

import TheoryContentPageWrapper from '../../../components/TheoryContentPageWrapper';
import ParagraphWithTitle from '../../../components/ParagraphWithTitle';
import FullRowImage from '../../../components/FullRowImage';
import InlineFormula from '../../../components/InlineFormula';
import TeaserVector from '../../../components/TeaserVector';
import CaptionedImage from '../../../components/CaptionedImage';
import Cite from '../../../components/Cite';

// Styles 
import style from '../../../styles/ExhibitionContent.module.css';

function QM() {
    return <TheoryContentPageWrapper>
        <TeaserVector g={false} c={false} h={true}/>
        <div className={style.singleColumnParagraph}>
            <h1 className={`${style.title} ${style.mainTitleTopOffset}`}>Quantenmechanik</h1>
            <section>
                Am Ende des 19. Jahrhunderts drangen die Experimente der Physik immer tiefer in den 
                atomaren Bereich ein, mit sehr merkwürdigen, der Alltagslogik widersprechenden Ergebnissen. 
                Die Theorie der Quantenmechanik ging aus diesen Experimenten hervor.
            </section>
            <section>
                Max Planck gelang es im Jahr 1900, einige der experimentellen Merkwürdigkeiten durch 
                Einführung einer neuen fundamentalen Konstante zu lösen, die wir heute als 
                Plancksches Wirkungsquantum <InlineFormula math="h"/> bezeichnen, oder auch
                kurz Planck-Konstante. Ein zweiter wichtiger Schritt erfolgte ein Vierteljahrhundert 
                später mit der Aufstellung von Schrödingers Wellenmechanik im Speziellen und von 
                Heisenbergs Matrizenmechanik im Allgemeinen. Diese Wellen bestimmen die 
                Aufenthaltswahrscheinlichkeit von Teilchen für jeden Ort. Sie ist durch Messungen 
                überprüfbar, die dabei allerdings die Wellenfunktion notwendigerweise verändern. 
                Dieses Phänomen ist für sämtliche Messvorgänge der Quantenmechanik entscheidend und 
                führt zu einer Vielzahl kontraintuitiver Effekte, aber auch zu revolutionären 
                technischen Entwicklungen.
            </section>
        </div>
        <FullRowImage src="/intro-images/quantum-mechanics.svg" overflow="150vw"/>
        <ParagraphWithTitle titlePos="right" title="Plancksches Wirkungsquantum">
            <section>
                Max Planck schrieb folgendes über seine Entdeckung der 
                Konstanten <InlineFormula math="h"/>:
            </section>
            <Cite>
                Wir betrachten aber – und dies ist der wesentlichste Punkt der ganzen 
                Berechnung &ndash; <InlineFormula math="E"/> als zusammengesetzt aus einer ganz bestimmten Anzahl 
                endlicher gleicher Teile und bedienen uns dazu der 
                Naturconstanten <InlineFormula math="h=6.55\times10^{-27}[erg\times sec]"/>. (Planck 1900: 84)
            </Cite>
            <section>
                Im Jahre 2019 wurde sie auf den exakten rationalen Wert <InlineFormula math="h=6,62607015\times10^{-27}"/>erg s 
                festgesetzt. Während die Lichtgeschwindigkeit als experimenteller Wert bereits im 17. Jahrhundert bekannt 
                war und von Einsteins Relativitätstheorie in den Rang einer fundamentalen Naturkonstante erhoben wurde, 
                fallen mit dem Jahr 1900 Geschichte und Systematik des Würfels der Physik in eins: Durch die Einführung 
                einer bislang unbekannten Naturkonstanten begründete Planck unbeabsichtigterweise eine neue 
                Theorie <InlineFormula math="(0, 0, h)"/>, nämlich die Quantenmechanik. Wie aber verhält 
                diese sich konzeptionell zur klassischen Mechanik?
            </section>
        </ParagraphWithTitle>
        <CaptionedImage src="/theory-illustrations/quantum-mechanics.png" caption="Messergebnisse von Lummer und Pringsheim zur Überprüfung der Strahlungsgesetze schwarzer Körper." />
        <ParagraphWithTitle titlePos="left" title="Klassisches Wirkungsprinzip">
            <section>
                Die Newtonsche Mechanik scheint punktförmige Massen und ausgedehnte Körper wie Äpfel 
                oder Planeten gleichermaßen gut zu beschreiben. Wie erstmals von Euler und Lagrange 
                verstanden wurde, kann die klassische Physik durch die folgende Annahme abgeleitet 
                werden: Die Natur wählt immer einen Pfad, der die <i>Wirkung</i> minimiert. Offen 
                bleibt dabei allerdings die Frage nach den Mechanismen der Wirkungsminimierung, 
                für die im 18. Jahrhundert metaphysische Prinzipien bemüht werden mussten:
            </section>
            <Cite>
                Wenn das Licht nun nicht zugleich den kürzesten und den schnellsten Weg 
                (den der raschesten Fortpflanzung) wählen kann, warum sollte es sich 
                eher entlang des einen Pfads, als entlang des anderen fortpflanzen? [...] 
                Es ist nicht zu bezweifeln, daß alle Dinge von einem Höchsten Wesen geordnet werden, 
                welches erst Kraft auf die Materie überträgt und damit seine Macht offenbart, und 
                danach Wirkungen anordnet, die von seiner Weisheit zeugen. (Maupertius 1744: 423 und 425)
            </Cite>
            <section>
                Je nach Standpunkt mag man sich darüber freuen oder auch ärgern: Auf den kleinsten Längenskalen, 
                also im Bereich atomarer Dimensionen und darunter, versagt die Wirkungsminimierung und damit 
                die Newtonsche Mechanik gänzlich.
            </section>
        </ParagraphWithTitle>
        <ParagraphWithTitle titlePos="right" title="Quantenmechanisches Wirkungsprinzip">
            <section>
                Stattdessen geht die Quantenmechanik davon aus, dass ein gegebenes Teilchen alle 
                möglichen Pfade durchläuft, und weist dann jedem dieser Pfade eine Wahrscheinlichkeit 
                zu. Genauer gesagt betrachtet man in der Quantenmechanik sogenannte Wahrscheinlichkeitsamplituden, 
                deren Betragsquadrat die Wahrscheinlichkeitsdichten der jeweiligen Pfade angibt. Wird eine 
                Messung durchgeführt, werden die oben genannten Amplituden abrupt und drastisch verändert. 
                Dies ist der berüchtigte sogenannte "Kollaps der Wellenfunktion". Abgesehen von möglichen 
                neuen metaphysischen Bedenken, die mit diesem Ansatz verbunden sind, erlaubt die Quantenmechanik 
                trotzdem weiter praktisch exakte Vorhersagen auf der Ebene makroskopischer Teilchensysteme.
            </section>
            <section>
                Allerdings sind sämtliche überprüfbaren Vorhersagen der Quantenmechanik für Einzelteilchen 
                prinzipiell statistisch. Zudem gilt, dass klassische Teilchen streng unterscheidbar sind. 
                Quantenmechanische Teilchen gleicher Art dagegen können nicht voneinander unterschieden 
                werden, während Teilchen unterschiedlicher Art verschränkt sein können. Dies erfordert 
                eine gegenüber klassischen Teilchen veränderte Statistik bzw. führt zu scheinbaren 
                Widersprüchen wie dem Einstein-Podolsky-Rosen-Paradoxon.
            </section>
        </ParagraphWithTitle>
        <ParagraphWithTitle titlePos="left" title="Beziehung zur Relativitätstheorie">
            <section>
                Doch wie verhält sich die Quantenmechanik <InlineFormula math="(0,0,h)"/> konzeptionell 
                zur Relativitätstheorie? Die oben erwähnte Wahrscheinlichkeitsamplitude für ein freies 
                Teilchen der Masse <InlineFormula math="m"/>, üblicherweise mit <InlineFormula math="\Psi"/> bezeichnet, 
                wird durch die Schrödinger-Gleichung (1926) bestimmt, also in diesem Fall
            </section>
            <Cite center>
                <InlineFormula math=" i\hbar\frac{\partial}{\partial t}\Psi=-\frac{\hbar^2}{2m}(\frac{\partial^2}{\partial x^2}+\frac{\partial^2}{\partial y^2}+\frac{\partial^2}{\partial z^2})\Psi "/>
                <br/> Schrödinger-Gleichung
            </Cite> 
            <section>
                Man beachte die Asymmetrie von Raum und Zeit: Die zeitliche Ableitung ist erster Ordnung, 
                die Ableitungen nach den Raumkoordinaten <InlineFormula math="x, y, z"/> sind dagegen 
                zweiter Ordnung. Kein Wunder, denn die Gleichung stammt aus der quantenmechanischen 
                Verallgemeinerung von Newtons Mechanik und widerspricht somit der Speziellen Relativitätstheorie. 
                Dies sehen wir auch schon allein daraus, dass die Lichtgeschwindigkeit <InlineFormula math="c"/> nicht 
                vorkommt.
            </section>
        </ParagraphWithTitle>
    </TheoryContentPageWrapper>;
}

export default QM;