import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import WorldsContentController from '../../../components/WorldsContentController';
import InlineFormula from '../../../components/InlineFormula';

// Styles 
import style from '../../../styles/ExhibitionContent.module.css';

function C0() {
    const {search} = useLocation();

    return <WorldsContentController teaserUrl={"/teaser-labels/c0.png"} comic={"/comics/C0.svg"} title={"Die Galileische Welt"}>
        <section className={style.subtitle}>
            Sie umfasst die folgenden vier Theorien: <NavLink to={"/theories/newtonian-mechanics"+search}>Newtonsche Mechanik</NavLink>,
            &nbsp;<NavLink to={"/theories/newtonian-gravitation"+search}>Newtonsche Gravitation</NavLink>, <NavLink to={"/theories/quantum-mechanics"+search}>Quantenmechanik</NavLink>,&nbsp; 
            und <NavLink to={"/theories/non-relativistic-quantum-gravitation"+search}>Nicht-Relativistische Quantengravitation</NavLink>. 
            Ihre Gegenwelt ist die <NavLink to={"/worlds/c-1"+search}>Relativistische Welt</NavLink>.
        </section>
        <section>
            Ist die Geschwindigkeit des Lichts <InlineFormula math="c" /> endlich oder unendlich, ist also <InlineFormula math="c^{-1}=0"/>? 
            Bis in die Moderne hinein war die Naturwissenschaft mit der Klärung dieser Frage beschäftigt.
        </section>
        <section>
            Lange Zeit war die Aristotelische Vorstellung vorherrschend, dass sich das Licht mit einer unendlichen 
            Geschwindigkeit ausbreitet. Sogar Kepler glaubte dies noch. Andererseits behaupteten bereits antike 
            Philosophen wie Empedokles, dass <InlineFormula math="c" /> endlich sei. 
            Am Beginn der modernen Naturwissenschaften versuchte erstmals Galilei, die Lichtgeschwindigkeit zu bestimmen,
            indem er mit Laternen auf weit voneinander entfernten Hügeln experimentierte. Der erste Beweis der Endlichkeit
            von <InlineFormula math="c" /> gelang jedoch erst 1676 Ole Rømer, der hierfür astronomische Methoden verwendete.
            Interessanterweise und ohne, dass er dies wissen konnte, wurde mit späteren Versionen dieser Experimente Galileis
            eigene Raum-Zeittheorie hinfällig. Denn nach den einige Jahrhunderte später entstandenen Arbeiten von James Clerk 
            Maxwell zur Elektrodynamik und von Albert Einstein zur Speziellen Relativitätstheorie weiß man, dass die 
            Endlichkeit und Konstanz von <InlineFormula math="c" /> in allen Inertialsystemen gilt. Das machte eine Ersetzung 
            der Galileischen Invarianzgesetze für Inertialsysteme durch diejenigen von Hendrik Antoon Lorentz notwendig. 
            Letztere sind Strukturelemente von Elektrodynamik und Spezieller Relativitätstheorie. 
            Im Grenzbereich von <InlineFormula math="c \rightarrow \infty" /> erhält man die alten Galileischen 
            Transformationsgesetze aus denjenigen von Lorentz.
        </section>
        <section>
            Genau in diesem Sinne ist die <InlineFormula math="c^{-1}=0" /> Welt des Würfels zu verstehen: Sie umfasst 
            diejenigen Theorien, die nur die Galileische aber nicht die Lorentzsche Invarianz besitzen. Dies sind zunächst
            Newtons Mechanik und Gravitationstheorie. Allerdings sägte auch Newton selbst an seiner eigenen Theorie, indem 
            er im Rahmen seiner Korpuskulartheorie des Lichts ein endliches <InlineFormula math="c" /> annahm, das aber fälschlich von der 
            Geschwindigkeit der Lichtquelle abhing und damit beobachterabhängig war. Ebenfalls nur Galilei-invariant ist die 
            Schrödinger-Gleichung (1926) der Quantenmechanik. Und schließlich ist auch das <i>hässliche Entlein</i> des Würfels, 
            die Nicht-Relativistische Quantengravitation lediglich Galilei-invariant. Der Würfel sieht für sie zwar einen 
            systematischen, nicht aber einen historischen Ort vor: Nach Newton, Planck und Einstein bedürfte es einer solchen 
            Theorie eigentlich nicht mehr. Ist sie ein Anachronismus, ein Hinweis auf einen Riss im Fundament des Würfels oder 
            eine Herausforderung, nach neuen experimentellen Hinweisen zu fahnden?
        </section>
    </WorldsContentController>;
}
export default C0;