import React, { useState } from 'react';

import style from '../styles/TeamMember.module.css';

function TeamMember(props) {
    const { title, name, imgUrl, role, link, children, mediaStationMode, language } = props;
    const [showDetail, setShowDetail] = useState(false);

    function handleDetailClick() {
        setShowDetail(!showDetail);
    }

    const nameStr = ((title) ? title + " " : "") + name;
    const imgUrlStr = (imgUrl || imgUrl.length > 0) ? imgUrl : "/team/Placeholder.png";

    const showDetailStyle = (showDetail) ? null : style.hidden;

    const moreInfo = (language === "de") ? "Weitere Informationen" : "More info";
    const furtherInfoLink = (mediaStationMode) ? "" : <div style={{ marginTop: "20px" }}><a href={link}>{moreInfo}</a></div>;

    return (
        <div className={style.memberWrapper}>
            <img className={style.portrait} src={imgUrlStr} alt={"Portrait of " + name}></img>
            <div className={style.imageCaptionWrapper} onClick={handleDetailClick}>
                <div style={{ flex: "1 1 auto" }}>
                    <h1 className={style.title}>{nameStr}</h1>
                    <span className={style.subtitle}>{role}</span>
                </div>
                <div className={style.plus} style={{ transform: "rotate(" + (showDetail ? 45 : 0) + "deg)" }}>
                    +
                </div>
            </div>
            <div className={`${showDetailStyle} ${style.detailText}`}>
                {children}
                {furtherInfoLink}
            </div>
        </div>
    );
}

export default TeamMember;
