// Derived from: https://stackoverflow.com/a/4770179
export default class ScrollLocker {
    constructor() {
        // left: 37, up: 38, right: 39, down: 40,
        // spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
        this.keys = {37: 1, 38: 1, 39: 1, 40: 1, 32: 1, 33: 1, 34: 1, 35: 1, 36: 1};

        // modern Chrome requires { passive: false } when adding event
        this.supportsPassive = false;
        try {
            window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
                get: () => { this.supportsPassive = true; return null; } 
            }));
        } catch(e) {}

        this.wheelOpt = this.supportsPassive ? { passive: false } : false;
        this.wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
    }

    preventDefault = (e) => {
        e.preventDefault();
    }
      
    preventDefaultForScrollKeys = (e) => {
        if (this.keys[e.keyCode]) {
            e.preventDefault();
            return false;
        }
    }

    disableScroll(reenableTimer = false) {
        window.addEventListener('DOMMouseScroll', this.preventDefault, false); // older FF
        window.addEventListener(this.wheelEvent, this.preventDefault, this.wheelOpt); // modern desktop
        window.addEventListener('touchmove', this.preventDefault, this.wheelOpt); // mobile
        window.addEventListener('keydown', this.preventDefaultForScrollKeys, false);

        if (reenableTimer) {
            window.setTimeout(() => {
                this.enableScroll();
            }, reenableTimer);
        }
    }
  
    // call this to Enable
    enableScroll() {
        window.removeEventListener('DOMMouseScroll', this.preventDefault, false);
        window.removeEventListener(this.wheelEvent, this.preventDefault, this.wheelOpt); 
        window.removeEventListener('touchmove', this.preventDefault, this.wheelOpt);
        window.removeEventListener('keydown', this.preventDefaultForScrollKeys, false);
    }
}
