import React from 'react';

import TheoryContentPageWrapper from '../../../components/TheoryContentPageWrapper';
import ParagraphWithTitle from '../../../components/ParagraphWithTitle';
import FullRowImage from '../../../components/FullRowImage';
import InlineFormula from '../../../components/InlineFormula';
import TeaserVector from '../../../components/TeaserVector';
import CaptionedImage from '../../../components/CaptionedImage';

// Styles 
import style from '../../../styles/ExhibitionContent.module.css';

function NM() {
    
    return <TheoryContentPageWrapper>
        <TeaserVector g={false} c={false} h={false}/>
        <div className={style.singleColumnParagraph}>
            <h1 className={`${style.title} ${style.mainTitleTopOffset}`}>Newtonsche Mechanik</h1>
            <section>
                Man stelle sich eine Welt vor, in der die Schwerkraft vernachlässigbar ist, in der sich 
                die Gegenstände sehr langsam im Vergleich zur Lichtgeschwindigkeit bewegen, und in der 
                Quanteneffekte zu klein sind, um wahrgenommen werden zu können. Eine solche Welt wäre 
                vollständig durch Galileis Raum-Zeitmodell und die Newtonsche Mechanik beschrieben. 
            </section>
            <section>
                Im Würfel der Physik entspricht dies der Theorie, in der die 
                Gravitationskonstante <InlineFormula math="G"/>, die inverse 
                Lichtgeschwindigkeit <InlineFormula math="c^{-1}"/> und das Plancksche 
                Wirkungsquantum <InlineFormula math="h"/> allesamt verschwinden. Alle Gegenstände 
                in dieser Welt haben einen wohldefinierten Ort im absoluten Raum. Die Zeit ist 
                ein Parameter, der überall identisch, gleichförmig und unentwegt das Verrinnen 
                von Sekunden, Minuten und Stunden zählt. Zu jeder Bewegung gehört ein fester, 
                berechenbarer Weg. Klingt das vertraut? Verrückterweise beschreibt diese Theorie 
                aber nicht die Wirklichkeit, sie liefert nur eine Näherung.
            </section>
        </div>
        <FullRowImage src="/intro-images/newtonian-mechanics.svg"/>
        <ParagraphWithTitle title="Newtons Grundlagen" titlePos="right">
            <section>
                Aus Sicht der heutigen Physik beschäftigt sich die Newtonsche Mechanik mit 
                der Bewegung hinreichend langsamer klassischer Körper unter dem Einfluss 
                eines Systems beliebiger physikalischer Kräfte. Daher kennt die Newtonsche 
                Mechanik <i>per se</i> weder die Gravitationskonstante <InlineFormula math="G"/>, noch 
                eine endliche Lichtgeschwindigkeit <InlineFormula math="c"/> und auch kein 
                Wirkungsquantum <InlineFormula math="h"/>. Sie ist die Theorie <InlineFormula math="(0, 0, 0)"/> des 
                Würfels der Physik und definiert den Ursprung eines Koordinatensystems, dessen 
                Achsen durch diese drei fundamentalen Naturkonstanten definiert sind. 
                Gleichzeitig weist dieses System allen anderen Theorien ihren Platz in diesem Modell zu.
            </section>
            <section>
                Wenn man die Gravitation hinzufügt, bleibt die Newtonsche Mechanik intakt. Sie ist 
                lediglich zur Theorie <InlineFormula math="(G, 0, 0)"/> erweitert, indem sie Newtons 
                Gravitationsgesetz und die grundlegende Idee der Identität von träger und schwerer 
                Masse hinzufügt. Damit definiert diese Theorie eine neue Würfelecke.
            </section>
        </ParagraphWithTitle>
        <CaptionedImage src="/theory-illustrations/newtonian-mechanics.jpg" 
            caption={"Frontispiece aus dem \"Dialog über die zwei Weltsysteme\" (1623) mit Galileis Gedankenexperiment im Hintergrund."} />
        <ParagraphWithTitle title="Galilei's space-time model" titlePos="left">
            <section>
                Die Newtonsche Mechanik basiert auf Galileis Modell von Raum und Zeit. Sie geht 
                davon aus, dass zwei Inertialsysteme physikalisch ununterscheidbar sind. In 
                einem berühmten Gedankenexperiment hat Galilei ein Schiff betrachtet, das sich 
                geradlinig mit konstanter Geschwindigkeit bewegt. Unter Deck, alle Luken dicht 
                und kein Mobiltelefon zur Hand, kann man zwar die eigene Bewegung bezogen auf 
                das Schiff bestimmen, nicht aber bezogen auf die See. Dieses Gedankenexperiment 
                war für Galilei von solcher Strahlkraft, dass er es im Frontispiz seines 
                "Dialogs über die zwei Weltsysteme" prominent zwischen den Figuren von Aristoteles, 
                Ptolemäus und Kopernikus verewigte. Darüber hinaus macht die Newtonsche Mechanik 
                eine absolute Zeit geltend, die in sämtlichen Inertialsystemen gleich verläuft. 
                Die Geschwindigkeiten von Inertialsystemen addieren sich linear. Damit ist es möglich, 
                alltägliche mechanische Systeme hinreichend genau zu beschreiben
            </section>
        </ParagraphWithTitle>
        <ParagraphWithTitle title="Nachträgliche Korrekturen" titlePos="right">
            <section>
                Galileis Modell von Raum und Zeit führt jedoch zu logischen Widersprüchen 
                mit den Maxwell-Gleichungen der Elektrodynamik. Daher sind für große 
                Geschwindigkeiten grundlegende Korrekturen notwendig, die nur im Rahmen 
                von Einsteins Lorentz-invarianter spezieller Relativitätstheorie verständlich 
                sind. Letztere entspricht im Würfel der Ecke <InlineFormula math="(0, c^{-1}, 0)"/>.
            </section>
            <section>
                Noch drastischere Korrekturen erfährt die klassische Mechanik auf 
                atomaren und subatomaren Längenskalen. Sie verliert ihren deterministischen 
                Charakter und wird im Rahmen der Quantenmechanik, der 
                Theorie <InlineFormula math="(0, 0, h)"/>, probabilistisch reformuliert. 
                Sämtliche Korrekturen der klassischen Mechanik verweisen also auf neue 
                Ecken des Würfels. Die beiden Theorien <InlineFormula math="(0, 0, 0)"/>, die Newtonsche Mechanik, 
                und <InlineFormula math="(G, 0, 0)"/>, die Newtonsche Gravitationstheorie, wurden von 
                Newton zeitgleich entwickelt; hier widerspricht also die Systematik des Würfels 
                in gewisser Weise der Geschichte der Physik. Newton befindet sich demnach auf 
                zwei Ecken des Würfels, hat aber diese Unterscheidung selbst nie gemacht. Auf der 
                anderen Seite wurden die Korrekturen <InlineFormula math="(0, c^{-1}, 0)"/> und <InlineFormula math="(0, 0, h)"/> bekanntermaßen 
                erst zu Beginn des 20. Jahrhunderts eingeführt. Hier spiegelt der Würfel der 
                Physik die Wissensgeschichte wider.
            </section>
        </ParagraphWithTitle>
    </TheoryContentPageWrapper>;
}
export default NM;