const xPositions = [
    0.0625, // 0
    0.3625, // 1
    0.5,    // 2
    0.6375, // 3
    0.9375  // 4
];

const yPositions = [
    0.12375,    // 0
    0.18,       // 1
    0.23625,    // 2
    0.2925,     // 3
    0.5,        // 4
    0.76125,    // 5
    0.8175,     // 6
    0.87375,    // 7
    0.93        // 8
];

const vertexAnimations = [
    [   // p0
        [xPositions[2], yPositions[4]], // stage 0
        [xPositions[2], yPositions[8]], // stage 1
        [xPositions[0], yPositions[7]], // stage 2
        [xPositions[0], yPositions[7]]  // stage 3
    ],
    [   // p1
        [xPositions[2], yPositions[4]], // stage 0
        [xPositions[2], yPositions[8]], // stage 1
        [xPositions[3], yPositions[8]], // stage 2
        [xPositions[3], yPositions[8]]  // stage 3
    ],
    [   // p2
        [xPositions[2], yPositions[4]], // stage 0
        [xPositions[2], yPositions[3]], // stage 1
        [xPositions[0], yPositions[2]], // stage 2
        [xPositions[0], yPositions[2]]  // stage 3
    ],
    [   // p3
        [xPositions[2], yPositions[4]], // stage 0
        [xPositions[2], yPositions[3]], // stage 1
        [xPositions[3], yPositions[3]], // stage 2
        [xPositions[3], yPositions[3]]  // stage 3
    ],
    [   // p4
        [xPositions[2], yPositions[4]], // stage 0
        [xPositions[2], yPositions[8]], // stage 1
        [xPositions[0], yPositions[7]], // stage 2
        [xPositions[1], yPositions[5]]  // stage 3
    ],
    [   // p5
        [xPositions[2], yPositions[4]], // stage 0
        [xPositions[2], yPositions[8]], // stage 1
        [xPositions[3], yPositions[8]], // stage 2
        [xPositions[4], yPositions[6]]  // stage 3
    ],
    [   // p6
        [xPositions[2], yPositions[4]], // stage 0
        [xPositions[2], yPositions[3]], // stage 1
        [xPositions[0], yPositions[2]], // stage 2
        [xPositions[1], yPositions[0]]  // stage 3
    ],
    [   // p7
        [xPositions[2], yPositions[4]], // stage 0
        [xPositions[2], yPositions[3]], // stage 1
        [xPositions[3], yPositions[3]], // stage 2
        [xPositions[4], yPositions[1]]  // stage 3
    ],
];

const connections = [
    0,1,
    1,5,
    0,4,
    4,5,
    0,2,
    1,3,
    4,6,
    5,7,
    2,3,
    3,7,
    7,6,
    6,2
]

const animationConfig = {
    stepDuration : 1000, // in ms
    pauseBetweenSteps : 500,
    vertexAnimations : vertexAnimations,
    vertexConnections : connections
}


export default animationConfig;