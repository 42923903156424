import TopNavMenu from './components/TopNavMenu';
import Title from './components/Title';
import LegalFooter from '../../general-components/LegalFooter';

import PageContent from './config/PageContent'

import style from './styles/MediaStation.module.css';

function MediaStationView(props) {
    const {page, language} = props;

    return <div className={style.wrapper}>
        <div className={style.contentColumn}>
            <TopNavMenu page={page}/>
            <Title page={page} language={language}/>
            {PageContent[language][page]}
            <LegalFooter black language={language} />
        </div>
    </div>;
} 

export default MediaStationView;