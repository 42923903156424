import React from 'react';

// Styles
import baseStyle from '../styles/ExhibitionTitle.module.css';

function ExhibitionIntroTeaser(props) {
    
    const en = <div className={baseStyle.titleWrapper}>
        <h1 className={baseStyle.mainTitle}>The Cube in 4 Steps</h1>
        <h2 className={baseStyle.subTitle}>An introduction of the meta model</h2>
    </div>;

    const de = <div className={baseStyle.titleWrapper}>
        <h1 className={baseStyle.mainTitle}>Der Würfel in 4 Schritten</h1>
        <h2 className={baseStyle.subTitle}>Eine Einführung in das Metamodell</h2>
    </div>;

    return (props.language === "de") ? de : en;
}
export default ExhibitionIntroTeaser;