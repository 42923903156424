import React, { useEffect, useState } from 'react';

import Teaser from './Teaser';
import Paragraph from './SingleColumnParagraph';

import NavigationMenu from './NavigationMenu';

// Styles 
import style from '../styles/ExhibitionContent.module.css';

function WorldsContentController(props) {
    const { teaserUrl, title, children, comic } = props;

    const [showContent, setShowContent] = useState(false);
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.scrollTo(0,0);
        window.addEventListener("wheel", revealContent);
        window.addEventListener("resize", resizeHandler);

        return () => {
            window.removeEventListener("wheel", revealContent);
            window.removeEventListener("resize", resizeHandler);
        }
    }, []);

    function revealContent() {
        if (showContent) {
            return;
        }
        setShowContent(true);
    }

    function resizeHandler() {
        setInnerWidth(window.innerWidth);
    }

    function renderLandscape() {
        const hidden = (showContent) ? style.fadeText : style.hidden;
        const hiddenArrow = (!showContent) ? style.fadeText : style.hidden;
        const comicAnimation = (showContent) ? style.moveComic : null;

        return <div className={style.worldContentWrapper} onClick={revealContent} onTouchStart={revealContent}>
            <Teaser imgUrl={teaserUrl}></Teaser>
            <div className={`${style.worldColumnWrapper} ${hidden}`}>
                <h1 className={style.title}>{title}</h1>
                <Paragraph scrollable>
                    {children}
                </Paragraph>
            </div>
            <div className={`${style.worldComic} ${comicAnimation}`}>
                <img alt="" src={comic}></img>
                <img className={`${style.scrollArrow} ${hiddenArrow}`} alt="Simple icon of an arrow pointing down, implying to scroll down the page" src="/button-icons/arrow-right.svg"></img>
            </div>

        </div>;
    }

    function renderPortrait() {
        return <div className={style.worldPortraitWrapper}>
            <div className={style.worldPortraitImageWrapper}>
                <img alt="" src={comic}></img>
            </div>
            <h1 className={style.title} style={{ paddingLeft: 0 }}>{title}</h1>
            {children}
            <NavigationMenu type={"worlds"} />
        </div>;
    }

    if (innerWidth >= 800) {
        return renderLandscape();
    } else {
        return renderPortrait();
    }
}
export default WorldsContentController;