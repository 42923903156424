import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import WorldsContentController from '../../../components/WorldsContentController';
import InlineFormula from '../../../components/InlineFormula';

// Styles 
import style from '../../../styles/ExhibitionContent.module.css';

function C0() {
    const {search} = useLocation();

    return <WorldsContentController teaserUrl={"/teaser-labels/c0.png"} comic={"/comics/C0.svg"} title={"Galilean World"}>
        <section className={style.subtitle}>
            It comprises the following four theories: <NavLink to={"/theories/newtonian-mechanics"+search}>Newtonian Mechanics</NavLink>,&nbsp;
            <NavLink to={"/theories/newtonian-gravitation"+search}>Newtonian Gravitation</NavLink>,
            &nbsp;<NavLink to={"/theories/quantum-mechanics"+search}>Quantum Mechanics</NavLink>,&nbsp;
            and <NavLink to={"/theories/non-relativistic-quantum-gravitation"+search}>Non-relativistic Quantum Gravitation</NavLink>. 
            <br/>Its antipode is the <NavLink to={"/worlds/c-1"+search}>Relativistic World</NavLink>.
        </section>
        <section>
            Is the speed of light <InlineFormula math="c" /> finite or infinite, i.e. <InlineFormula math="c^{-1}=0"/>? Until 
            early modern times this question occupied many scholars.
        </section>
        <section>
            For a long time, the Aristotelian opinion dominated that light moves at infinite speed. Even Kepler still held this belief. 
            On the other hand, e.g. the antique philosopher Empedocles already suspected <InlineFormula math="c" /> to be finite. In early modern times, 
            Galilei tried for the first time to experimentally measure the speed of light by means of lanterns placed on well-separated 
            hills. However, the first proof of finiteness was presented in 1676 by Ole Rømer, using astronomical methods. 
            Interestingly, and unbeknownst to him, Galilei's space-time theory was ultimately destroyed by later versions of these experiments.
            In fact, several centuries later, the work of James Clerk Maxwell on electrodynamics and Albert Einstein on Special Relativity 
            firmly established the finiteness and constancy of <InlineFormula math="c" /> in any inertial reference frame. This required the replacement of 
            Galilei's invariance laws for inertial systems by those of Hendrik Antoon Lorentz. The latter are structural components 
            of electrodynamics and Special Relativity. In the limit <InlineFormula math="c \rightarrow \infty" />, Lorentz's transformations nicely reduce 
            to the ones of Galilei.
        </section>
        <section>
            The <InlineFormula math="c^{-1}=0" /> world of the cube is to be understood in exactly this sense: It comprises those physical theories that only possess 
            Galilean as opposed to Lorentzian invariance. To begin with, these are Newtonian Mechanics and Newtonian Gravitation. However, Newton 
            also already sowed the seeds of distraction of his own theory. Namely, he assumed a finite, but fatally wrong observer-dependent 
            speed of light in the framework of his corpuscular theory of light. Likewise, Schrödinger's famous quantum mechanical equation of 
            1926 only exhibits, erroneously, Galilei invariance. Finally, the cube's <i>ugly duckling</i>, Non-relativistic Quantum Gravitation, 
            is perfectly Galilean invariant. The cube assigns to the latter merely a systematic, not historical status: According to Newton, 
            Planck and Einstein, such a theory is actually rendered superfluous. Does this indicate an anachronism, a crack in the foundation 
            of the cube, or a challenge to search for new experimental clues?
        </section>
    </WorldsContentController>;
}
export default C0;