import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import WorldsContentController from '../../../components/WorldsContentController';

// Styles 
import style from '../../../styles/ExhibitionContent.module.css';

function G1() {
    const {search} = useLocation();

    return <WorldsContentController teaserUrl={"/teaser-labels/G1.png"} comic={"/comics/G1.svg"} title={"Die Welt mit Gravitation"}>
        <section className={style.subtitle}>
            Die Welt der Schwerkraft umfasst die folgenden vier Theorien: <NavLink to={"/theories/newtonian-gravitation"+search}>Newtonsche Gravitation</NavLink>,&nbsp;
            <NavLink to={"/theories/general-relativity"+search}>Allgemeine Relativitätstheorie</NavLink>,&nbsp;
            <NavLink to={"/theories/non-relativistic-quantum-gravitation"+search}>Nicht-Relativistische Quantengravitation</NavLink>, und die &nbsp;
            <NavLink to={"theory-of-everything"+search}>Theorie von Allem</NavLink>. 
            <br/>Ihre Gegenwelt ist die <NavLink to={"/worlds/g-0"+search}>Welt ohne Gravitation</NavLink>.
        </section>
        <section>
            Die Gravitation ist die schwächste der vier Naturkräfte, jedoch die in unserem Alltag offensichtlichste. 
            Dies erklärt sich aus ihrer unendlichen Reichweite und der Tatsache, dass sie nicht abschirmbar ist: 
            Alle Dinge ziehen sich gegenseitig an. Newton formulierte als erster eine Gravitationstheorie, die mit 
            einer für lange Zeit hinreichenden Genauigkeit sowohl das Fallen von Objekten auf der Erde als auch 
            die Bahnen der uns unmittelbar umgebenden Himmelskörper beschreibt. Das nach derzeitigem Wissensstand 
            bis auf mögliche Quanteneffekte korrekte Gravitationsgesetz wurde gut 200 Jahre später von Einstein 
            aufgestellt. Und tatsächlich ist Newtons Theorie nur eine Approximation: Einsteins bis heute gültige 
            Allgemeine Relativitätstheorie umfasst einerseits feine Abweichungen von Newtons Theorie und führt 
            anderseits zu einem vollständig neuartigen Bild des Kosmos, indem sie einen Urknall nahelegt sowie 
            Gravitationswellen (Nobelpreis 2017) und Schwarze Löcher (Nobelpreis 2020) vorhersagt.
        </section>
        <section>
            Dies alles veränderte unser Verständnis der Raum-Zeit grundlegend. Raum und Zeit sind nicht länger nur 
            Bühne und Hintergrund der Theorie, sondern werden Teil des physikalischen Geschehens. Die Gravitationskraft 
            als solche verschwindet, und Anziehung wird zu einer Bewegung in einer lokal gekrümmten Raum-Zeit. 
            Die Raum-Zeit ist dynamisch: Sie wird durch die Bewegung und Verteilung von Materie und Energie geformt 
            und ist deshalb untrennbar mit ihnen verbunden.
        </section>
        <section>
            Auch in dieser Welt sollten jedoch die Gesetze der Quantenmechanik gelten. Dabei ist die 
            Nicht-Relativistische Quantengravitation in unserem Würfelmodell nicht besonders spannend, 
            da sie unproblematisch ist. Eine quantenmechanische Verallgemeinerung der Allgemeinen Relativitätstheorie 
            zu finden, stellt dagegen eine der großen aktuellen Herausforderungen dar: Dies würde im Rahmen des 
            Würfelmodells zu einer Theorie von Allem führen. Versuche, eine solche Theorie konsistent zu 
            formulieren, wie die Stringtheorie oder Quantenschleifengravitation, sind bisher jedoch weitgehend 
            erfolglos.
        </section>
        <section>
            Die Theorie von Allem sollte eine Einsicht darüber liefern, was die Raum-Zeit selbst ist – wie und
            aus welchen Prinzipien sich diese auf kleinsten Skalen herausbildet. Das Universum ist also nicht 
            unabhängig von den darin befindlichen Entitäten, sondern damit verschmolzen. Bühne und Akteure können 
            nicht voneinander getrennt werden.
        </section>
    </WorldsContentController>;
}

export default G1;