import React, {useRef, useEffect} from 'react';

// Styles
import baseStyle from '../styles/Exhibition.module.css';

// Config
import animConfig from '../config/NCubeAnimationConfig';

function NCubeAnimation(props) {
    const {active} = props;

    const canvas = useRef(null);
    const ctx = useRef(null);
    
    const dpr = useRef(null);
    const width = useRef(null);
    const height = useRef(null);
    const refLength = useRef(null);
    const offset = useRef(null);
    
    const animStage = useRef(0);
    const animStageStart = useRef(0);
    
    const vertices = useRef([
            [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0]
    ]);

    const requestID = useRef(null);

    useEffect(() => {
        window.addEventListener('resize', onWindowResize);
        updateCanvasProps();

        animStageStart.current = performance.now();
        requestID.current = window.requestAnimationFrame(update);

        return () => {
            window.removeEventListener('resize', onWindowResize);
            window.cancelAnimationFrame(requestID.current);
        }
    }, []);

    useEffect(() => {
        (props.active) ? restartAnimation() : window.cancelAnimationFrame(requestID.current);
    }, [props.active]);

    function updateCanvasProps() {
        dpr.current = window.devicePixelRatio || 1;
        width.current = canvas.current.clientWidth * dpr.current;
        height.current = canvas.current.clientHeight * dpr.current;

        canvas.current.width = width.current;
        canvas.current.height = height.current;
        
        refLength.current = Math.min(width.current, height.current);
        offset.current = {
            x: (width.current - refLength.current) / 2,
            y: (height.current - refLength.current) / 2,
        };
    
        ctx.current = canvas.current.getContext("2d");
    }

    const updateVertexPositions = (progress) => {
        for (let i=0; i<animConfig.vertexAnimations.length; i++) {
            let currentPos = animConfig.vertexAnimations[i][animStage.current];
            let nextPos = animConfig.vertexAnimations[i][nextAnimationStage()];
            
            let xStep = (nextPos[0] - currentPos[0]) * progress;
            let yStep = (nextPos[1] - currentPos[1]) * progress;

            vertices.current[i][0] = (currentPos[0] + xStep) * refLength.current;
            vertices.current[i][1] = (currentPos[1] + yStep) * refLength.current; 
        }
    }

    function restartAnimation() {
        animStageStart.current = performance.now();
        animStage.current = 0;
        update();
    }

    function nextAnimationStage() {
        let result = animStage.current + 1;
        if (result > 3) {
            result = 0;
        }
        return result;
    }

    const update = () => {
        ctx.current.clearRect(0, 0, width.current, height.current);

        let deltaT = performance.now() - animStageStart.current;
        
        // Calculate stage progress 
        let stepProgress = 0;
        stepProgress = (deltaT > animConfig.pauseBetweenSteps) ? 
            (deltaT - animConfig.pauseBetweenSteps) / animConfig.stepDuration : 0;

        updateVertexPositions(stepProgress);

        // Prepare draw state
        ctx.current.save();
        ctx.current.translate(offset.current.x, offset.current.y);
        ctx.current.strokeStyle = "#ffffff";
        ctx.current.fillStyle = "#ffffff";
        ctx.current.lineWidth = 1 * dpr.current;

        // Draw vertices
        for (let i=0; i<vertices.current.length; i++) {
            ctx.current.beginPath();
            ctx.current.arc(
                vertices.current[i][0], 
                vertices.current[i][1], 
                refLength.current * 0.005, 0, 2 * Math.PI);
            ctx.current.fill(); 
        }

        // Draw edges
        for (let i=0; i<animConfig.vertexConnections.length; i+=2) {
            let v1 = animConfig.vertexConnections[i];
            let v2 = animConfig.vertexConnections[i+1]
            
            ctx.current.beginPath();
            ctx.current.moveTo(vertices.current[v1][0], vertices.current[v1][1]);
            ctx.current.lineTo(vertices.current[v2][0], vertices.current[v2][1]); 
            ctx.current.stroke();
        }

        ctx.current.restore();
        
        // Check if stage is finished
        if (deltaT > animConfig.stepDuration + animConfig.pauseBetweenSteps) {
            animStageStart.current = performance.now();
            animStage.current = nextAnimationStage();
        }

        requestID.current = window.requestAnimationFrame(update);
    }

    function onWindowResize() {
        updateCanvasProps();
    };

    return <div className={baseStyle.introAnimationWrapper}>
        <canvas className={baseStyle.introAnimationCanvas} ref={canvas}/>
    </div>;
}
export default NCubeAnimation;