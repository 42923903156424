import React from 'react';

import BurgerMenu from './BurgerMenu';
import LegalFooter from './LegalFooter';

import style from '../styles/Misc.module.css';

function MiscPage(props) {
  const {backgroundColor, title, children, language} = props;
  
  const bgColor = backgroundColor ? backgroundColor : "#F59B7E";
  const mainTitle = (title) ? <h1 className={style.title}>{title}</h1> : null;

  return <div className={style.backgroundWrapper} style={{backgroundColor: bgColor}}>
    <BurgerMenu language={props.language} />
    <div className={style.contentWrapper}>
        {mainTitle}
        {children}
        <LegalFooter language={props.language} />
    </div>
  </div>;
}

export default MiscPage;
