import React from 'react';

import MiscPage from '../../general-components/MiscPage';

import style from '../../styles/Misc.module.css';

function Contact(props) {
    const huLink = (props.mediaStationMode) ? "https://www.physik.hu-berlin.de" 
        : <a href="https://www.physik.hu-berlin.de">https://www.physik.hu-berlin.de</a>;
    
    const cc4Link = (props.mediaStationMode) ? "Creative Commons Attribution 4.0 International License" 
        : <a href="https://creativecommons.org/licenses/by/4.0/">Creative Commons Attribution 4.0 International License</a>

    const de = <MiscPage backgroundColor={"#D6BBC1"} language={props.language}>
        <h1 className={style.title}>Impressum</h1>
        <h2 className={style.minorTitle}>
            Humboldt-Universität zu Berlin
        </h2>
        <p>
            Im Auftrag des Präsidiums der Humboldt-Universität zu Berlin trägt das Institut für
            Physik die Verantwortung für den Inhalt dieser Website.
        </p>
        <h1 className={style.title}>Kontakt</h1>
        <h2 className={style.minorTitle}>
            Humboldt-Universität zu Berlin
        </h2>
        <p>
            Humboldt-Universität zu Berlin<br />
            Unter den Linden 6<br />
            10099 Berlin
        </p>
        <p>
            <span className={style.minorTitle}>Telefon:</span> +49 30 2093-0<br />
            <span className={style.minorTitle}>Fax:</span> +49 30 2093-2770
        </p>
        <h2 className={style.minorTitle}>Institut für Physik</h2>
        <p>
            <span className={style.minorTitle}>Homepage:</span>&nbsp;{huLink}<br />
        </p>
        <p>
            Newtonstraße 15 <br />
            12489 Berlin
        </p>
        <p>
            <span className={style.minorTitle}> E-Mail:</span> idir@physik.hu-berlin.de <br />
            <span className={style.minorTitle}> Telefon:</span> +49 30 2093-7600
        </p>
        <h1 className={style.title}>Haftungsausschluss</h1>
        <p>
            Sofern nicht anders angegeben, ist der Inhalt dieser Website lizenziert unter einer
            &nbsp;{cc4Link}.
        </p>
        <h1 className={style.title}>Unterstützung</h1>
        <p>
            Das Projekt dankt für die Unterstützung des Exzellenzclusters »Matters of Activity. Image 
            Space Material«, gefördert durch die Deutsche Forschungsgemeinschaft (DFG) im Rahmen 
            der Exzellenzstrategie des Bundes und der Länder – EXC 2025 – 390648296.
        </p>
        <img className={style.logo} src="/Logo_MoA_White.png" alt="Logo des Exzellensclusters Matters of Activty"></img>
    </MiscPage>;

    const en = <MiscPage backgroundColor={"#D6BBC1"} language={props.language}>
        <h1 className={style.title}>Imprint</h1>
        <h2 className={style.minorTitle}>
            Humboldt-Universität zu Berlin
        </h2>
        <p>
            On behalf of the president of Humboldt-Universität zu Berlin, the Department of
            Physics carries responsibility for the content of this website.
        </p>
        <h1 className={style.title}>Contact</h1>
        <h2 className={style.minorTitle}>
            Humboldt-Universität zu Berlin
        </h2>
        <p>
            Humboldt-Universität zu Berlin<br />
            Unter den Linden 6<br />
            10099 Berlin/Germany
        </p>
        <p>
            <span className={style.minorTitle}>Phone:</span> +49 30 2093-0<br />
            <span className={style.minorTitle}>Fax:</span> +49 30 2093-2770
        </p>
        <h2 className={style.minorTitle}>Department of Physics</h2>
        <p>
            <span className={style.minorTitle}>Homepage:</span>&nbsp;{huLink}<br />
        </p>
        <p>
            Newtonstraße 15 <br />
            12489 Berlin
        </p>
        <p>
            <span className={style.minorTitle}> E-Mail:</span> idir@physik.hu-berlin.de <br />
            <span className={style.minorTitle}> Phone:</span> +49 30 2093-7600
        </p>
        <h1 className={style.title}>Disclaimer</h1>
        <p>
            Except where otherwise noted, content on this site is licensed under a&nbsp;{cc4Link}.
        </p>
        <h1 className={style.title}>Acknowledgments</h1>
        <p>
            The project acknowledges the support of the Cluster of Excellence »Matters of Activity. 
            Image Space Material« funded by the Deutsche Forschungsgemeinschaft (DFG, German Research Foundation) 
            under Germany's Excellence Strategy – EXC 2025 – 390648296.
        </p>
        <img className={style.logo} src="/Logo_MoA_White.png" alt="Logo of the cluster of excellence Matters of Activty"></img>
    </MiscPage>;

    return (props.language === "de") ? de : en;
}

export default Contact;
