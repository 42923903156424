import React from 'react';

// Styles 
import style from '../styles/ExhibitionContent.module.css';

function Cite(props) {
    const {center, children} = props;
    let centerStyle = (center) ? style.center: null;
        
    return <section className={`${style.cite} ${centerStyle}`}>{children}</section>;
}
export default Cite;