import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import WorldsContentController from '../../../components/WorldsContentController';
import InlineFormula from '../../../components/InlineFormula';

// Styles 
import style from '../../../styles/ExhibitionContent.module.css';

function C1() {
    const {search} = useLocation();

    return <WorldsContentController teaserUrl={"/teaser-labels/c1.png"} comic={"/comics/C1.svg"} title={"Relativistic World"}>
        <section className={style.subtitle}>
            It comprises the following four theories: <NavLink to={"/theories/special-relativity"+search}>Special Relativity</NavLink>,&nbsp;
            <NavLink to={"/theories/general-relativity"+search}>General Relativity</NavLink>,&nbsp;
            <NavLink to={"/theories/quantum-field-theory"+search}>Quantum Field Theory</NavLink>,&nbsp;
            and <NavLink to={"/theories/theory-of-everything"+search}>Theory of Everything</NavLink>.
            <br/>Its antipode is the <NavLink to={"/worlds/c-0"+search}>Galilean World</NavLink>.
        </section>
        <section>
            The <InlineFormula math="c^{-1}=1" /> world of the cube is made up from those four theories in which three-dimensional space and one-dimensional time 
            are combined into a new entity, christened four-dimensional space-time. Within this space-time, the velocity of light <InlineFormula math="c" /> is the 
            fundamental speed limit. In fact, <InlineFormula math="c" /> has been fixed by way of definition in 1983, and thereby reduced to a mere conversion factor 
            between meters and seconds. One might quip that in everyday language, space and time have been intermingled for a <i>long time</i>. 
            Incidentally, classical electrodynamics, subsumed in Special Relativity, is also included in the <InlineFormula math="c^{-1}=1" /> world.
        </section>
        <section>
            Within Albert Einstein's <InlineFormula math="c^{-1}=1" /> world, the simultaneity of two events depends on the reference system, while the speed of light 
            does not. In contrast, in the Galilean <InlineFormula math="c^{-1}=0" /> world, time is absolute, whereas the speed of light depends on the reference system. 
            Hence, while the Galilean world is an approximation, which has been sufficiently accurate until the advent of the 20th century, 
            relativistic effects must now even be taken into account in today's indispensable technologies such as GPS positioning. While important 
            features of the <InlineFormula math="c^{-1}=1" /> world such as length contraction and time dilation often contradict naive logic, they are solidly incorporated 
            into modern physics and high technology. For example, muons generated in the Earth's atmosphere can be detected on Earth, although their 
            half-life would not be sufficient to cover this distance. Here, the reference system is decisive. In a laboratory's reference system, 
            muons live longer, because of their high speed. But in their own rest frame, the distance to Earth is shorter.
        </section>
    </WorldsContentController>;
}

export default C1;