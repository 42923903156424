import React from 'react';

import style from '../styles/ExhibitionIntroduction.module.css';

function ExhibitionShortTextQuickNavButton(props) {
    const { onPageUpdate, pageID, activePage } = props;

    function handleNavButtonClick() {
        onPageUpdate(pageID);
    }

    let inactive = (pageID > activePage) ? style.inactive : '';

    return <div className={`${style.quickNavButton} ${inactive}`}
        onClick={handleNavButtonClick} />;
}
export default ExhibitionShortTextQuickNavButton;