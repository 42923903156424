import { useNavigate, useLocation } from 'react-router-dom';

import style from '../styles/MediaStation.module.css';

function TopNavMenuEntry(props) {
    const { page, id } = props;

    const {search} = useLocation();

    const activeStyle = (page === id) ? style.active : '';
    const text = "0"+(id+1);

    const navigate = useNavigate();

    function handleClick() {
        if (page !== id) {
            navigate("/"+text+search);
        }
    }
        
    return <div className={`${style.topNavMenuEntry} ${activeStyle}`} onClick={handleClick}>
        {text}
    </div>;
}

export default TopNavMenuEntry;