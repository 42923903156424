import React, {useState} from 'react';
import {useNavigate, useLocation, useSearchParams} from "react-router-dom";

import localization from '../config/Localization';

import style from '../styles/BurgerMenu.module.css';

function ExhibitionBurgerMenu (props) {
    const {onHomeClick, language, mediaStationMode} = props;
    const [isOpen, setIsOpen] = useState(false);
    const [theoriesOpen, setTheoriesOpen] = useState(false);
    const [worldsOpen, setWorldsOpen] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    function clickHome () {
        if (onHomeClick) {
            onHomeClick();
            closeMenu();
        } else {
            navigate("/exhibition"+location.search);    
        }
    }

    function clickExplore () {
        if (location.pathname === "/explore") {
            closeMenu();
        } else {
            navigate("/explore"+location.search);
            window.scrollTo(0,0);
        }
    }

    function clickAbout () {
        if (location.pathname === "/about") {
            closeMenu();
        } else {
            navigate("/about"+location.search);
            window.scrollTo(0,0);
        }
    }

    function openSubpage (path) {
        navigate(path+location.search);
        window.scrollTo(0,0);
    }

    function clickHistory () {
        if (location.pathname === "/history") {
            this.closeMenu();
        } else {
            navigate("/history"+location.search);
            window.scrollTo(0,0);
        }
    }


    function toggleMenu() {
        setIsOpen(!isOpen);
    }

    function closeMenu() {
        setIsOpen(false);
    }

    function selectDE() {
        setLanguage("de");
    }

    function selectEN() {
        setLanguage("en");
    }

    function setLanguage(lang) {
        let params = {lang: lang};
        let msm = searchParams.get("mediaStationMode");
        if (msm) {
            params["mediaStationMode"] = msm;
        }
        setSearchParams(params);
        closeMenu();
    }


    let imgSrc = "/button-icons/close.svg";
    let overlay = <div className={style.menuOpenOverlay} onClick={closeMenu}/>;
    let visibility = null;

    if (!isOpen) {
        imgSrc = "/button-icons/menu-icon.svg";
        overlay = null;
        visibility = style.hidden;
    }

    const theoriesArrow = (!theoriesOpen) ? 90 : 270;
    const worldsArrow = (!worldsOpen) ? 90 : 270;

    const theories = (language === "de") ? "Theorien" : "Theories";
    const worlds = (language === "de") ? "Welten" : "Worlds";
    const about = (language === "de") ? "Über Uns" : "About";
    const history = (language === "de") ? "Geschichte" : "History";

    const enlardedIcon = (mediaStationMode) ? style.menuIconEnlarged : null;

    return <div className={style.topMenuWrapper}>
        {overlay}
        <div className={style.menuBox}>
            <img className={`${style.menuIcon} ${enlardedIcon}`} src={imgSrc} onClick={toggleMenu} alt="Burger-Menu for quick navigation and language selection"></img>
            <ul className={`${style.menuList} ${visibility}`}>
                <li className={style.menuListEntry} onClick={clickHome}>Home</li>
                <li className={style.menuListEntry} onClick={clickExplore}>Explore Mode</li>
                <li className={style.expandingListEntry}>
                    <details onToggle={(ev) => {setTheoriesOpen(ev.target.open);}}>
                        <summary className={style.topicListTitle}>
                            {theories} 
                            <img className={style.topicListArrow} style={{transform: "rotate("+theoriesArrow+"deg)"}} src="/button-icons/arrow-right.svg" alt=""></img>
                        </summary>
                        <ul className={style.topicList}>
                            <li className={style.topicListEntry} onClick={()=>openSubpage("/theories/newtonian-mechanics")}>
                                {localization["NM"][language]}
                            </li>
                            <li className={style.topicListEntry} onClick={()=>openSubpage("/theories/newtonian-gravitation")}>
                                {localization["NGT"][language]}
                            </li>
                            <li className={style.topicListEntry} onClick={()=>openSubpage("/theories/general-relativity")}>
                                {localization["GR"][language]}
                            </li>
                            <li className={style.topicListEntry} onClick={()=>openSubpage("/theories/special-relativity")}>
                                {localization["SR"][language]}
                            </li>
                            <li className={style.topicListEntry} onClick={()=>openSubpage("/theories/quantum-field-theory")}>
                                {localization["QFT"][language]}
                            </li>
                            <li className={style.topicListEntry} onClick={()=>openSubpage("/theories/quantum-mechanics")}>
                                {localization["QM"][language]}
                            </li>
                            <li className={style.topicListEntry} onClick={()=>openSubpage("/theories/non-relativistic-quantum-gravitation")}>
                                {localization["NRQG"][language]}
                            </li>
                            <li className={style.topicListEntry} onClick={()=>openSubpage("/theories/theory-of-everything")}>
                                {localization["TOE"][language]}
                            </li>
                        </ul>
                    </details>
                </li>
                <li className={style.expandingListEntry}>
                    <details onToggle={(ev) => {setWorldsOpen(ev.target.open);}}>
                        <summary className={style.topicListTitle}>
                            {worlds}
                            <img className={style.topicListArrow} style={{transform: "rotate("+worldsArrow+"deg)"}} src="/button-icons/arrow-right.svg" alt=""></img>
                        </summary>
                        <ul className={style.topicList}>
                            <li className={style.topicListEntry} onClick={()=>openSubpage("/worlds/g-0")}>{localization["G0"][language]}</li>
                            <li className={style.topicListEntry} onClick={()=>openSubpage("/worlds/g-1")}>{localization["G1"][language]}</li>
                            <li className={style.topicListEntry} onClick={()=>openSubpage("/worlds/c-0")}>{localization["C0"][language]}</li>
                            <li className={style.topicListEntry} onClick={()=>openSubpage("/worlds/c-1")}>{localization["C1"][language]}</li>
                            <li className={style.topicListEntry} onClick={()=>openSubpage("/worlds/h-0")}>{localization["H0"][language]}</li>
                            <li className={style.topicListEntry} onClick={()=>openSubpage("/worlds/h-1")}>{localization["H1"][language]}</li>
                        </ul>
                    </details>
                </li>
                <li className={style.menuListEntry} onClick={clickHistory}>{history}</li>
                <li className={style.menuListEntry} onClick={clickAbout}>{about}</li>
            </ul>
            <div className={`${style.languageButtonsWrapper} ${visibility}`}>
                <div className={`${style.languageButton} ${(language === "de") ? style.active : null}`} onClick={selectDE}>DE</div>
                <div className={`${style.languageButton} ${(language === "en") ? style.active : null}`} onClick={selectEN}>EN</div>
            </div>
        </div>
    </div>;
}
export default ExhibitionBurgerMenu;