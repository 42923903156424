import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import WorldsContentController from '../../../components/WorldsContentController';
import InlineFormula from '../../../components/InlineFormula';

// Styles 
import style from '../../../styles/ExhibitionContent.module.css';

function C1() {
    const {search} = useLocation();

    return <WorldsContentController teaserUrl={"/teaser-labels/c1.png"} comic={"/comics/C1.svg"} title={"Die Relativistische Welt"}>
        <section className={style.subtitle}>
            Die Relativistische Welt umfasst folgende Theorien: <NavLink to={"/theories/special-relativity"+search}>Spezielle Relativitätstheorie</NavLink>,&nbsp;
            <NavLink to={"/theories/general-relativity"+search}>Allgemeine Relativitätstheorie</NavLink>,&nbsp;
            <NavLink to={"/theories/quantum-field-theory"+search}>Quantenfeldtheorie</NavLink>,&nbsp;
            und <NavLink to={"/theories/theory-of-everything"+search}>Theorie von Allem</NavLink>.
            <br/>Ihre Gegenwelt ist die <NavLink to={"/worlds/c-0"+search}>Galileische Welt</NavLink>.
        </section>
        <section>
            Die <InlineFormula math="c^{-1}=1" />-Welt des Würfels besteht aus genau jenen vier Theorien, in denen 
            der dreidimensionale Raum und die eindimensionale Zeit zu einer neuen Entität vereint werden, der 
            vierdimensionalen Raum-Zeit. In dieser gibt es eine fundamentale Grenzgeschwindigkeit, 
            die Lichtgeschwindigkeit <InlineFormula math="c" />. Seit 1983 ist sie exakt festgelegt und somit 
            Konversionsfaktor zwischen Sekunden und Metern. In der Alltagssprache 
            sind Raum und Zeit bereits seit 'langer Zeit' ganz unkompliziert miteinander verknüpft. Die klassische 
            Elektrodynamik, als Bestandteil der Speziellen Relativitätstheorie, ist übrigens ebenso in der
            <InlineFormula math="c^{-1}=1" />-Welt enthalten. 
        </section>
        <section>
            In der Relativistischen Welt Albert Einsteins hängt die Gleichzeitigkeit zweier Ereignisse vom Bezugssystem 
            ab, während dies für die Lichtgeschwindigkeit nicht der Fall ist. Dagegen gibt es in der 
            nicht-relativistischen <InlineFormula math="c^{-1}=0" />-Welt Galileis eine absolute Zeit, und die 
            Lichtgeschwindigkeit hängt vom Bezugssystem ab. Während die Galileische Welt damit eine Näherung darstellt, 
            deren Genauigkeit bis zum Anfang des 20. Jahrhunderts ausreichte, müssen im heutigen technologischen Alltag 
            relativistische Effekte oftmals berücksichtigt werden. Ein wichtiges Beispiel ist die GPS-Positionierung. 
            Die <InlineFormula math="c^{-1}=1" />-Welt widerspricht allerdings der naiven Anschauung: Wechselt man das 
            Bezugssystem, so treten die miteinander verwobenen Effekte der Längenkontraktion und der Zeitdilatation auf. 
            Diese paradoxalen Effekte der Einsteinschen Welt sind fester Bestandteil der modernen Physik und 
            Hochtechnologie. So können z.B. Myonen, die in der Erdatmosphäre erzeugt werden, auf der Erde nachgewiesen 
            werden, obwohl ihre Halbwertszeit nicht ausreichen würde, diese Strecke zurückzulegen. Das Bezugssystem ist 
            hier entscheidend: Denn aus Laborsicht leben die Myonen aufgrund ihrer hohen Geschwindigkeit länger, 
            während sich in ihrem Eigensystem die Entfernung zur Erde verkürzt.
        </section>
    </WorldsContentController>;
}

export default C1;