import React from "react";

import InlineFormula from "../../exhibition-content/components/InlineFormula";

import style from '../styles/MediaStation.module.css';

const de = [
    <div className={style.textWrapper}>
        <h2>Auf der Suche nach dem Weltmodell</h2>
        <section>
            Im Verlauf des 20. Jahrhunderts hat sich das Standardmodell als umfassendste Zusammenführung 
            des aktuellen Wissens über die Elementarteilchenphysik erwiesen. Es beschreibt alle bekannten 
            Teilchen sowie die zwischen ihnen wirkenden Kräfte und bildet damit gewissermaßen die Grundlage 
            der gesamten Physik. Zudem konnte es auch ganz neue Phänomene wie die Existenz des Higgs-Bosons 
            vorhersagen. Dieses wurde erst Jahrzehnte später mithilfe des riesigen Teilchenbeschleunigers 
            am CERN nachgewiesen. Dennoch hat das Modell ein großes Manko: Die Schwerkraft passt nicht in 
            das Bild. Seit über 50 Jahren ist es der Physik nicht gelungen, diese in den quantenmechanischen 
            Theorierahmen des Standardmodells zu integrieren. Als Folge dieser disparaten Theoriesituation 
            wird in der Community über die unmittelbare Zeit nach dem Urknall oder die Struktur von Schwarzen 
            Löchern heftig gestritten.
        </section>
        <section>
            Wie die genauen Zusammenhänge zwischen den verschiedenen Theorien sind, wie deren historische 
            Entwicklung gewesen ist und woran in Physik und Mathematik heute geforscht wird, visualisiert 
            und erläutert diese Ausstellung anhand des Denkmodells »Cube of Physics«.
        </section>
        <section>
            Finden Sie auf der folgenden Seite selbst heraus, wie der »Cube of Physics« aufgebaut ist und 
            was sich hinter seinen Ecken und Flächen verbirgt.
        </section>
    </div>,
    <div className={style.textWrapper}>
        <section>
            Auf unserer Website »Cube of Physics« wird das Denkmodell Schritt für Schritt erklärt. Entdecken 
            Sie alle Theorien und Welten des Cubes!
        </section>
        <section className={style.center}>
            <a href="/?lang=de">cube-of-physics.org</a>
        </section>
    </div>,
    <div className={style.textWrapper}>
        <h2>Vom Würfel zur Skulptur</h2>
        <section>
            Ein Würfel ist ein dreidimensionaler Körper, dessen Begrenzung aus sechs gleich großen Quadraten, 
            zwölf gleich langen Kanten und acht Ecken besteht. Damit ist er einer der fünf möglichen regulären 
            Polyeder, die man nach dem griechischen Philosophen Platon als platonische Körper bezeichnet. 
            Verbindet man geradlinig die Mittelpunkte aller benachbarten Quadrate, so erhält man den zum Würfel 
            dualen Platonischen Körper, das Oktaeder. Seine Begrenzung besteht jetzt aus acht gleichseitigen 
            Dreiecken, wiederum zwölf gleich langen Kanten und nun sechs Ecken. Gegenüber dem Würfel sind also 
            die Randflächen und Ecken vertauscht, während die Anzahl der Kanten gleich bleibt, auch wenn sie 
            geometrisch anders verortet sind. 
        </section>
        <section>
            Im »Cube of Physics« stehen die Ecken des Würfels für acht Theorien und die Flächen für sechs Welten. 
            Im zum Würfel dualen Oktaeder ist es daher genau umgekehrt.
        </section>
        <section>
            Die nebenstehende Skulptur ist ein aufgeschnittenes »Oktaeder der Physik«: Das Modell entlang der Kanten 
            des Oktaeders aufzutrennen, erzeugt zugleich eine neue Konstellation zwischen den Theorien und eine 
            Sukzession des Blicks von unten nach oben, von Newtons Physik hin zur immer noch gesuchten 
            Theorie von Allem.
        </section>
    </div>,
    <div className={style.textWrapper}>
        <section>
            Der Würfel der Physik ist ein räumliches Modell der Physik. Die Idee, eine zweidimensionale Landkarte 
            der Physik basierend auf den Naturkonstanten <InlineFormula math="G"/>, <InlineFormula math="c"/> und <InlineFormula math="h"/> zu erstellen, geht auf den russischen Physiker 
            Bronstein im Jahr 1933 zurück. Seine Fortentwicklung zu einem Würfel mit den drei 
            Raumachsen <InlineFormula math="G"/>, <InlineFormula math="c^{-1}"/>, und <InlineFormula math="h"/> stammt von Zelmanov (1967).
        </section>
        <section>
            Die Konstante <InlineFormula math="G"/> legt den Wert der Anziehungskraft zweier gegebener punktförmiger Massen in einem 
            bestimmten räumlichen Abstand fest. Implizit gemessen wurde sie erstmals Ende des 18. Jahrhunderts 
            durch eine Dichtebestimmung der Erde. Auch die Krümmung der Raumzeit in der Allgemeinen Relativitätstheorie 
            ist mit <InlineFormula math="G"/> verbunden. Die Geschwindigkeit von Licht im Vakuum <InlineFormula math="c"/> ist die am längsten bekannte Konstante. 
            Ihr Wert ist mittlerweile exakt festgelegt. Erstmals bestimmt wurde <InlineFormula math="c"/> in der zweiten Hälfte des 
            17. Jahrhunderts aus astronomischen Beobachtungen. Sämtliche elektromagnetische Wellen und Gravitationswellen
            breiten sich mit <InlineFormula math="c"/> aus. Das Plancksche Wirkungsquantum <InlineFormula math="h"/> beschreibt in der Quantenmechanik das Verhältnis 
            von Energie und Frequenz eines Photons und wurde zuvor Ende des 19. Jahrhunderts durch Max Planck entdeckt.
        </section>
        <section>
            Im Modell des »Cube of Physics« nicht berücksichtigt ist die Boltzmann-Konstante <InlineFormula math="k"/>, aus der Planck eine 
            vierte natürliche Einheit für die Temperatur ableitete. Der dreidimensionale Würfel der Physik weist 
            der Thermodynamik und der statistischen Physik keinen gesonderten Ort zu. Wie sähe das Metamodell mit 
            dieser vierten Konstante aus?
        </section>
    </div>
];

const en = [
    <div className={style.textWrapper}>
        <h2>Eine dreidimensionale Karte der Physik?</h2>
        <section>
            In the course of the 20th century, the Standard Model has proven to be a complete formulation 
            of all of our current knowledge on elementary particle physics. It describes all known particles 
            and all forces acting between them, and thus constitutes to a certain extent the basis of all of physics. 
            The model was also able to predict completely new phenomena such as the existence of the Higgs boson. 
            The latter was detected decades after this prediction with the help of a huge particle accelerator at CERN. 
            Nevertheless, the model has a major shortcoming: gravity does not fit into the picture. For more than 50 
            years, physics has failed to integrate the gravitational force into the quantum mechanical theoretical 
            framework of the Standard Model. As a result of this disparate theoretical situation, there are ongoing 
            heated debates in the community about the time just after the Big Bang and about the fine structure of 
            black holes.
        </section>
        <section>
            This exhibition uses the conceptual model »Cube of Physics« to visualize and explain the exact connections 
            between the physical theories involved. What is their historic development? What are the hot topics in 
            today’s experimental and mathematical physics?
        </section>
        <section> 
            Discover the structure of the »Cube of Physics« on the next page. What is hiding behind its corners and 
            surfaces?
        </section>
    </div>,
    <div className={style.textWrapper}>
        <section>
            The conceptual model is explained step-by-step on our »Cube of Physics« website. Discover all the 
            theories and worlds of the Cube!
        </section>
        <section className={style.center}>
            <a href="/?lang=en">cube-of-physics.org</a>
        </section>
    </div>,
    <div className={style.textWrapper}>
        <section>
            A cube is a three-dimensional body whose boundary consists of six squares of equal size, 
            twelve edges of equal length, and eight corners. This makes it one of the five possible 
            regular polyhedra, called Platonic solids after the Greek philosopher Plato. If one connects 
            the centers of all neighboring squares by straight lines, one obtains the Platonic solid dual 
            to the cube, the octahedron. Now its boundary consists of eight equilateral triangles, twelve 
            equal-length edges, and six corners. Compared to the cube, the bounding surfaces and the 
            corners are exchanged, while the number of edges remains the same.
        </section>
        <section>     
            In the »Cube of Physics«, its eight corners represent eight theories, while its six bounding 
            squares stand for six worlds. To the contrary, in the dual octahedron the theories are coded 
            into its eight bounding triangles, and the worlds sit on the six corners. 
        </section>
        <section>
            The sculpture next to you is an exploded »Octahedron of Physics«: cutting along the edges creates
             a new constellation between the theories. Moving the eyes up the sculpture, one travels from 
             Newton’s physics to the yet-to-be-discovered Theory of Everything.
        </section>
    </div>,
    <div className={style.textWrapper}>
        <section>
            The Cube of Physics is a spatial model of physics. The idea of creating a two-dimensional 
            map of physics based on the natural constants <InlineFormula math="G"/>, <InlineFormula math="c"/> and <InlineFormula math="h"/> dates back to the Russian physicist 
            Bronstein in 1933. Its evolution into a three-dimensional cube with coordinate axes <InlineFormula math="G"/>, <InlineFormula math="c^{-1}"/>, 
            and <InlineFormula math="h"/> is due to Zelmanov (1967).
        </section>
        <section>
            The constant <InlineFormula math="G"/> determines the value of the gravitational attraction between two given point 
            masses at a given spatial distance. It was measured implicitly, for the first time, at the end 
            of the 18th century through determining the density of the earth. The curvature of space-time in 
            general relativity is also related to <InlineFormula math="G"/>. The speed of light in vacuum <InlineFormula math="c"/> is the constant known for 
            the longest time. It was first determined in the second half of the 17th century from astronomical 
            observations. Its value has been fixed in 1983. All electromagnetic and all gravitational waves 
            propagate with <InlineFormula math="c"/>. In quantum mechanics, Planck's quantum of action <InlineFormula math="h"/> describes the relationship 
            between the energy and frequency of a photon. It was discovered by Max Planck at the very end of 
            the 19th century.
        </section>
        <section>
            The model of the »Cube of Physics« does not take into account the Boltzmann constant <InlineFormula math="k"/>, from 
            which Planck derived, in addition to his natural units for length, time, and mass, a fourth one 
            for temperature. However, the three-dimensional cube of physics does not assign a separate place 
            to thermodynamics and statistical physics. What would a meta model look like that also includes 
            this fourth constant?
        </section>
    </div>
];

const texts = {
    de: de,
    en: en
}

export default texts;