import React, { useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

// Component
import pages from '../TopicList';

// Styles 
import style from '../styles/ExhibitionContent.module.css';

function NavigationMenu(props) {
    const { type } = props;

    const navigate = useNavigate();
    const location = useLocation();

    let keys = Object.keys(pages[type]);
    let pageId = getPageId();

    useEffect(() => {
        pageId = getPageId();
    }, [location.pathname]);

    function onClickClose() {
        navigate("/explore"+location.search);
    }

    function onClickPrevious() {
        let index = (pageId - 1);
        if (index < 0) {
            index = keys.length - 1;
        }
        navigate("/" + type + "/" + keys[index]+location.search);
        window.scrollTo(0, 0);
    }

    function onClickNext() {
        let index = (pageId + 1) % keys.length;
        navigate("/" + type + "/" + keys[index]+location.search);
        window.scrollTo(0, 0);
    }

    function getPageId() {
        let page = location.pathname.replace("/" + type, "");
        page = page.replace("/", "");
        return keys.indexOf(page);
    }

    return <div className={style.menuWrapper}>
        <img className={style.menuButton} alt="icon for previous content page navigation"
            src="/button-icons/arrow-left.svg" onClick={onClickPrevious}></img>
        <img className={style.menuButton} alt="icon for next content page navigation"
            src="/button-icons/arrow-right.svg" onClick={onClickNext}></img>
        <img className={style.menuButton} alt="icon for close content page and return to explore mode"
            src="/button-icons/close.svg" onClick={onClickClose}></img>
    </div>;
}

export default NavigationMenu;