import React from 'react';

import 'katex/dist/katex.min.css';
import TeX from '@matejmazur/react-katex';

// Styles 
import style from '../styles/ExhibitionContent.module.css';

function InlineFormula(props) {
    const {math} = props;
    return <TeX className={style.inlineFormula} math={math} />;
}
export default InlineFormula;