import React, {useState, useEffect, useRef} from 'react';
import {
    useNavigate,
    useLocation,
    useSearchParams,
    BrowserRouter,
    Route,
    Routes,
    Navigate
} from "react-router-dom";

// Main Views
import Explore from './views/explore/Explore';
import Exhibition from "./views/exhibition/Exhibition";
import ExhibitionContent from "./views/exhibition-content/ExhibitionContent";

// Media Station
import MediaStationView from './views/media-station/MediaStationView';

// Misc Views
import About from './views/misc/About';
import History from './views/misc/History';
import Legal from './views/misc/Legal';
import ScreenSaver from './views/misc/ScreenSaver';

// Styles
import './App.css';

function App(props) {
    const navigate= useNavigate();
    const { search } = useLocation();

    const [searchParams, ] = useSearchParams();

    const [language, setLanguage] = useState(checkUrlLanguage());
    const [mediaStationMode, setMediaStationMode] = useState(false);

    
    const idleTimeout = useRef(null);

    useEffect(() => {
        let searchLang = searchParams.get("lang");
        if (searchLang && (searchLang === "en" || searchLang === "de")) {
            setLanguage(searchLang);
        }

        let mediaStationModeSearch = searchParams.get("mediaStationMode");
        if (mediaStationModeSearch === "true") {
            setMediaStationMode(true);
        }
    }, [searchParams]);
    
    useEffect(() => {
        function resetIdleTimeout() {
            // Clears the existing timeout
            if(idleTimeout.current) {
                clearTimeout(idleTimeout.current);
            }
            
            idleTimeout.current = setTimeout(() => {
                navigate('/screen-saver'+search);
            }, 45000); // Screen Saver activates after 45 seconds
        }

        const events = ['click', 'touchstart', 'mousemove', 'scroll'];
        if (mediaStationMode) {
            events.forEach(evt => {
                document.addEventListener(evt, resetIdleTimeout, false);
            });
        }

        return () => {
            // Clears the existing timeout
            if(idleTimeout.current) {
                clearTimeout(idleTimeout.current);
            }
            
            events.forEach(evt => {
                document.removeEventListener(evt, resetIdleTimeout);
            });
        };
    }, [mediaStationMode]); // eslint-disable-line react-hooks/exhaustive-deps

    function checkUrlLanguage() {
        // Determine Language based on url
        let urlLang = window.location.hostname.split('.')[0];
        let language = (urlLang === "en" || urlLang === "de") ? urlLang : "en";

        return language;
    }
    
    return <div className="app-body">
        <Routes>
            <Route path="/" element={<Exhibition language={language}/>} />

            <Route path="/about/" element={<About language={language} mediaStationMode={mediaStationMode}/>} />
            <Route path="/legal/" element={<Legal language={language} mediaStationMode={mediaStationMode}/>} />
            <Route path="/history/" element={<History language={language}/>} />
            <Route path="/screen-saver/" element={<ScreenSaver language={language}/>} />

            <Route path="/exhibition/" element={<Exhibition language={language} mediaStationMode={mediaStationMode}/>} />
            <Route path="/explore/" element={<Explore language={language} mediaStationMode={mediaStationMode}/>} />
            <Route path="/worlds/:topicID/" element={<ExhibitionContent type="worlds" language={language}/>} />
            <Route path="/theories/:topicID/" element={<ExhibitionContent type="theories" language={language}/>} />

            <Route path="/01/" element={<MediaStationView page={0} language={language}/>} />
            <Route path="/02/" element={<MediaStationView page={1} language={language}/>} />
            <Route path="/03/" element={<MediaStationView page={2} language={language}/>} />
            <Route path="/04/" element={<MediaStationView page={3} language={language}/>} />

            <Route path="*" element={<Navigate to={`/${search}`} />} />
        </Routes>
    </div>;
}

const AppWrapper = () => {
    return (
        <BrowserRouter>
            <App />
        </BrowserRouter>
    );
};
    
export default AppWrapper;