import React, { useEffect, useRef, useState} from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import VisualiserController from '../helper/VisualizerController';

// THREE
import * as THREE from 'three';
import {TrackballControls} from "three/examples/jsm/controls/TrackballControls";

// Components
import ColumnVectorLabel from '../../../general-components/ColumnVectorLabel';

import Hammer from 'hammerjs';

// Helper
import OBJModelController from '../../../helper/OBJModelController';
import LabelHelper from '../helper/LabelHelper';

// Styles
import baseStyle from '../styles/NewExplore.module.css';

// Config
import theoryUrlConf from '../config/TheoryConstantTripelMap';
import faceWorldConf from '../config/FaceIndexToWorldMap';
import raycastCylConf from '../config/RaycastCylinders';

function ExploreVisualizer() {
    const {search} = useLocation();
    const navigate = useNavigate();

    const [updateLabelsFlag, setUpdateLabelsFlag] = useState(0);
    const [labelScale, setLabelScale] = useState(10);

    const threeMountDiv = useRef(null);
    const visualiserController = useRef(null);
    
    const labelData = useRef([]);
    for (let i=0; i<8; i++) {
        labelData.current.push(new LabelHelper());
    }

    const onTheorySelection = (tripel) => {
        visualiserController.current.saveCurrentSceneSetupToLocalStorage();
        navigate("/theories/"+theoryUrlConf[tripel]+search);
    }

    const onWorldSelection = (clickedFace) => {
        navigate("/worlds/"+faceWorldConf[clickedFace]+search);
    }

    useEffect(() => {
        visualiserController.current = new VisualiserController(
            threeMountDiv.current, 
            labelData.current, 
            setUpdateLabelsFlag, 
            setLabelScale,
            onWorldSelection
        );

        threeMountDiv.current.addEventListener('mousedown', visualiserController.current.onMouseDown, false);
        threeMountDiv.current.addEventListener('mouseup', visualiserController.current.onMouseUp, false);
        threeMountDiv.current.addEventListener('touchend', visualiserController.current.onMouseUp);
        threeMountDiv.current.addEventListener('touchcancel', visualiserController.current.onMouseUp, false);
        threeMountDiv.current.addEventListener('mousemove', visualiserController.current.onMouseMove);
        
        return () => {
            visualiserController.current.cleanup();
            // threeMountDiv.current.removeEventListener('mousedown');
            // threeMountDiv.current.removeEventListener('mouseup');
            // threeMountDiv.current.removeEventListener('mousemove');
            // threeMountDiv.current.removeEventListener('touchend');
            // threeMountDiv.current.removeEventListener('touchcancel');
        }
    }, []);
    /*constructor(props) {
        super(props);
        this.threeMountDiv = React.createRef();

        this.state = {
            updateLabelsFlag: 0
        };

        this.pos = {
            x: 0,
            y: 0
        };

        this.labelData = [];
        for (let i=0; i<8; i++) {
            this.labelData.push(new LabelHelper());
        }
    }

    componentDidMount() {
        this.threeMount = this.threeMountDiv.current;

        this.mouse = new THREE.Vector2();
        
        this.setupScene();
        this.setupObjects();
        this.setupControls();

        this.renderer.domElement.addEventListener('mousedown', this.onMouseDown, false);
        this.renderer.domElement.addEventListener('mousemove', this.onMouseMove);
        window.addEventListener('resize', this.onWindowResize);

        this.renderer.render( this.scene, this.camera );
        
        this.labelScale = 0;
        this.calcLabelScale();

        this.update();
    }

    componentWillUnmount() {
        window.cancelAnimationFrame(this.requestID);
        window.removeEventListener('resize', this.onWindowResize);
        this.renderer.domElement.removeEventListener('mousedown',this.onMouseDown);
        this.renderer.domElement.removeEventListener('mousemove',this.onMouseMove);
    }*/


    /*updateLabelStates = () => {
        let updateNeccessary = false;
        let deltaT = performance.now() - this.state.updateLabelsFlag;

        let cameraPos = new THREE.Vector3();
        this.camera.getWorldPosition(cameraPos);

        if (deltaT > 250) {
            let spherePos = new THREE.Vector3();

            for (let i=0; i<this.spheres.children.length; i++) {
                let sphere = this.spheres.children[i];
                sphere.getWorldPosition(spherePos);

                let visible = (cameraPos.distanceTo(spherePos) < 6);
                if (visible !== this.labelData[i].visible) {
                    this.labelData[i].visible = visible; 
                    updateNeccessary = true;
                }
    
                let newPos = this.worldToScreenPosition(sphere);
                if (this.labelData[i].updatePosition(newPos)) {
                    updateNeccessary = true;
                }
            }
            
            if (updateNeccessary) {
                this.setState({updateLabelsFlag: deltaT});
            }
        }
    }

    worldToScreenPosition(obj) {
        var vector = new THREE.Vector3();
        let halfWidth = 0.5*this.width;
        let halfHeight = 0.5*this.height;

        obj.updateMatrixWorld();
        vector.setFromMatrixPosition(obj.matrixWorld);
        vector.multiplyScalar(1.15);
        vector.project(this.camera);

        vector.x = ( vector.x * halfWidth ) + halfWidth;
        vector.y = - ( vector.y * halfHeight ) + halfHeight;

        return { 
            x: vector.x,
            y: vector.y
        };
    }

    calcLabelScale = () => {
        let unitInPixel = Math.min(this.width, this.height) / 6;
        this.labelScale = unitInPixel * 0.25;
        this.setState({updateLabelsFlag: this.state.updateLabelsFlag + 1});
    }


    

    onTheorySelection = (tripel) => {
        this.saveCurrentSceneSetupToLocalStorage();
        this.props.history.push("/theories/"+theoryUrlConf[tripel]);
    }

    

    */

    return <div className={baseStyle.visualizerWrapper} ref={threeMountDiv}>
                
            <div className={baseStyle.labelWrapper}>
                <ColumnVectorLabel g={true}  h={false} c={false} pos={labelData.current[4].pos} onSelect={onTheorySelection}
                    visible={labelData.current[4].visible} updateLabelsFlag={updateLabelsFlag} simpleMode width={labelScale}/>
                <ColumnVectorLabel g={false} h={false} c={false} pos={labelData.current[5].pos} onSelect={onTheorySelection}
                    visible={labelData.current[5].visible} updateLabelsFlag={updateLabelsFlag} simpleMode width={labelScale}/>
                <ColumnVectorLabel g={true}  h={true}  c={false} pos={labelData.current[6].pos} onSelect={onTheorySelection}
                    visible={labelData.current[6].visible} updateLabelsFlag={updateLabelsFlag} simpleMode width={labelScale}/>
                <ColumnVectorLabel g={false} h={true}  c={false} pos={labelData.current[7].pos} onSelect={onTheorySelection}
                    visible={labelData.current[7].visible} updateLabelsFlag={updateLabelsFlag} simpleMode width={labelScale}/>             
                <ColumnVectorLabel g={true}  h={false} c={true}  pos={labelData.current[0].pos} onSelect={onTheorySelection}
                    visible={labelData.current[0].visible} updateLabelsFlag={updateLabelsFlag} simpleMode width={labelScale}/>
                <ColumnVectorLabel g={false} h={false} c={true}  pos={labelData.current[1].pos} onSelect={onTheorySelection}
                    visible={labelData.current[1].visible} updateLabelsFlag={updateLabelsFlag} simpleMode width={labelScale}/>
                <ColumnVectorLabel g={true}  h={true}  c={true}  pos={labelData.current[2].pos} onSelect={onTheorySelection}
                    visible={labelData.current[2].visible} updateLabelsFlag={updateLabelsFlag} simpleMode width={labelScale}/>
                <ColumnVectorLabel g={false} h={true}  c={true}  pos={labelData.current[3].pos} onSelect={onTheorySelection}
                    visible={labelData.current[3].visible} updateLabelsFlag={updateLabelsFlag} simpleMode width={labelScale}/>
                </div>
        </div>;
}

export default ExploreVisualizer;