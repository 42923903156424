import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import WorldsContentController from '../../../components/WorldsContentController';
import InlineFormula from '../../../components/InlineFormula';

// Styles 
import style from '../../../styles/ExhibitionContent.module.css';

function H1() {
    const {search} = useLocation();

    return <WorldsContentController teaserUrl={"/teaser-labels/h1.png"} comic={"/comics/H1.svg"} title={"Die Welt der Quanten"}>
        <section className={style.subtitle}>
            Die Quantenwelt umfasst vier Theorien: <NavLink to={"/theories/quantum-mechanics"+search}>Quantenmechanik</NavLink>,&nbsp;
            <NavLink to={"/theories/non-relativistic-quantum-gravitation"+search}>Nicht-Relativistische Quantengravitation</NavLink>,&nbsp;
            <NavLink to={"/theories/quantum-field-theory"+search}>Quantenfeldtheorie</NavLink>,&nbsp;
            und die <NavLink to={"/theories/theory-of-everything"+search}>Theorie von Allem</NavLink>.
            <br/>Ihre Gegenwelt ist die <NavLink to={"/worlds/h-0"+search}>klassische Welt</NavLink>.
        </section>
        <section>
            Die Physik schien Ende des 19. Jahrhunderts weitgehend verstanden. Man hatte es geschafft, Elektrizität und 
            Magnetismus zum Elektromagnetismus zu vereinheitlichen sowie die Thermodynamik als statistische Theorie von 
            Ensembles zu formulieren, in denen die Einzelteilchen den Gesetzen der klassischen Mechanik gehorchen. 
            Umso überraschender war es für Max Planck, dass es ihm zunächst nicht gelang, das präzise gemessene 
            Strahlungsspektrum eines schwarzen Körpers mit der klassischen statistischen Physik zu erklären. Er 
            benötigte vielmehr die adhoc-Annahme, dass die Energien mikroskopischer Oszillatoren nur in diskreten 
            Portionen, den Quanten, auftreten. In seinem Strahlungsgesetz hat er dafür eine Hilfsgröße, das später 
            sogenannte Plancksche Wirkungsquantum <InlineFormula math="h" />, eingeführt. 
        </section>
        <section>
            Planck gab damit den Auftakt zur Erforschung der Welt der Quanten. Das Verständnis dieser Welt hat 
            Eigenschaften zu Tage gefördert, die mit der klassischen Welt nicht vereinbar sind: Die Entwicklung 
            quantenmechanischer Systeme ist nicht etwa über feste Teilchenbahnen, sondern lediglich über sogenannte 
            Wahrscheinlichkeitsamplituden definiert. Messprozesse verändern typischerweise die Systemzustände auf 
            eine nur teilweise vorhersehbare Weise. Solange man jedoch nicht misst, sind die 
            Wahrscheinlichkeitsamplituden weiterhin vollständig deterministischen Gleichungen unterworfen. Diese 
            führen zu überprüfbaren Vorhersagen und damit zur Entwicklung von Quantentechnologien, die beispielsweise 
            die heutigen Informations- und Kommunikationsmedien ermöglichen.
        </section>
        <section>
            Vereint man Quantenmechanik und Spezielle Relativitätstheorie, berücksichtigt also die Konstanten <InlineFormula math="h" /> und <InlineFormula math="c^{-1}" />, 
            so führt dies zur Quantenfeldtheorie, die im Standardmodell die Elementarteilchenwelt mit 
            teilweise ungeheurer Präzision beschreibt. Offen bleibt dagegen, ob es einer eigenen Theorie der 
            Nicht-Relativistischen Quantengravitation bedarf, die nur die Konstanten <InlineFormula math="G" /> und <InlineFormula math="h" /> enthält. Denn 
            bisherige Experimente mit langsamen Neutronen in irdischen Gravitationsfeldern stimmen gut mit den 
            einfachen theoretischen Vorhersagen der quantenmechanischen Schrödinger-Gleichung mit Gravitationspotential 
            überein.
        </section>
        <section>
            In jedem Fall fehlt aber noch die Vereinigung der Quantenfeldtheorie mit der Allgemeine Relativitätstheorie 
            zu einer konsistenten Theorie der Quantengravitation. Diese Theorie von Allem sollte die 
            Konstanten <InlineFormula math="G" />, <InlineFormula math="c^{-1}" /> und <InlineFormula math="h" /> enthalten. Es gibt eine ganze Reihe heiß diskutierter Kandidaten 
            wie die Superstringtheorie oder die Schleifenquantengravitation. Jedoch hat keiner dieser Ansätze 
            bisher experimentell verifizierbare Voraussagen machen können. Das Spiel ist längst nicht zu Ende!
        </section>
    </WorldsContentController>;
}

export default H1;