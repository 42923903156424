import React from 'react';
import { Navigate, useParams, useLocation } from 'react-router-dom';

//Components
import NavigationMenu from './components/NavigationMenu';
import TopicList from './TopicList';

import LegalFooter from '../../general-components/LegalFooter';

// Styles 
import style from './styles/ExhibitionContent.module.css';

function ExhibitionContent(props) {
    const {type, language} = props;
    const params = useParams();
    const {search} = useLocation();
    
    let content = TopicList[type][params.topicID][language];
    
    if (!content) {
        return <Navigate to={"/"+search} />
    }

    let menu = <NavigationMenu  type={type} />;
    let bottomMenu = (type === "worlds") ? null : menu;

    return <div className={style.outerWrapper}>
            {menu}
            {content}
            {bottomMenu}
            <LegalFooter language={language} />
    </div>;
}
export default ExhibitionContent;