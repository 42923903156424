import React from 'react';

import InlineFormula from '../exhibition-content/components/InlineFormula';
import MiscPage from '../../general-components/MiscPage';

function About(props) {
    const de = <MiscPage title={"Geschichte"} backgroundColor="#95696E" language={props.language}>
        <p>
            Der sowjetische Physiker Matwei Petrowitsch Bronstein veröffentlichte 1933 einen Aufsatz 
            unter dem Titel "Über eine mögliche Theorie der Welt als Ganzes". Er studierte seinerzeit 
            gemeinsam mit George Gamow, Dmitri Iwanenko und Lew Landau an der damaligen Staatlichen 
            Universität Leningrad. In dem gemeinsamen Beitrag "Über die Weltkonstanten und deren 
            Grenzübergänge" von 1928 schrieben sie: "jede Konstante repräsentiert ein physikalisches 
            Gesetz (Theorie), eine Weltkonstante, die dessen Allgemeingültigkeit verkörpert." 
            (Gamow/Ivanenko/Landau 1928: 1373f.)

        </p>
        <p>
            Vor diesem Hintergrund führte Bronstein in seinem Aufsatz eine zweidimensionale <InlineFormula math="cGh" />-Karte ein, 
            in welcher die "Beziehung zwischen physikalischen Theorien und deren Verhältnis zur kosmologischen Theorie" 
            dargestellt ist. (zit. n. Gorelik/Frenkel 1994: 89) Nachdem Albert Einstein seine Allgemeine Relativitätstheorie 
            1915 veröffentlicht hatte, arbeiteten die Physiker am nächsten großen Schritt: eine relativistische Quantentheorie. 
            Entsprechend markierte Bronstein in seiner Karte auch zukünftige Theorien: "durchgezogene Linien bezeichnen bereits 
            bestehende physikalische Theorien, während gepunktete Linien auf noch immer ungelöste Probleme hinweisen". 
            (zit. n. Gorelik/Frenkel 1994: 90)
        </p>
        <p>
            Bronsteins Modell war also zugleich eine Aussage über die Entwicklung der Physik als Ganzes: 
            "Nach der Formulierung der relativistischen Quantentheorie wird die nächste Aufgabe in einem 
            dritten Schritt bestehen (...), nämlich die Quantentheorie (Naturkonstante <InlineFormula math={"h"} />) 
            mit der Speziellen Relativitätstheorie (Naturkonstante <InlineFormula math={"c"} />) und der Theorie der 
            Schwerkraft (Naturkonstante <InlineFormula math={"G"} />) zu vereinen." (zit. n. Gorelik/Frenkel 1994: 90) 
            1967/69 dann war es Zelmanov, der Bronsteins zweidimensionale Karte auf brilliante Weise in einen dreidimensionalen 
            Würfel der Physik weiterentwickelte, aufgespannt von den drei Naturkonstanten <InlineFormula math={"c"} />,
            &nbsp;<InlineFormula math={"G"} /> und <InlineFormula math={"h"} />.
        </p>
    </MiscPage>;

    const en = <MiscPage title={"History"} backgroundColor="#95696E" language={props.language}>
        <p>
            In 1933, the Soviet physicist Matvei Bronstein published a paper "On the
            Possible Theory of the World as a Whole". He was a student at Leningrad
            University at the time, together with George Gamow, Dmitry Ivanenko, and Lev
            Landau. The latter had published in 1928 an article "World Constants and
            Limiting Transition" in which they wrote: "each constant is a representative of
            a physical law (theory), a world constant symbolizing the generality of a law."
            (Gamow/Ivanenko/Landau 1928: 1373f.)
        </p>
        <p>
            With this conception in mind, Bronstein
            introduced in his paper a two-dimensional cGh-plane of the "Relationship
            Between Physical Theories and Their Relation to the Cosmological Theory".
            (qtd. Gorelik/Frenkel 1994: 89) After Albert Einstein had published his General
            Theory of Relativity in 1915, physicists worked on the next big step: a
            relativistic quantum theory. Accordingly, Bronstein depicted in his map also
            future theories: "continuous lines denote the already existing physical
            theories while the dotted lines correspond to the still unsolved problems".
            (qtd. Gorelik/Frenkel 1994: 90)
        </p>
        <p>
            Hence, his model was also a statement on the
            development of physics as a whole: "After relativistic quantum theory is
            formulated, the next task would be to realize the third step (...), namely, to
            merge quantum theory (<InlineFormula math={"h"} /> constant) with special relativity (<InlineFormula math={"c"} /> constant) and
            the theory of gravitation (<InlineFormula math={"G"} /> constant) into a single whole." (qtd.
            Gorelik/Frenkel 1994: 90). In 1967/69, Zelmanov brilliantly turned Bronstein's
            planar map into a three-dimensional cube, spanned by the natural constants <InlineFormula math={"c"} />,
            &nbsp;<InlineFormula math={"G"} /> and <InlineFormula math={"h"} />.
        </p>
    </MiscPage>;

    return (props.language === "de") ? de : en;
}

export default About;
